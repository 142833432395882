import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

export default function ButtonX(props) {
  const [value, setValue] = useState(null);
  const theme = useTheme();

  const handleOnChange = (e) => {
    if (props.onPress) {
      props.onPress();
    }
    if (props.onClick) {
      props.onClick();
      if (props.ignoreDefault) {
        e.preventDefault();
      }
    }
  };

  return (
    <Button
      id={props.id}
      variant={props.variant}
      type={props.type}
      onClick={(e) => handleOnChange(e)}
      style={{
        backgroundColor: theme.palette.general.blue,
        display: 'flex',
        alignItems: 'center',
        ...props.style,
      }}
      disabled={props.disabled}
    >
      {props.icon && <div style={{ paddingRight: '5px' }}>{props.icon}</div>}
      {props.label}
    </Button>
  );
}

ButtonX.propTypes = {
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  onClick: PropTypes.func,
};

ButtonX.defaultProps = {
  id: 'NO-ID-PROVIDED',
  label: 'NO-LABEL-PROVIDED',
  variant: 'contained',
  type: undefined,
};
