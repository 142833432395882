import React, { useState } from 'react';
import { theme } from 'Stories';

import logo from 'Assets/Self_Health_Logo.png';

import './Style.css';

export default function Card(props) {
  let colors = theme.palette.general;

  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className="Card_Main"
      style={{
        backgroundColor: props.color ? colors[props.color] : colors.blue,
      }}
      onClick={() => handleOnClick()}
    >
      <div className="Card_Image">{props.icon}</div>
      <div className="Card_TextContainer">{props.label}</div>
    </div>
  );
}
