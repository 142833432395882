import React from 'react';
import { theme } from 'Stories';
import './Style.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function CanvasControl(props) {
  const buttonMatch = {
    center: solid('arrows-to-circle'),
    zoomIn: solid('plus'),
    zoomOut: solid('minus'),
  };

  return (
    <div
      className="basicButton"
      style={{
        ...props.style,
      }}
      onClick={() => props.onClick()}
    >
      <div className="iconHolder">
        <FontAwesomeIcon
          className="alterChartBtn"
          style={{ height: '13px', width: '13px' }}
          icon={buttonMatch[props.type]}
        />
      </div>
    </div>
  );
}
