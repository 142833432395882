import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal, theme, Card } from 'Stories';
import tasks from 'Assets/Tasks.jpg';
import admin from 'Assets/Admin.jpg';

import { store } from 'Contexts/GlobalState';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// import PageForm from './Form.js';

import './Main.css';

import Header from 'Pages/ViewConfig/Header';

export default function ViewConfig(props) {
  let colors = theme.palette.general;

  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const location = useLocation();
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  let navigate = useNavigate();

  const { appConfig } = location.state;

  return (
    <div>
      <Header
        title={'Template Page'}
        prevPage={'/app-configs'}
        prevPageName={'configurations'}
      />
    </div>
  );
}
