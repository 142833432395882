import InsightsImage from '../../Assets/Insights Graphic.svg';
import UsersGroupsImage from '../../Assets/Users & Groups Graphic.svg';
import SurveyBuilder from '../../Assets/Survey Builder.svg';
import FacilitateImmersion from '../../Assets/facilitate immersion.png';
import ArtGallery from '../../Assets/art-gallery.png';
import FacilitatorsLounge from '../../Assets/facilitator lounge.png';
import ProgramMaterials from '../../Assets/program materials.png';
import TrainingModules from '../../Assets/Training-Modules-Icon.png';
import { TEMP_CONFIG, TEMP_DASHBOARD } from 'Services/GlobalConstants';

export const quickLinksList = [
  {
    title: 'Insights and Reporting',
    image: InsightsImage,
    path: '/view-dashboard',
    extras: { state: { dashboard: TEMP_DASHBOARD } },
  },
  {
    title: 'Users',
    image: UsersGroupsImage,
    path: '/users',
  },
  {
    title: 'Survey Builder',
    image: SurveyBuilder,
    path: '/view-config/surveys',
    extras: { state: { appConfig: TEMP_CONFIG } }, // Warning: Hardcoded configs present here
  },
];

export const innerImmersionLinksList = [
  {
    title: 'Facilitate an Immersion',
    image: FacilitateImmersion,
    path: '/facilitate-immersion',
  },
  // {
  //   title: 'Facilitators Lounge',
  //   image: FacilitatorsLounge,
  //   path: '/facilitators-lounge',
  // },
  {
    title: 'Training Modules',
    image: TrainingModules,
    path: '/training-modules',
  },
  // {
  //   title: 'Art Gallery',
  //   image: ArtGallery,
  //   path: '/art-gallery',
  // },
];

export const innerImmersionLinksListFacilitator = [
  // {
  //   title: 'Facilitate an Immersion',
  //   image: FacilitateImmersion,
  //   path: '/facilitate-immersion',
  // },
  // {
  //   title: 'Facilitators Lounge',
  //   image: FacilitatorsLounge,
  //   path: '/facilitators-lounge',
  // },
  {
    title: 'Training Modules',
    image: TrainingModules,
    path: '/training-modules',
  },
  // {
  //   title: 'Art Gallery',
  //   image: ArtGallery,
  //   path: '/art-gallery',
  // },
];
