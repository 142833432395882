import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation } from 'react-router-dom';

import { store } from 'Contexts/GlobalState';
import { alertClasses } from '@mui/material';

import PageForm from './Form.js';
import { EXECUTE_ADMIN_TASK } from './GraphQL/mutations.js';

import { useLazyQuery, useMutation } from '@apollo/client';

import { Auth } from '@aws-amplify/auth';

import * as moment from 'moment';

const labels = [
  {
    alignment: 'left',
    label: 'Name',
    key: 'displayName',
  },
  {
    alignment: 'left',
    label: 'Email',
    key: 'email',
  },
  {
    alignment: 'left',
    label: 'Groups',
    key: 'userGroupsStr',
  },
  {
    alignment: 'right',
    label: 'Date Added',
    key: 'formattedDate',
  },
  {
    alignment: 'right',
    label: 'Actions',
    key: 'actions',
    size: 'small',
  },
];

const hiddenUsers = [
  'divyanth@magnoliapoint.com',
  'krista@magnoliapoint.com',
  'jennifer@magnoliapoint.com',
];

export default function UsersAndGroups(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [editInfo, setEditInfo] = useState({});
  const [myUserGroups, setMyUserGroups] = useState([]);

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const toggleModal = (e) => {
    // navigate('/add-survey', { state: { editInfo: e } });
    setModalInfo(e);
    setModalOpen(!modalOpen);
  };

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;
  let navigate = useNavigate();

  console.log(Auth.user.attributes.email);

  const isMPEmployee = () => {
    return Auth.user.attributes.email.indexOf('@magnoliapoint.com') > -1;
  };

  const canEdit = (item) => {
    if (Auth.user.attributes.email === item.email) {
      return false;
    } else if (item.email.indexOf('@magnoliapoint.com') > -1) {
      if (isMPEmployee()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  const isProd = () => {
    return window.location.host === 'innerimmersion.magnolia-point.com';
  };

  const getUsers = async () => {
    setLoading(true);

    let resp = await executeAdminTask({
      variables: {
        operation: 'pullUsersAndGroups',
      },
    });

    let t = JSON.parse(resp.data.executeAdminTask.body);

    if (t && t.users) {
      let users = t.users;
      let prodUsers = [];

      for (var i = 0; i < users.length; ++i) {
        users[i].actions = {
          edit: canEdit(users[i]),
          delete: canEdit(users[i]),
        };
        users[i].formattedDate = moment(users[i].UserCreateDate).format(
          'MMM Do YYYY, h:mm:ss a'
        );
        if (isProd()) {
          if (hiddenUsers.indexOf(users[i].email) === -1) {
            prodUsers.push(users[i]);
          }
        } else {
          prodUsers.push(users[i]);
        }
      }

      console.log('GROUPS: ', t.groups);

      setUsers(prodUsers);
      setGroups(t.groups);
      setLoading(false);
    } else {
      console.log('Failed', taskError);
      setLoading(false);
    }
  };

  const forceReload = () => {
    getUsers();
  };

  const onClickEdit = (e) => {
    console.log('EDIT:', e);
    setEditInfo({ ...e, type: 'edit' });
    setMyUserGroups(e.userGroups);
    toggleModal();
    console.log('Dashboard ', JSON.stringify(e));
  };

  const onClickDelete = async (e) => {
    console.log('onclick delete', e);
    let resp = await executeAdminTask({
      variables: {
        operation: 'deleteUser',
        input: JSON.stringify({ email: e.email }),
      },
    });

    setLoading(true);
    getUsers();
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>Users</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          {
            <Button
              label="Add new user"
              onClick={(e) => {
                setEditInfo({ type: 'new' });
                toggleModal({});
              }}
            />
          }

          {
            // <Button label="Add new group" onClick={(e) => toggleModal({})} />
          }
        </div>
      </div>
      <Table
        labels={labels}
        data={users}
        loading={loading}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        confirmDelete={true}
      />
      <Modal
        open={modalOpen}
        toggle={toggleModal}
        title={`${editInfo.type === 'edit' ? 'Manage' : 'Add'} User`}
      >
        <PageForm
          editInfo={editInfo}
          setModalOpen={setModalOpen}
          setEditInfo={setEditInfo}
          myUserGroups={myUserGroups}
          forceReload={forceReload}
          groups={groups}
        />
      </Modal>
    </div>
  );
}
