import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { TextInput, Form, Button, Table, Modal } from 'Stories';
import './Style.css';

import { checkPassword } from './utility.js';
import PasswordHelper from './PasswordHelper.js';

export default function ChangePassword(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newpassword, setNewPassword] = useState(null);

  const [verificationCode, setVerificationCode] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(null);

  const onSubmit = async (d) => {
    props.clearError();

    let password = newpassword + '';
    let errMsgs = checkPassword(password, d.confirmpassword);

    if (!isPasswordValid) {
      props.setError('Password does not meet requirements');
    } else if (newpassword !== d.confirmpassword) {
      props.setError('Passwords do not match');
    } else {
      setLoading(true);

      let payload = {
        password: password,
        username: props.authSession.username.toLowerCase(),
      };

      if (props.type === 'forgotPassword') {
        await Auth.forgotPasswordSubmit(
          props.authSession.username,
          verificationCode,
          password
        );
      } else {
        await Auth.completeNewPassword(props.authSession, password);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    setError(props.error);
    if (props.error) {
      setLoading(false);
    }
  }, [props.error]);

  return (
    <div style={{ flex: 1 }}>
      <div className="pageTitle">Change Password</div>
      <div>
        <Form style={{ display: 'block' }} onSubmit={onSubmit}>
          {props.type === 'forgotPassword' && (
            <TextInput
              label="Verification Code"
              id="verificationCode"
              form
              variant="outlined"
              type="code"
              required
              onChange={(e) => setVerificationCode(e)}
            />
          )}
          <TextInput
            label="New Password"
            id="newpassword"
            form
            variant="outlined"
            required
            type="password"
            onChange={(e) => setNewPassword(e)}
          />
          <PasswordHelper
            password={newpassword}
            setIsPasswordValid={setIsPasswordValid}
          />
          <TextInput
            label="Confirm New Password"
            id="confirmpassword"
            form
            variant="outlined"
            required
            type="password"
          />
          <Button
            label={loading ? 'Changing Password' : 'Change Password'}
            id="submit"
            type="submit"
            disabled={loading}
            style={{ width: '100%' }}
          />
          {error && <div className="authErrorMessage">{error}</div>}
        </Form>
      </div>
    </div>
  );
}
