import { gql } from '@apollo/client';

export const EXECUTE_ADMIN_TASK = gql`
  mutation ($operation: String, $input: String) {
    executeAdminTask(operation: $operation, input: $input) {
      status
      body
    }
  }
`;
