import { useState, useEffect } from 'react';
import { TextInput, Button } from 'Stories';
import './changepassword.css';

export default function ChangePassword(props) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmPasswordSame, setIsConfirmPasswordSame] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleChange = (e, setValue) => {
    setValue(e);
  };

  useEffect(() => {
    setCanSubmit(isConfirmPasswordSame && newPassword.length > 0);
  }, [isConfirmPasswordSame]);

  useEffect(() => {
    setIsConfirmPasswordSame(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  return (
    <div>
      <div className="change-password-form">
        <h3>Change Password</h3>
        <div className="password-input">
          <TextInput
            label="Current Password"
            type="password"
            value={currentPassword}
            onChange={(e) => handleChange(e, setCurrentPassword)}
          />
        </div>
        <div className="password-input">
          <TextInput
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => handleChange(e, setNewPassword)}
          />
        </div>
        <div className="password-input">
          <TextInput
            label="Confirm New Password"
            type="password"
            error={!isConfirmPasswordSame}
            helperText={
              !isConfirmPasswordSame
                ? 'Confirm password should be the same as new password'
                : ''
            }
            value={confirmPassword}
            onChange={(e) => handleChange(e, setConfirmPassword)}
          />
        </div>
        <div className="change-password-submit-button">
          <Button label="Change Password" type="password" />
        </div>
      </div>
    </div>
  );
}
