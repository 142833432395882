import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Button } from 'Stories';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Modal
      open={open}
      onClose={props.toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id={props.id}
    >
      <Box
        sx={style}
        style={{
          width: props.wide ? '70%' : null,
          maxHeight: '80%',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            paddingBottom: '25px',
            fontSize: '20px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {props.title}
          <FontAwesomeIcon
            onClick={() => {
              if (props.toggle) props.toggle();
            }}
            style={{ color: 'gray', cursor: 'pointer' }}
            icon={solid('xmark')}
          />
        </div>
        {props.children}
        {props.confirmation && (
          <div style={{ display: 'flex', paddingTop: '25px' }}>
            <Button
              label="cancel"
              id="cancel"
              type="cancel"
              onClick={props.toggleModal}
            />
            <div style={{ width: '10px' }}> </div>
            <Button
              label="Confirm"
              id="submit"
              type="submit"
              onClick={props.onClickDelete}
            />
          </div>
        )}
      </Box>
    </Modal>
  );
}
