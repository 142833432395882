import { gql } from '@apollo/client';

export const LIST_APP_CONFIGS = gql`
  mutation ($pagination: PaginationInput) {
    listAppConfigs(pagination: $pagination) {
      id
      name
      description
      connectCode
      addedBy
    }
  }
`;

export const GET_APP_CONFIG = gql`
  mutation ($id: String) {
    getAppConfig(id: $id) {
      id
      name
      description
      connectCode
      addedBy
    }
  }
`;
