import { gql } from '@apollo/client';

export const GET_DASHBOARD_URL = gql`
  query ($operation: String, $input: GetDashboardUrlInput!) {
    getDashboardUrl(operation: $operation, input: $input) {
      status
      body
    }
  }
`;
