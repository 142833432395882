import React from 'react';
import '../Tree.scss';

export default function TreeStart(props) {
  return (
    <div className="tf-tree" id="fullTree">
      <ul key="baseTreeStart">
        <li key="start">
          <ul key="treeStart">{props.children}</ul>
        </li>
      </ul>
    </div>
  );
}
