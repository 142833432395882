import { useState, useEffect } from "react"
import {TableHead, TableRow, TableCell, Pagination, Stack } from '@mui/material';
import { Table, TextInput, Switch } from 'Stories';
import {FormControlLabel} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Divider } from "@mui/material";
const DNA_RESPONSES_PER_PAGE = 10;
export const DNAResponseTable = props => {
    const {
        dnaResponseHeaders,
        filteredDNAResponses,
        setIsFilteredByActive,
        handleFilterChange,
        isFilteredByActive
    } = props;

    const [ paginatedData, setPaginatedData ] = useState(filteredDNAResponses.slice(0, DNA_RESPONSES_PER_PAGE));
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ totalPages, setTotalPages ] = useState(1);

    useEffect(() => {
        let totalPages = Math.ceil(filteredDNAResponses.length / DNA_RESPONSES_PER_PAGE)
        
        setCurrentPage(0);
        setTotalPages(totalPages);
        let data = filteredDNAResponses.slice(currentPage * DNA_RESPONSES_PER_PAGE, currentPage * DNA_RESPONSES_PER_PAGE + DNA_RESPONSES_PER_PAGE);
        setPaginatedData(data);
    }, [filteredDNAResponses])

    useEffect(() => {
        let data = filteredDNAResponses.slice(currentPage * DNA_RESPONSES_PER_PAGE, currentPage * DNA_RESPONSES_PER_PAGE + DNA_RESPONSES_PER_PAGE);
        setPaginatedData(data);
    }, [currentPage])

    return <div>
    <Table
        customHeader={
            <TableHead className={"pm-table-header"}>
                <TableRow>
                    {dnaResponseHeaders.map((header, index) => {
                        return <TableCell
                            align={header.alignment ? header.alignment : 'left'}
                            size={header.size ? header.size : 'medium'}
                            key={'TableLabel_' + index}
                            variant={"head"}
                            sx={{ fontWeight: "bold"}}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center"
                            }}>
                            <div>{header.label}</div>
                            {
                                header.isSearchable && <Divider orientation={"vertical"} flexItem style={{ marginLeft: 20, marginRight: 20}}/>                                                
                            }
                            {
                                header.isSearchable && 
                                header.type === "string" ? <div style={{ display: "flex", scale: 0.8}}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                                        <Search sx={{ fontSize: 20 }}/>
                                    </div>
                                    
                                    <TextInput 
                                        size={"small"}
                                        fullWidth={false}
                                        onChange={(e) => handleFilterChange(e, header.key)}
                                        label={<span>
                                            {header.searchPlaceholderText}
                                        </span>}
                                />
                                </div> :
                                <div>
                                        <FormControlLabel
                                            value="start"
                                            control={<Switch 
                                                onChange={e => setIsFilteredByActive(e)}
                                                style={{
                                                color: "#52bf90", // Green color for the track when switched on
                                                '& .MuiSwitch-track': {
                                                backgroundColor: isFilteredByActive ? "#52bf90" : null,
                                                },
                                            }}/>}
                                            label={header.searchPlaceholderText}
                                            labelPlacement="start"
                                        />
                                    
                                </div>
                                
                            }
                            </div>
                        
                    </TableCell>
                    })}
                </TableRow>
            </TableHead>
        } 
        labels={dnaResponseHeaders}
        data={paginatedData}
        tableCellGeneralStyles={{
            color: "green"
        }}
        tableCellColumnStyles={{
            status: {
                fontWeight: 700
            }
        }}
    />
    {totalPages > 1 && <Stack spacing={2}>
        <Pagination count={totalPages} shape="rounded" onChange={(e,v) => setCurrentPage(v - 1)}/>
    </Stack>}
</div>
}