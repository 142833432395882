import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal, theme, Card } from 'Stories';
import tasks from 'Assets/Tasks.jpg';
import admin from 'Assets/Admin.jpg';

import { store } from 'Contexts/GlobalState';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// import PageForm÷ from './Form.js';

import './Main.css';

import Header from 'Pages/ViewConfig/Header';

export default function Config(props) {
  let colors = theme.palette.general;

  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const location = useLocation();
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  let navigate = useNavigate();

  const { appConfig } = location.state;

  const onClickBack = (e) => {
    console.log(dispatch, state, e);
    dispatch({
      type: 'setCurrentAppConfig',
      appConfig: null,
    });
    navigate('/app-configs');
  };

  let allButtons = {
    main: [
      { title: 'Condition Tracker', page: 'conditions' },
      { title: 'Variable Tracker', page: 'variables' },
    ],
    secondary: [
      { title: 'Symptom Tracker', page: 'symptoms' },
      { title: 'Bio Tracker', page: 'bio-tracker' },
      { title: 'RX Tracker', page: 'rx-tracker' },
      { title: 'Physician Connect', page: 'physician-connect' },
      { title: 'User Fields', page: 'user-fields' },
      { title: 'Products', page: 'products' },
      { title: 'Survey Builder', page: 'surveys' },
    ],
  };

  const onClickButton = (e, page) => {
    console.log('did click: ', e, page);
    navigate('../' + page, { state: { appConfig: appConfig } });
  };

  if (!appConfig && !appConfig.name) {
    return (
      <Link
        to={{
          pathname: '/app-configs',
        }}
      />
    );
  } else {
    return (
      <div>
        <Header
          title={appConfig.name}
          prevPage={'/view-config'}
          prevPageName={'configuration home'}
        />

        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {allButtons.main.map((button) => {
              return (
                <Card
                  key={uuidv4()}
                  onClick={(e) => onClickButton(e, button.page)}
                  label={button.title}
                  color={'blue'}
                />
              );
            })}
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {allButtons.secondary.map((button) => {
              return (
                <Card
                  key={uuidv4()}
                  onClick={(e) => onClickButton(e, button.page)}
                  label={button.title}
                  color={'green'}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
