import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation } from 'react-router-dom';

import { store } from 'Contexts/GlobalState';
import { alertClasses } from '@mui/material';

import { useLazyQuery } from '@apollo/client';
import { LIST_DASHBOARDS } from './GraphQL/queries';

const labels = [
  {
    alignment: 'left',
    label: 'Dashboard Name',
    key: 'name',
  },
  {
    alignment: 'left',
    label: 'Description',
    key: 'description',
  },
  {
    alignment: 'right',
    label: 'Date Updated',
    key: 'updatedAt',
  },
  {
    alignment: 'right',
    label: 'Actions',
    key: 'actions',
    size: 'small',
  },
];

export default function Dashboards(props) {
  const [dashboards, setDashboards] = useState([]);
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  let navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [
    listDashboards,
    { loading: listLoading, data: listData, error: listError },
  ] = useLazyQuery(LIST_DASHBOARDS, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination: {
        page: page - 1,
        limit: pageLimit,
      },
    },
  });

  useEffect(() => {
    if (listData && listData.listDashboards) {
      listData.listDashboards.items.forEach((item) => {
        item.actions = {
          edit: false,
          delete: false,
          manage: true,
        };
      });

      setDashboards(listData.listDashboards.items);
    } else if (listError) {
      console.log('Failed getting dashboards: ', listError);
    }
  }, [listData, listError]);

  const onClickManage = (e) => {
    console.log('Dashboard ', JSON.stringify(e));
    dispatch({
      type: 'setCurrentDashboard',
      dashboard: e,
    });
    navigate('/view-dashboard', { state: { dashboard: e } });
  };

  useEffect(() => {
    listDashboards();
  }, []);

  return (
    <div>
      <Table labels={labels} data={dashboards} onClickManage={onClickManage} />
    </div>
  );
}
