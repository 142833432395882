import { useState } from 'react';
import { Paper } from '@mui/material';
import { TextInput, Form, Button, Table, Modal, Card, Select } from 'Stories';
import './CompanyInfo.css';
import ThemeCard from './ThemeCard.js';
export default function CompanyInfo(props) {
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [selectedThemeIndex, setSelectedThemeIndex] = useState(0);
  const [mm, setMm] = useState('');
  const [yy, setYy] = useState('');
  const themes = [
    {
      name: 'red',
      color: '#ee4035',
    },
    {
      name: 'blue',
      color: '#252261',
    },
    {
      name: 'yellow',
      color: '#faae3f',
    },
  ];
  const handleChange = (e, setValue) => {
    setValue(e);

    // For saving after editing
    const delayDebounceFn = setTimeout(() => {
      saveChanges();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };

  const saveChanges = () => {
    // Fake saving
    //console.log("Saving", {username, givenName, familyName, email, phoneNumber})
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
      setIsSaved(true);
    }, 400);
    // Placeholder method for saving changes through API
  };

  return (
    <div>
      <div>
        <h3>{'Company Information'}</h3>
        <div className="company-info-and-payment">
          <Paper variant="outlined" className="company-info">
            <div className="company-info-input">
              <TextInput
                label="Company Name"
                onChange={(e) => handleChange(e, setCompanyName)}
                value={companyName}
              />
            </div>
            <div className="company-info-input">
              <TextInput
                label="Phone Number"
                onChange={(e) => handleChange(e, setPhoneNumber)}
                value={phoneNumber}
                type={'tel'}
              />
            </div>
            <div className="company-info-input">
              <TextInput
                label="Address"
                onChange={(e) => handleChange(e, setAddress)}
                value={address}
              />
            </div>
          </Paper>
          <Paper variant="outlined" className="payment-method">
            <div className="card-logo-payment-plan-container">
              <div className="card-logo">
                {/* Card logo goes here */}
                {'VISA'}
              </div>
              <div className="payment-plan">
                <h4 className="plan-text">{'Basic Plus Plan'}</h4>
                <h4 className="plan-text">{'$350 per month'}</h4>
              </div>
            </div>
            <div className="card-number">
              <TextInput
                label="Card Number"
                onChange={(e) => handleChange(e, setCreditCardNumber)}
                value={creditCardNumber}
                type={'number'}
              />
            </div>
            <div className="card-expires-container">
              <div className="card-expires-mm">
                <TextInput
                  label="MM"
                  onChange={(e) => handleChange(e, setMm)}
                  value={mm}
                  type={'number'}
                />
              </div>
              <div className="card-expires-yy">
                <TextInput
                  label="YY"
                  onChange={(e) => handleChange(e, setYy)}
                  value={yy}
                  type={'number'}
                />
              </div>
              <div className="plan-due-date">
                <h4 className="payment-due-text">{'Next Payment Due'}</h4>
                <h4 className="payment-due-text">{'12th September 2023'}</h4>
              </div>
            </div>
          </Paper>
        </div>

        <div className="company-logos">
          <Paper variant="outlined" className="company-theme-and-logo">
            <img
              src="https://t3.ftcdn.net/jpg/01/18/01/98/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg"
              className="company-logo"
            />
            <div
              className="company-theme-cards"
              style={{ background: themes[selectedThemeIndex].color }}
            >
              {themes.map((theme, index) => {
                return (
                  <ThemeCard
                    key={index}
                    color={theme.color}
                    onClick={() => {
                      console.log('Clicked on ', index);
                      setSelectedThemeIndex(index);
                    }}
                  />
                );
              })}
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
