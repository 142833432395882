import { DASHBOARD_IDS_LIST } from 'Services/GlobalConstants';
export default function DashboardPagesPicker(props) {
  const { dashboardId, setDashboardId, setPathname } = props;
  return (
    <div style={{ display: 'flex', padding: '20px 0px' }}>
      {DASHBOARD_IDS_LIST.map((item, index) => {
        const { id, name, routeName } = item;
        return (
          <div
            style={{
              backgroundColor: dashboardId === id ? '#2004ad' : '#d8d8d8',
              color: dashboardId === id ? 'white' : 'black',
            }}
            key={index}
            className="tempButton"
            onClick={() => {
              setDashboardId(id);
              setPathname(routeName);
            }}
          >
            {name}
          </div>
        );
      })}
    </div>
  );
}
