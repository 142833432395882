import { gql } from '@apollo/client';

export const LIST_SURVEY_NODES = gql`
  query ($surveyId: String!) {
    listSurveyNodes(surveyId: $surveyId) {
      data {
        id
        surveyId
        identifier
        question
        questionNumberValue
        questionType
        questionSubType
        parent
        isMatch
        isPath
        title
        answers {
          id
          value
          variable
          scoreValue
        }
        characterLimit
        upperLimit
        lowerLimit
        minDate
        maxDate
        addedBy
        condition
      }
      item {
        id
        surveyId
        identifier
        question
        questionNumberValue
        questionType
        questionSubType
        parent
        isMatch
        isPath
        title
        answers {
          id
          value
          variable
          scoreValue
        }
        characterLimit
        upperLimit
        lowerLimit
        minDate
        maxDate
        addedBy
        condition
      }
      nextToken
    }
  }
`;

export const GET_SURVEY_NODE = gql`
  query ($id: String!) {
    getSurveyNode(id: $id) {
      data {
        id
        surveyId
        identifier
        question
        questionNumberValue
        questionType
        questionSubType
        parent
        isMatch
        isPath
        title
        answers {
          id
          value
          variable
          scoreValue
        }
        characterLimit
        upperLimit
        lowerLimit
        minDate
        maxDate
        addedBy
        condition
      }
      item {
        id
        surveyId
        identifier
        question
        questionNumberValue
        questionType
        questionSubType
        parent
        isMatch
        isPath
        title
        answers {
          id
          value
          variable
          scoreValue
        }
        characterLimit
        upperLimit
        lowerLimit
        minDate
        maxDate
        addedBy
        condition
      }
      nextToken
    }
  }
`;
