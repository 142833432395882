export const sections = [
  {
    title: 'Section 1',
    content: [
      {
        title: 'The Opening',
        path: null,
        imgUrl: require('../../Assets/SECTION 1 Image.jpg'),
        video: 'Jose - Introduction.mov',
      },
    ],
  },
  {
    title: 'Section 2',
    content: [
      {
        title: 'Introducing Inner Immersion',
        path: null,
        imgUrl: require('../../Assets/SECTION 2 Image.jpg'),
        video: 'Introducing Inner Immersion.mov',
      },
    ],
  },
  {
    title: 'Section 3',
    content: [
      {
        title: "Jose's story - Part 1",
        path: null,
        imgUrl: require('../../Assets/SECTION 3 Image.jpg'),
        video: 'Jose Part 1 of 2.mov',
      },
      {
        title: "Jose's story - Part 2",
        path: null,
        imgUrl: require('../../Assets/SECTION 3 Image.jpg'),
        video: 'Jose Part 2 of 2.mov',
      },
    ],
  },
  {
    title: 'Section 4',
    content: [
      {
        title: "Facilitator's Story",
        path: null,
        imgUrl: require('../../Assets/SECTION 4 Image.jpg'),
        video: "Facilitator's Story.mov",
      },
    ],
  },
  {
    title: 'Section 5',
    content: [
      {
        title: 'Meditation',
        description: 'Traveling from past to present - Part 1',
        path: null,
        imgUrl: require('../../Assets/SECTION 5 Image.jpg'),
        video: 'Traveling from Past to Present 1.mov',
      },
      {
        title: 'Meditation',
        description: 'Traveling from past to present - Part 2',
        path: null,
        imgUrl: require('../../Assets/SECTION 5 Image.jpg'),
        video: 'Traveling from Present to Past 2.mov',
      },
    ],
  },
  {
    title: 'Section 6',
    content: [
      {
        title: 'Meditation',
        description: 'Healing Angelic - Part 1',
        path: null,
        imgUrl: require('../../Assets/SECTION 6 Image.jpg'),
        video: 'Healing Angelic Meditation 1.mov',
      },
      {
        title: 'Meditation',
        description: 'Healing Angelic - Part 2',
        path: null,
        imgUrl: require('../../Assets/SECTION 6 Image.jpg'),
        video: 'Healing Angelic Meditation 2.mov',
      },
    ],
  },
  {
    title: 'Section 7 (Last Section)',
    content: [
      {
        title: 'Closing Ceremony',
        path: null,
        imgUrl: require('../../Assets/SECTION 7 Image.jpg'),
        video: 'Jose Closing Video.mov',
      },
    ],
  },
];
