import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import './styles.css';

import { useMutation } from '@apollo/client';
import { EXECUTE_ADMIN_TASK } from './GraphQL/mutations.js';

export default function ViewImmersionVideo(props) {
  const [url, setUrl] = useState(null);
  const location = useLocation();

  console.log('STAE: ', props, location);
  const { video, type } = location.state;
  console.log('PROPS: ', video);

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  // TODO
  const getVideo = async () => {
    let resp = await executeAdminTask({
      variables: {
        operation: type ? type : 'getS3Link',
        input: JSON.stringify({ key: video }),
      },
    });

    console.log('RESP', resp);

    let x = JSON.parse(resp.data.executeAdminTask.body).signedUrl;
    setUrl(x);
  };

  useEffect(() => {
    getVideo();
  }, []);

  return (
    <div>
      <ReactPlayer
        url={url}
        controls={true}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
              onContextMenu: (e) => e.preventDefault(),
            },
          },
        }}
      />
    </div>
  );
}
