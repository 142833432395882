import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal, theme, Card } from 'Stories';

import { store } from 'Contexts/GlobalState';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import './Main.css';

import Header from 'Pages/ViewConfig/Header';

const labels = [
  {
    alignment: 'left',
    label: 'First Name',
    key: 'name',
  },
  {
    alignment: 'left',
    label: 'Last Name',
    key: 'description',
  },
  {
    alignment: 'right',
    label: 'Email',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: 'Gender',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: 'DOB',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: 'Phone',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: 'App Install Date',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: ' ',
    key: 'actions',
    size: 'small',
  },
];

export default function ViewConfig(props) {
  let colors = theme.palette.general;

  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const location = useLocation();
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  let navigate = useNavigate();

  const { appConfig } = location.state;

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getItems = async () => {
    // const item = await API.graphql(graphqlOperation(listAppUsers, {}));
    // let items = item.data.listAppUsers.items;
    // for (var i = 0; i < items.length; ++i) {
    //   let name = items[i].addedBy;
    //   if (items[i].addedByUserInfo) {
    //     name =
    //       items[i].addedByUserInfo.given_name +
    //       ' ' +
    //       items[i].addedByUserInfo.family_name;
    //   }
    //   items[i] = {
    //     ...items[i],
    //     addedByDisplayName: name,
    //     actions: {
    //       edit: true,
    //       delete: true,
    //       manage: true,
    //     },
    //   };
    //   console.log(items[i]);
    // }
    // setData(items);
  };

  const onClickEdit = (e) => {
    console.log('click: ', e);
  };

  const onClickDelete = (e) => {
    console.log('click: ', e);
  };

  const onClickManage = (e) => {
    console.log('click: ', e);
  };

  return (
    <div>
      <Header
        title={'Manage Users'}
        prevPage={'/app-configs'}
        prevPageName={'configuration home'}
      />

      <Table
        labels={labels}
        data={data}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickManage={onClickManage}
      />
    </div>
  );
}
