import React, { useState, useContext } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';

export default function PageForm(props) {
  const { id, name, description } = props.editInfo;

  const onSubmit = async (e) => {
    props.setEditInfo({});
    props.setModalOpen(false);
  };

  return (
    <Form style={{ display: 'block' }} onSubmit={onSubmit}>
      <TextInput label="Name" id="name" variant="outlined" value={name} />
      <TextInput
        label="Description"
        id="description"
        variant="outlined"
        rows={4}
        multiline
        value={description}
      />
      <Button label="submit" id="submit" type="submit" />
    </Form>
  );
}
