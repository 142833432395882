import DrugInteractionsPresentImage from "../../../Assets/DETECTED Interactions Image.svg";
import DrugInteractionsAbsentImage from "../../../Assets/NO Drug Interactions Image.svg";

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "@mui/material/Link";
export const DrugInteractionsBar = props => {
    const { isDIError, isDILoading, isDrugInteractionsDetected, setIsDrugInteractionsOpen } = props;
    return <div className={"pm-drug-interactions " + ((isDrugInteractionsDetected || isDIError) && !isDILoading ? "pm-di-red" : " pm-di-transparent")}>
    <div>
        {!isDILoading && isDrugInteractionsDetected && <h2 className={"pm-di-alert"}>Drug Interactions Detected!</h2>}
        {!isDILoading && !isDrugInteractionsDetected && !isDIError && <h2>No drug interactions</h2>}
        {isDILoading && <h2 className={"pm-di-transparent"}>Loading Drug Interactions</h2>}
        {!isDILoading && isDIError && <h2 className={"pm-di-alert"}>Error fetching drug interactions</h2>} {/* Error message header*/}
        {!isDILoading ? <h4 className={"pm-di-text"}>
            {
                isDrugInteractionsDetected ? 
                <div>
                    
                    <div className="pm-di-alert">
                        
                        {"This patient has drug interactions"}
                    </div>
                    <Link href="#" variant={"text"} onClick={() => setIsDrugInteractionsOpen(true)}>{"View interactions details"}</Link>
                </div> :
                <div className={isDIError ? "pm-di-alert" : ""}>
                    <FontAwesomeIcon icon={solid("info-circle")}/>
                    {!isDIError ? "This patient currently has no drug interactions" : "There was an error trying to fetch drug interactions. Please try again later."}
                    
                </div>
                
            }
        </h4> : <h4 className={"pm-di-transparent"}>{"Fetching details of drug interactions"}</h4>}
    </div>
    <img src={ isDrugInteractionsDetected || isDIError ? DrugInteractionsPresentImage : DrugInteractionsAbsentImage} alt="Drug interactions image"/>
</div>
}