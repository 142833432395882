import React, { createContext, useReducer } from 'react';
const initialState = {
  appConfig: null,
};

const store = createContext(initialState);
const { Provider, Consumer } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'setCurrentAppConfig':
        const changeAppConfig = {
          ...state,
          appConfig: action.appConfig,
        };
        return changeAppConfig;
      case 'setCurrentDashboard':
        const changeDashboard = {
          ...state,
          dashboard: action.dashboard,
        };
      case 'setEditingNode':
        const changeEditingNode = {
          ...state,
          editingNode: action.editingNode,
        };
        return changeEditingNode;
      default:
        console.log('GlobalStore action failed: ', action);
        throw new Error();
    }
  }, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
export { store, StateProvider, Consumer };
