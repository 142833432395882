var childrenTracker = {};
var deepestDepth = 0;
var arrows = [];

export function embedTree(t, canSplit, type = 'standard') {
  console.log('EMBEDDING!: ', t);
  childrenTracker = {};
  deepestDepth = 0;

  if (t.length === 0) {
    return { tree: [], arrows: [] };
  } else {
    arrows = [];
    let treeInd = -1;

    for (var i = 0; i < t.length; ++i) {
      if (!t[i].parentAlt) {
        t[i].parentAlt = [];
      }

      if (!t[i].parentCombined) {
        t[i].parentCombined = t[i].parent
          ? [t[i].parent].concat(t[i].parentAlt)
          : [];
      }

      if (
        t[i].parentCombined === null ||
        t[i].parentCombined[0] === 'null' ||
        t[i].parentCombined === undefined ||
        t[i].parentCombined.length === 0
      ) {
        treeInd = i;
      } else {
        if (type !== 'version') {
          for (var j = 0; j < t[i].parentCombined.length; ++j) {
            arrows.push({
              start: t[i].parentCombined[j],
              end: t[i].id,
            });
          }
        }
      }

      t[i].children = [];
      let children = [];

      let rawChildResp = findChildren(t, t[i].id, arrows);

      t[i].children = rawChildResp.children;
      t[i].childRef = rawChildResp.childRef;
      t[i].childNeedsOffset = rawChildResp.childNeedsOffset;

      let isOnlyPath = true;

      for (var j = 0; j < t[i].children.length; ++j) {
        if (!t[i].children[j].isPath) {
          isOnlyPath = false;
        }
      }

      if (t[i].children.length > children.length) {
        t[i].children = mergeArrays(t[i].children, children);
      } else {
        t[i].children = mergeArrays(children, t[i].children);
      }

      if (type !== 'version') {
        if (!t[i].children || t[i].children.length === 0) {
          addOptionButton(t[i], arrows, 'add');
          addOptionButton(t[i], arrows, 'split');
        } else if (isOnlyPath) {
          addOptionButton(t[i], arrows, 'split');
          addOptionButton(t[i], arrows, 'add');
        } else {
          addOptionButton(t[i], arrows, 'split');
        }
      }
    }

    if (t.length > 1) insertChildren(t[treeInd].children, t);

    let childrenToSimplify = [];

    for (var key in childrenTracker) {
      if (childrenTracker[key] > 1) {
        childrenToSimplify.push({
          id: key,
          amt: childrenTracker[key],
        });
      }
    }

    let treeToUse = [t[treeInd]];

    if (type !== 'version') {
      for (var i = 0; i < childrenToSimplify.length; ++i) {
        searchTree(t[treeInd], childrenToSimplify[i].id, 0, false, null, t);
        searchTree(t[treeInd], childrenToSimplify[i].id, 0, true, null, t);
        deepestDepth = 0;
      }
    }

    return { tree: treeToUse, arrows: arrows };
  }
}

function searchTree(
  element,
  id,
  timeThru = 0,
  deleteOnRun,
  parentNodeId,
  rawTree
) {
  if (element.id === id) {
    if (deleteOnRun) {
      if (deepestDepth > timeThru) {
        for (var i = 0; i < rawTree.length; ++i) {
          if (rawTree[i].id === parentNodeId) {
            for (var j = rawTree[i].children.length - 1; j >= 0; --j) {
              if (rawTree[i].children[j].id === id) {
                for (var k = 0; k < arrows.length; ++k) {
                  if (
                    arrows[k].start === rawTree[i].children[j].parent &&
                    arrows[k].end === rawTree[i].children[j].id
                  ) {
                    arrows.splice(k, 1);
                  }
                }

                let originalId = rawTree[i].children[j].id + '';
                let newNodeId =
                  rawTree[i].children[j].id +
                  (rawTree[i].children[j].id.indexOf('hiddenNode') === -1
                    ? '_hiddenNode' + timeThru
                    : '');

                rawTree[i].children[j] = {
                  ...rawTree[i].children[j],
                  id: newNodeId,
                  children: [],
                  hidden: true,
                };

                arrows.push({
                  start: rawTree[i].children[j].parent,
                  end: newNodeId,
                });

                arrows.push({
                  start: newNodeId,
                  end: originalId,
                });

                // rawTree[i].children.splice(j, 1);
                // rawTree[i].childNeedsOffset.splice(0, 1);
              }
            }
          }
        }
      }
    } else {
      if (deepestDepth < timeThru) {
        deepestDepth = timeThru + 0;
      }
    }
  }

  if (element.children != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(
        element.children[i],
        id,
        ++timeThru,
        deleteOnRun,
        element.id,
        rawTree
      );
    }
    return result;
  }
  return null;
}

function addOptionButton(item, connectors, type) {
  let itemId = `${item.id}_${type === 'split' ? 'Split' : 'Add'}Btn`;
  item.children.push({
    id: itemId,
    type: type,
    parentId: item.id,
  });
  connectors.push({
    start: item.id,
    end: itemId,
  });
}

export function insertChildren(returnArr, fullArr) {
  for (var i = 0; i < returnArr.length; ++i) {
    for (var j = 0; j < fullArr.length; ++j) {
      if (fullArr[j].id === returnArr[i].id) {
        returnArr[i].children = fullArr[j].children;
        insertChildren(fullArr[j].children, fullArr);
      }
    }
  }
}

export function mergeArrays(mainArr, subArr) {
  for (var i = 0; i < mainArr.length; ++i) {
    let currId = mainArr[i].id;
    for (var j = 0; j < subArr.length; ++j) {
      if (currId === subArr[j].id) {
        for (let key in subArr[j]) {
          mainArr[i][key] = subArr[j][key];
        }
      }
    }
  }

  return mainArr;
}

export function findChildren(t, id) {
  let children = [];
  let childRef = [];
  let childNeedsOffset = [];

  for (var i = 0; i < t.length; ++i) {
    if (t[i].parentCombined && t[i].parentCombined.indexOf(id) > -1) {
      if (t[i].parentAlt && t[i].parentAlt.length > 0) {
        console.log(t[i].parentAlt);
        childNeedsOffset.push({ hidden: true });
      }
      children.push(t[i]);
      childRef.push(t[i].id);

      if (!childrenTracker[t[i].id]) {
        childrenTracker[t[i].id] = 0;
      }

      ++childrenTracker[t[i].id];
    }
  }

  return {
    children,
    childRef,
    childNeedsOffset: [],
  };
}
