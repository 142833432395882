import Module1 from '../../Assets/Module Cards/Module_1.svg';
import Module2 from '../../Assets/Module Cards/Module_2.svg';
import Module3 from '../../Assets/Module Cards/Module_3.svg';
import Module4 from '../../Assets/Module Cards/Module_4.svg';
import Module5 from '../../Assets/Module Cards/Module_5.svg';
import Module6 from '../../Assets/Module Cards/Module_6.svg';

export const moduleHeaders = [
  {
    key: 'step',
    label: 'Step',
    sx: { width: 150 },
  },
  {
    key: 'video',
    label: 'Video',
    sx: { width: 300 },
  },
  {
    key: 'assignment',
    label: 'Assignment',
  },
  {
    key: 'reflection_question',
    label: 'Reflection Question',
  },
  {
    key: 'support_material',
    label: 'Support Material',
    sx: { width: 400 },
  },
];

export const modules = [
  {
    title: 'Module 1 Training',
    enableDate: '8/21/2023',
    image: Module1,
    path: `module-1`,
    content: [
      {
        step: 'Step 1',
        video: {
          title: 'Video 1: Introduction to This Course',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_1_Video_Video+1+-+Introduction+to+This+Course.mp4',
          //link:'https://download.samplelib.com/mp4/sample-5s.mp4'
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: `Program Module Overview`,
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_1_Support_Program+Module+Overview.png',
        },
      },
      {
        step: 'Step 2',
        video: {
          title: `Video 2: The Training Program`,
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_2_Video_Video+2+-+The+Training+Program.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 3',
        video: {
          title: `Video 3: How Did Inner Immersion Start?`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_3_Video_Video+3+-+How+Did+Inner+Immersion+Start.mp4`,
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 4',
        video: {
          title: `Video 4: What Happens in a Group Inner Immersion?`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_4_Video_Video+4+-+What+Happens+in+a+Group+Inner+Immersion.mp4`,
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: `22 Group Immersion Steps`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_4_Support_22+Inner+Immersion+Program+Steps.pdf`,
        },
      },
      {
        step: 'Step 5',
        video: {
          title: `Video 5: What Happens During a One-on-One Session?`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_5_Video_Video+5+-+What+Happens+During+a+One-on-One+Session.mp4`,
        },
        assignment: null,
        reflection_question: null,
        support_material: [
          {
            title: `One-on-One Steps (1st)`,
            link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_5_Support_One-on-One+Steps+-+1.png`,
          },
          {
            title: `One-on-One Steps (2nd)`,
            link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_5_Support_One-on-One+Steps+-+2.png`,
          },
        ],
      },
      {
        step: 'Step 6',
        video: {
          title: `Video 6: Conducting an Inner Immersion – A Practitioner’s Perspective`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_6_Video_Video+6+-+Conducting+an+Inner+Immersion+%E2%80%93+A+Practitioner%E2%80%99s+Perspective.mp4`,
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 7',
        video: {
          title: `Video 7: What to look for during Inner Immersion Sessions?`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_7_Video_Video+7+-+What+to+look+for+during+Inner+Immersion+Sessions.mp4`,
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: `What to Look For`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_7_Support_What+to+look+for+-+Group+Sessions.png`,
        },
      },
      {
        step: 'Step 8',
        video: [
          {
            title: `  Video 8: Introduction to Jose's NDE Story (Part 1)`,
            link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module%201%20Assets/Module_1_Group_8_Video_Video%208%20-%20The%20pre-recorded%20NDE%20Story.mp4`,
          },
          {
            title: `Video 8: Jose's NDE Story (Part 2)`,
            link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_8_Video_Video+8+-+Jose's+NDE+Story+(Part+2).mp4`,
          },
        ],
        assignment: null,
        reflection_question: {
          title: `Video 8 — Question`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_8_Reflection_Long-form+Question.docx`,
        },
        support_material: {
          title: `Fact Sheet: NDE with References`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_8_Support_NDE+Fact+Sheet.pdf`,
        },
      },
      {
        step: 'Step 9',
        video: {
          title: `Video 9: My NDE and the Inner Immersion Modality`,
          link: `https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+1+Assets/Module_1_Group_9_Video_Video+9+-+My+NDE+and+the+Inner+Immersion+Modality.mp4`,
        },
        assignment: {
          title: `Module 1 Quiz (30 pts)`,
          link: `https://forms.gle/3fBr2AAouii6zyZR7`,
        },
        reflection_question: null,
        support_material: null,
      },
    ],
  },
  {
    title: 'Module 2 Training',
    enableDate: '9/4/2023',
    image: Module2,
    path: `module-2`,
    content: [
      {
        step: 'Step 1',
        video: {
          title: 'Intro Video',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_1_Video_Intro.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 2',
        video: {
          title: 'Video 1: The Shredding & Surrender',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_2_Video_Video+1+-+The+Shredding+%26+Surrender.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 3',
        video: {
          title: 'Video 2: New Horizons: Acceptance and Letting Go',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_3_Video_Video+2+-+New+Horizons+-+Acceptance+and+Letting+Go.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: 'Surrender & Shredding',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_3_Support_Shredding+and+Surrender.png',
        },
      },
      {
        step: 'Step 4',
        video: {
          title: 'Video 3: The Facilitator’s Role – Part One',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_4_Video_Video+3+-+The+Facilitator%E2%80%99s+Role+%E2%80%93+Part+One.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 5',
        video: {
          title: 'Video 4: The Facilitator’s Role – Part Two',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_5_Video_Video+4+-+The+Facilitator%E2%80%99s+Role+%E2%80%93+Part+Two.mp4',
        },
        assignment: null,
        reflection_question: (
          <div>
            When you have finished watching video 4, answer{' '}
            <a href="https://forms.gle/KutgaHJkq5tpgt8k7">these questions</a>
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 6',
        video: {
          title: 'Video 5: The Healing Path – Part One',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_6_Video_Video+5+-+The+Healing+Path+%E2%80%93+Part+One.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: 'Strengthen Your Intuition',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_6_Support_Strengthen+Your+Intuition.png',
        },
      },
      {
        step: 'Step 7',
        video: {
          title: 'Video 6: The Healing Path – Part Two',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+2+Assets/Module_2_Group_7_Video_Video+6+-+The+Healing+Path+%E2%80%93+Part+Two.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
    ],
  },
  {
    title: 'Module 3 Training',
    enableDate: '9/11/2023',
    image: Module3,
    path: `module-3`,
    content: [
      {
        step: 'Step 1',
        video: {
          title: 'Video 1: Setting the Stage',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_1_Video_Video+1+-+Setting+the+Stage.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: 'Evidence-Based Inner Immersion Elements',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_1_Support_Evidence+Based+Components+of+II.png',
        },
      },
      {
        step: 'Step 2',
        video: {
          title: 'Video 2: First Set of Drawings & Questions',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_2_Video_Video+2+-+First+Set+of+Drawings+%26+Questions.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: '13 Questions',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_2_Support_Inner+Immersion+13+Questions.docx.pdf',
        },
      },
      {
        step: 'Step 3',
        video: {
          title: 'Video 3: Building Trust',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_3_Video_Video+3+-+Building+Trust.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 4',
        video: {
          title: 'Video 4: The Guided Meditations',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_4_Video_Video+4+-+The+Guided+Meditations.mp4',
        },
        assignment: null,
        // "reflection_question": {
        //     "title": "Video 4 — Question",
        //     "link": "https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_4_Reflection_Long-form+Questions.docx"
        // },
        reflection_question: (
          <div>
            When you have finished watching video 4, answer{' '}
            <a href="https://forms.gle/qkep4fC9r2T6U7Nt5">these questions</a>
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 5',
        video: {
          title: 'Video 5: The Second Set of Drawings & Questions',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_5_Video_Video+5+-+The+Second+Set+of+Drawings+%26+Questions.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 6',
        video: {
          title: 'Video 6: Intentions & Interpretations',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_6_Video_Video+6+-+Intentions+%26+Interpretations.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 7',
        video: {
          title: 'Video 7: Closing the Circle',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_7_Video_Video+7+-+Closing+the+Circle.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 8',
        video: {
          title: 'Video 8: Inner Immersion One-on-One',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_8_Video_Video+8+-+Inner+Immersion+One-on-One.mp4',
        },
        assignment: null,
        // "reflection_question": {
        //     "title": "Video 8 — Question",
        //     "link": "https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+3+Assets/Module_3_Group_8_Relection_Long-form+Questions.docx"
        // },
        reflection_question: (
          <div>
            When you have finished watching video 4, answer{' '}
            <a href="https://forms.gle/FX8b6QinAApgRBAd6">this question</a>
          </div>
        ),
        support_material: null,
      },
    ],
  },
  {
    title: 'Module 4 Training',
    enableDate: '9/18/2023',
    image: Module4,
    path: `module-4`,
    content: [
      {
        step: 'Step 1',
        video: {
          title: 'Video 1: Colors',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_1_Video+1+-+Colors.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: {
          title: 'Inner Immersion Color & Artwork Interpretation',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_1_Support_Inner+Immerson+Color+and+Artwork+Interpretation+Guidelines.pdf',
        },
      },
      {
        step: 'Step 2',
        video: {
          title: 'Video 2: Position on the Page',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_2_Video+2+-+Position+on+the+Page.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 3',
        video: {
          title: 'Video 3: Shapes, Lines and Directions',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_3_Video+3+-+Shapes%2C+Lines+and+Directions.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 4',
        video: {
          title: 'Video 4: Symbolism and Additional Elements',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_4_Video+4+-+Symbolism+and+Additional+Elements.mp4',
        },
        assignment: null,
        // "reflection_question": {
        //     "title": "Video 4 — Question",
        //     "link": "https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_4_Reflection_Long-form+Question.docx"
        // },
        reflection_question: (
          <div>
            When you have finished watching video 4, answer{' '}
            <a href="https://forms.gle/puB2wgzzcR5Peqcg7">this question.</a>
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 5',
        video: {
          title: 'Video 5: Evidence Based Components and Final Thoughts',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+4+Assets/Module_4_Group_5_Video+5+-+Evidence+Based+Components+and+Final+Thoughts.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
    ],
  },
  {
    title: 'Module 5 Training',
    enableDate: '9/25/2023',
    image: Module5,
    path: `module-5`,
    content: [
      {
        step: 'Step 1',
        video: {
          title: 'Examples video',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_1_Video_Examples.mp4',
        },
        assignment: null,
        reflection_question: null,
        support_material: null,
      },
      {
        step: 'Step 2',
        video: {
          title: 'Case Study 1',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_2_Video_Case+Study+1.mp4',
        },
        // "assignment": {
        //     "title": "Please watch three Case Study videos and complete an Art interpretation form for each of the three.",
        //     "link": "https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_2_Art+Interpretation+Form.docx"
        // },
        assignment: (
          <div>
            Please watch three Case Study videos and complete an{' '}
            <a href="https://forms.gle/F2Vi2eeBD32vT4Aj7">
              Art interpretation form
            </a>{' '}
            for each of the three
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 3',
        video: {
          title: 'Case Study 2',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_3_Video_Case+Study+2.mp4',
        },
        assignment: (
          <div>
            Please watch three Case Study videos and complete an{' '}
            <a href="https://forms.gle/F2Vi2eeBD32vT4Aj7">
              Art interpretation form
            </a>{' '}
            for each of the three
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 4',
        video: {
          title: 'Case Study 3',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_4_Video_Case+Study+3.mp4',
        },
        assignment: (
          <div>
            Please watch three Case Study videos and complete an{' '}
            <a href="https://forms.gle/F2Vi2eeBD32vT4Aj7">
              Art interpretation form
            </a>{' '}
            for each of the three
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 5',
        video: {
          title: 'Case Study 4',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_5_Video_Case+Study+4.mp4',
        },
        assignment: (
          <div>
            Please watch three Case Study videos and complete an{' '}
            <a href="https://forms.gle/F2Vi2eeBD32vT4Aj7">
              Art interpretation form
            </a>{' '}
            for each of the three
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 6',
        video: {
          title: 'Case Study 5',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_6_Video_Case+Study+5.mp4',
        },
        assignment: (
          <div>
            Please watch three Case Study videos and complete an{' '}
            <a href="https://forms.gle/F2Vi2eeBD32vT4Aj7">
              Art interpretation form
            </a>{' '}
            for each of the three
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 7',
        video: {
          title: 'Case Study 6',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_7_Video_Case+Study+6.mp4',
        },
        assignment: (
          <div>
            Please watch three Case Study videos and complete an{' '}
            <a href="https://forms.gle/F2Vi2eeBD32vT4Aj7">
              Art interpretation form
            </a>{' '}
            for each of the three
          </div>
        ),
        support_material: null,
      },
      {
        step: 'Step 8',
        video: 'Practice Group Immersions',
        assignment:
          'To schedule your practice group immersions, reach out to DeJuana Golden.',
        reflection_question: null,
        support_material: {
          title: 'Participant Form',
          link: 'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/Modules/Module+5+Assets/Module_5_Group_5_Support_Inner+Immersion+One+on+One+Notes+Blank+Template.docx.pdf',
        },
      },
      {
        step: 'Step 9',
        video: 'Practice One-on-Ones',
        assignment:
          'To schedule your practice group immersions, reach out to DeJuana Golden.',
        reflection_question: null,
        support_material: null,
      },
    ],
  },
  {
    title: 'Module 6 Training',
    enableDate: '10/23/2023',
    image: Module6,
    path: `module-6`,
    content: [
      {
        step: 'Step 1',
        video: null,
        assignment: 'Study Week & Final Exam',
        reflection_question: 'Final Exam',
        support_material: null,
      },
    ],
  },
];
