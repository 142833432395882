import React, { useState, useContext, useEffect, useRef } from 'react';
import { NodeButton, Modal, theme } from 'Stories';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { store } from 'Contexts/GlobalState';

import { GlobalConstants } from 'Services';

import { embedTree } from './utility.js';

import './Tree.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  Branch,
  Title,
  GenerateTree,
  SplitNode,
  ConnectExistingNode,
} from './Helpers';
import { Streetview } from '@mui/icons-material';

export default function SurveyBuilder(props) {
  const [rawNodes, setRawNodes] = useState(props.nodes ? props.nodes : []);
  const [nodes, setNodes] = useState([]);
  const [arrows, setArrows] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  const [modalInfo, setModalInfo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState('split');

  const location = useLocation();
  const { appConfig, editInfo } = location.state;

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  useEffect(() => {
    props.setArrows(arrows);
  }, [arrows]);

  useEffect(() => {
    setRawNodes(props.nodes);
  }, [props.nodes]);

  useEffect(() => {
    let embedded = embedTree(
      rawNodes,
      GlobalConstants.surveyBuilderOptionsCanSplit
    );

    console.log('EMBEDDED: ', embedded);
    setNodes(embedded.tree);
    // console.log('SETTING ARROS FROM NODES: ', embedded.arrows);
    setArrows(embedded.arrows);
  }, [rawNodes]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const addNode = (parentId, type) => {
    let newN = {
      parent: parentId,
      parentAlt: [],
      parentCombined: [parentId],
      id: uuidv4(),
      isNew: true,
      questionType: type,
    };

    let newA = {
      start: parentId,
      end: newN.id,
    };

    setRawNodes([...rawNodes, newN]);
    if (parentId) {
      setArrows([...arrows, newA]);
    }

    dispatch({
      type: 'setEditingNode',
      editingNode: newN,
    });
  };

  const splitNode = (n, isNew) => {
    let node = { ...n };

    if (isNew) {
      for (var i = 0; i < rawNodes.length; ++i) {
        if (rawNodes[i].id === n.parentId) {
          node = { ...rawNodes[i] };
        }
      }
    }

    node.isNew = isNew;

    setModalInfo(node);
    setModalOpen(true);
    dispatch({
      type: 'setEditingNode',
      editingNode: null,
    });
  };

  const onSaveSplit = (payload) => {
    props.updateNodes(payload);
    toggleModal();
    setModalInfo(null);
  };

  // console.log('HERE: ', modalInfo);

  return (
    <div className="alignTreeDiv">
      <Title title={editInfo.title} />
      <div className="fullTreeHolder">
        {nodes && nodes.length === 0 && (
          <NodeButton type="add" onClick={(e) => addNode(null, e)} />
        )}
        {nodes && nodes.length >= 1 && (
          <GenerateTree>
            {nodes.map((item, index) => {
              return (
                <Branch item={item} addNode={addNode} splitNode={splitNode} />
              );
            })}
          </GenerateTree>
        )}
      </div>
      <Modal
        open={modalOpen}
        toggle={toggleModal}
        title={'Create Path'}
        id="SplitNodeModal"
        wide
      >
        <div>
          {modalInfo && modalInfo.isNew && (
            <div
              className="toggleView"
              onClick={() => {
                let type = null;
                if (view === 'exisitng') {
                  type = 'split';
                } else if (view === 'split') {
                  type = 'exisitng';
                }
                setView(type);
              }}
            >
              {view === 'split'
                ? 'Connect to Exisiting'
                : 'Split Conditionally'}
            </div>
          )}

          {view === 'split' && (
            <SplitNode
              rawNodes={rawNodes}
              embeddedNodes={nodes}
              nodeSplit={modalInfo}
              saveSplit={onSaveSplit}
              surveyInfo={props.surveyInfo}
            />
          )}

          {view === 'exisitng' && (
            <ConnectExistingNode
              editInfo={editInfo}
              modalInfo={modalInfo}
              onSaveSplit={onSaveSplit}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
