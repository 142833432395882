import React, { useEffect, useState, useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Auth } from 'aws-amplify';

import { useCookies } from 'react-cookie';

export default function User(props) {
  const [anchorElement, setAnchorElement] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const [cookies] = useCookies(['cookie-name']);
  const user = Auth.user;

  const goToAccount = () => {
    handleClose();
    navigate('/account');
  };

  const handleClick = (e) => {
    if (anchorElement === null) {
      setAnchorElement(e.currentTarget);
      setIsMenuOpen(true);
    }
  };

  const handleClose = () => {
    setAnchorElement(null);
    setIsMenuOpen(false);
  };

  return (
    <div
      style={{
        color: '#4372C4',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'white',
      }}
      onClick={handleClick}
    >
      <AccountCircleIcon />
      <ListItemText primary={`${cookies.given_name} ${cookies.family_name}`} />
      <FontAwesomeIcon
        icon={solid('chevron-down')}
        style={{ marginLeft: '10px' }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={goToAccount}>My account</MenuItem> */}
        <MenuItem
          onClick={() => {
            Auth.signOut();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
