
export const medicationsHeaders = [
    {
        alignment: "left",
        label: "Medication",
        key: "medication",
        size: "small"
    },
    {
        alignment: "left",
        label: "Response",
        key: "response",
        size: "medium"
    },
    {
        alignment: "left",
        label: "Details",
        key: "details",
        tooltip: {
            position: "left",
            style: {
                backgroundColor: "#fff5d4",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#140e29",
                color: "black",
                padding: 2,
                fontSize: 16
            }
        }
    },
    {
        alignment: "left",
        label: "Recommendation",
        key: "recommendation"
    }
]

export const dosageGuidanceHeaders = medicationsHeaders;


export const dnaResponseHeaders = [
    {
        alignment: "left",
        label: "Category",
        key: "category",
        type: "string",
        isSearchable: true,
        searchPlaceholderText: "Search Category"
    },
    {
        alignment: "left",
        label: "Drug Class",
        key: "drugClass",
        type: "string",
        isSearchable: true,
        searchPlaceholderText: "Search Drug Class"
    },
    {
        alignment: "left",
        label: "Drug Name",
        key: "drugName",
        type: "string",
        isSearchable: true,
        searchPlaceholderText: "Search Drug Name"
    },
    {
        alignment: "left",
        label: "Recommendation",
        key: "recommendation",
        type: "string",
        isSearchable: true,
        searchPlaceholderText: "Search Recommendation"
    },
    {
        alignment: "left",
        label: "Status",
        key: "status",
        type: "boolean",
        isSearchable: true,
        searchPlaceholderText: "View Active Only"
    }
]