import { HeadsetRounded, Search } from '@mui/icons-material';
import { Button, TextInput } from 'Stories';
import { Drawer } from '@mui/material';
export const MedicationsSearch = (props) => {
  const {
    isMedicationsSearchOpen,
    isSearching,
    setIsMedicationsSearchOpen,
    setMedicationsSearchResults,
    handleMedicationSearchTermChange,
    addedMedications,
    medicationsSearchResults,
    blinkRcxui,
    setBlinkRxcui,
    addMedication,
    removeMedication,
  } = props;

  return (
    <Drawer
      anchor="left"
      open={isMedicationsSearchOpen}
      onClose={() => setIsMedicationsSearchOpen(false)}
    >
      {/* Medications Search Drawer */}
      {/* Use this endpoint example for suggestions https://rxnav.nlm.nih.gov/REST/spellingsuggestions.json?name=naprox*/}
      <div className={'pm-check-medications-list-container'}>
        <h3 className="pm-check-medications-list-header">{`Check a medication for your patient`}</h3>
        <div>
          <TextInput
            size={'small'}
            variant="outlined"
            fullWidth={true}
            onChange={(e) => handleMedicationSearchTermChange(e)}
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Search sx={{ fontSize: 24 }} />
                <div>{'Search Medication'}</div>
              </div>
            }
          />
        </div>
        {addedMedications.length > 0 && (
          <div style={{ opacity: 0.75, marginTop: 20 }}>
            {`${addedMedications.length} medications have been added for checking additional drug interactions`}
          </div>
        )}
        {addedMedications.length > 0 &&
          addedMedications.map((medication) => {
            return (
              <div
                className={`pm-medication-search-item pm-medication-search-item-selected ${
                  medication.drugbank_pcid === blinkRcxui
                    ? 'pm-medication-search-item-flash '
                    : ''
                }`}
                key={medication.drugbank_pcid}
                onClick={() => removeMedication(medication.drugbank_pcid)}
              >
                {medication.name}
              </div>
            );
          })}
        <div style={{ opacity: 0.75, marginTop: 20 }}>
          {isSearching
            ? 'Searching'
            : `${medicationsSearchResults.length} results found`}
        </div>
        {medicationsSearchResults.map((resultItem) => {
          return (
            <div
              className="pm-medication-search-item"
              key={resultItem.drugbank_pcid}
              onClick={() => addMedication(resultItem.drugbank_pcid)}
            >
              {resultItem.name}
            </div>
          );
        })}
      </div>
      <div className="pm-check-medications-actions-container">
        <Button
          label="Close"
          onClick={() => {
            setMedicationsSearchResults([]);
            setIsMedicationsSearchOpen(false);
          }}
        ></Button>
      </div>
    </Drawer>
  );
};
