import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

// let defaultValues = { username: 'kdskds', password: '123rfdsa' };

export default function FormX(props) {
  const theme = useTheme();

  const onSubmit = (data) => {
    props.onSubmit(data);
  };

  const methods = useForm({
    defaultValues: props.defaultValues ? props.defaultValues : null,
    reValidateMode: 'onChange',
  });

  const { handleSubmit, control, register, setValue } = methods;
  let style = props.formStyle ? props.formStyle : {};

  function renderChild(child, index) {
    return (
      <div
        key={child.props.name + '_formitem_' + index}
        style={{
          paddingBottom: props.children[index + 1] ? '15px' : null,
        }}
      >
        {React.createElement(child.type, {
          ...child.props,
          control: control,
          register: register,
          setValue: setValue,
        })}
      </div>
    );
  }

  return (
    <div>
      {props.title && (
        <div
          style={{
            fontSize: theme.fontSize.header,
            fontWeight: 'bold',
            padding: theme.padding.standard,
          }}
        >
          {' '}
          {props.title}
        </div>
      )}
      <form
        style={{ padding: theme.padding.standard, ...style }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {props.children.map((child, index) => {
          if (child && typeof child === 'object' && child.length > 0) {
            return child.map((childarr, index) => {
              return renderChild(child, index);
            });
          } else if (child && child.props) {
            return renderChild(child, index);
          } else {
            return null;
          }
        })}
      </form>
    </div>
  );
}

FormX.defaultProps = {
  onClick: () => {
    console.log('no onSubmit handler');
  },
};

// {props.formData.map((item) => {
//   return (
//     <Controller
//       name={item.name ? item.name : item.id}
//       control={control}
//       render={({ field }) =>
//         React.cloneElement(
//           item.component,
//           {
//             ...field,
//             ...item.component.props,
//           },
//           null
//         )
//       }
//     />
//   );
// })}
