

export const dosageGuidance = [
    {
      medication: "Atomoxetine",
      rxcui: "38400",
      recommendation: "ACTIONABLE",
      response:
        "Possible Atomoxetine Underexposure Leading to Decreased Response (CYP2D6: Normal Metabolizer)",
      details:
        "The genotype result indicates that the patient is likely to have an insufficient response due to inadequate drug exposure following standard dosing. Consider the following dosin9 strategy\n        ⦁\tInitiate treatment at 40 mg/day, increase to 80 mg/day after 3 days and maintain dose.\n        ⦁\tIf after 2 weeks, optimal clinical response is not observed and adverse events are not present, consider a dose increase to 100 mg/day.\n        ⦁\tIf after 2 weeks, optimal clinical response is not observed and adverse events are riot present, consider therapeutic drug monitoring 1-2 hours post dose. If the plasma concentration is less than 200 ng/ml consider a dose increase to a target of 400 ng/ml. Doses greater than 100 mg/day may be needed to achieve a targeted therapeutic concentration. (Therapeutic range: 200-1000 ng/ml).\n        ",
    },
    {
      medication: "Atorvastatin",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response: "Increased Myopathy Risk (SLCO1B1: Decreased Function)",
      details:
        "The patient's genotype is associated with reduced SLCO1B1 function which results in elevated atorvastatin plasma\n        concentrations. If atorvastatin is used in this patient, consider closer monitoring of myopathy, serum creatine kinase and liver function.\n        \n        If the patient has additional myopathy risk factors, consider an alternative statin that is not influenced by SLCOJBJ. Other myopathy risk factors include advanced age (*65), uncontrolled hypothyroidism, renal impairment, high statin dose, comedications, and female sex.\n        ",
    },
    {
      medication: "Carbamazepine",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response: "Increased Carbamazepine Exposure (CYP2C9: Slow Metabolizer)",
      details:
        "The patient's genotype predicts a reduced CYP2C9 metabolic activity, Increased drug exposure may occur in this patient leading to prolonged sedation. Consider standard Iabel-recommended dosing and close monitoring for adverse effects.",
    },
    {
      medication: "Clozapine",
      rxcui: "2626",
      recommendation: "INFORMATIVE",
      response:
        "Non-Response to Clozapine (CYP1A2: Normal Metabolizer - Higher Inducibility)",
      details:
        "Smokers have a high risk for non-response at standard doses and may require higher doses. \n        There is an association between high clozapine doses and the risk of seizures, and therefore careful monitoring is recommended during dosing adjustment. \n        Smoking cessation will increase plasma drug levels, leading to adverse events. \n        Therefore, therapeutic drug monitoring accompanied by dose reduction is recommended in patients who have quit smoking.",
    },
    {
      medication: "Dexlansoprazole",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response:
        "Normal or Possible Slightly Decreased Exposure to Dexlansoprazole (CYP2C19: Normal Metabolizer)",
      details:
        "The patient's genotype is predictive of normal metabolism but may be associated with a slightly decreased dexlansoprazole \n        clinical exposure following standard dosing. Be alert for insufficient response, consider prescribing dexlansoprazole \n        at standard label-recommended dosage and administration. May consider increasing the recommended dose for certain \n        indications by 50—100% to optimize therapeutic efficacy.\n        ",
    },
    {
      medication: "Dexmethylphenidate",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response:
        "Decreased Response to Dexmethylphenidate (COMT: \n            Intermediate COMT Activity)",
      details:
        "The patient's genotype result predicts a less optimal response to dexmethylphenidate.\n        Dosage should be individualized according to the needs and response of the patient. \n        Therapy should be initiated in small doses, with gradual weekly increments.",
    },
    {
      medication: "Dronabinol",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Increased Dronabinol Exposure (CYP2C9: Intermediate \n            Metabolizer)",
      details:
        "The patient's genotype predicts a reduced CYP2C9 metabolic activity, Increased drug \n        exposure may occur in this patient leading to prolonged sedation. Consider standard \n        Iabel-recommended dosing and close monitoring for adverse effects",
    },
    {
      medication: "Fluvastatin",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response:
        "Possible Increased Fluvastatin Exposure (CYP2C9: Intermediate \n            Metabolizer)",
      details:
        "Increased fluvastatin plasma concentrations due to reduced CYP2C9 activity may \n        occur, resulting in myopathy/hepatotoxicity. Consider monitoring the patient for \n        treatment-related adverse effects, and adjust dose based on tolerability and response.\n        Other adverse events and predisposing factors include advanced age (>65), diabetes,\n        hypothyroidism, renal or hepatic impairments, high statin dose, CYP2C9 inhibitors, \n        ABCG2 inhibitors, and female gender",
    },
    {
      medication: "Lansoprazole",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Normal or Possible Slightly Decreased Exposure to Lansoprazole \n        (CYP2C19: Normal Metabolizer)",
      details:
        "The patient's genotype is predictive of normal metabolism but may be associated with \n        a slightly decreased lansoprazole\n        clinical exposure following standard dosing. Be alert (or insufficient response, consider\n        prescribing lansoprazole at standard label-recommended dosage and administration. \n        May consider increasing the recommended dose for certain indications by 50-100% to\n        optimize therapeutic efficacy.",
    },
    {
      medication: "Lovastatin",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response: "Increased Myopathy Risk (5LCO1B1: Decreased Function)",
      details:
        "The reduced SLCO1B1 function may result in elevated lovastatin acid plasma levels. \n        Because the risk of myopathy increases in patients with high statin plasma levels, the \n        use of high lovastatin doses in this patient should be avoided. If lovastatin is used in \n        this patient, a closer monitoring of serum creatine kinase and liver function is \n        recommended. Other myopathy predisposing factors include advanced age (>65), \n        uncontrolled hypothyroidism, renal impairment, comedications, and female gender.",
    },
    {
      medication: "Methylphenidate",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response:
        "Decreased Response to Methylphenidate (COMT: Intermediate \n            COMT Activity)",
      details:
        "The patient's genotype result predicts a less optimal response to methylphenidate. \n        Dosage should be individualized according to the needs and response of the patient. \n        Therapy should be initiated in small doses, with gradual weekly increments.",
    },
    {
      medication: "Olanzapine",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response:
        "Non-Response to Olanzapine (CYP1A2: Normal Metabolizer - \n            Higher Inducibility)",
      details:
        "There is little evidence regarding the impact of CYP1A2 genetic variants on \n        olanzapine response. Smokers may be at risk for non-response at standard doses. \n        Careful monitoring is recommended during dosing adjustment. Smoking cessation \n        may increase plasma drug levels, leading to adverse events. Therefore, therapeutic \n        drug monitoring accompanied by dose reduction may be needed in patients who have \n        quit smoking",
    },
    {
      medication: "Omeprazole",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Normal or Possible Slightly Decreased Exposure to Omeprazole \n        (CYP2C19: Normal Metabolizer)",
      details:
        "The patient's genotype is predictive of normal metabolism but may be associated with \n        a slightly decreased omeprazole\n        clinical exposure following standard dosing. Be alert for insufficient response, consider\n        prescribing omeprazole at standard label-recommended dosage and administration. \n        May consider increasing the recommended dose for certain indications by 50-100% to\n        optimize therapeutic efficacy.",
    },
    {
      medication: "Pantoprazole",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Normal or Possible Slightly Decreased Exposure to Pantoprazole \n        (CYP2C19: Normal Metabolizer)",
      details:
        "The patient's genotype is predictive of normal metabolism but may be associated with \n        a slightly decreased pantoprazole clinical exposure following standard dosing. Be alert\n        for insufficient response, consider prescribing pantoprazole at standard label-\n        recommended dosage and administration. May consider increasing the recommended dose for certain indications by 50-100% to optimize therapeutic efficacy.",
    },
    {
      medication: "Pitavastatin",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response: "Increased Myopathy Risk (SLCO1B1: Decreased Function)",
      details:
        "The reduced SLCO1B1 function may result in elevated pitavastatin plasma levels. \n        Because the risk of myopathy increases in patients with high statin plasma levels, the \n        use of high pitavastatin doses in this patient should be avoided. If pitavastatin is used \n        in this patient, a closer monitoring of serum creatine kinase and liver function is \n        recommended.\n        Other myopathy predisposing factors include advanced age (>65), uncontrolled \n        hypothyroidism, renal impairment, comedications, and female gender.",
    },
    {
      medication: "Pravastatin",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response: "Increased Myopathy Risk (SLCO1B1: Decreased Function)",
      details:
        "The reduced SLCO1B1 function may result in elevated pravastatin plasma levels. \n        Because the risk of myopathy increases in patients with high statin plasma levels, the \n        use of high pravastatin doses in this patient should be avoided. If pravastatin is used \n        in this patient, a closer monitoring of serum creatine kinase and liver function is \n        recommended.\n        Other myopathy predisposing factors include advanced age (>65), uncontrolled \n        hypothyroidism, renal impairment, comedications, and female gender.",
    },
    {
      medication: "Rosuvastatin",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response: "Increased Myopathy Risk (SLCO1B1 S21T>C T/C)",
      details:
        "The reduced SLCO1B1 function may result in elevated rosuvastatin plasma levels. \n        Because the risk of myopathy increases in patients with high statin plasma levels, the \n        use of high rosuvastatin doses in this patient should be avoided. If rosuvastatin is \n        used in this patient a closer monitoring of serum creatine kinase and liver function is \n        recommended. Other myopathy predisposing factors include advanced age (>65), \n        uncontrolled hypothyroidism, renal impairment, comedications, and female gender.",
    },
    {
      medication: "Simvastatin",
      response: "Intermediate Myopathy Risk (SLCO1B1: Decreased Function)",
      recommendation: "ACTIONABLE",
      rxcui: "36567",
      details:
        "Simvastatin plasma concentrations are expected to be elevated. Consider avoiding \n        simvastatin, and prescribe an alternative statin or another hypolipidemic drug, or \n        consider prescribing simvastatin at a lower starting dose (20mg/day). Routine creatine\n        kinase (CK) monitoring is also advised. The FDA recommends against the 80 mg daily\n        dose. Although the as sociation between the SLCO1BI 521T>C variant and myopathy \n        risk is not clearly established for other statins such as atorvastatin, pitavastatin, \n        rosuvastatin, and pravastatin, caution is advised if high doses of these statins are \n        used in this patient, Fluvastatin plasma levels are not affected by the SLCO1B1 \n        S21T>C variant",
    },
    {
      medication: "Tacrolimus",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Insufficient Response to Tacrolimus (CYP3A5: Intermediate \n            Metabolizer)",
      details:
        "The genotype result predicts that the patient expresses the CYP3A5 protein. \n        Therefore, the patient may metabolize tacrolimus more rapidly, resulting in low \n        tacrolimus trough levels. Studies have shown patients with this genotype may be at \n        increased risk for acute transplant rejection while taking a standard dose of \n        tacrolimus. Therefore, increasing stating dose 1.5 to 2 times recommended starting \n        dose with close monitoring is strongly recommended to achieve therapeutic effect. \n        Total starting dose should not exceed 0.3mg/kg/day.",
    },
    {
      medication: "Tetrabenzaine",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Normal Sensitivity to Tetrabenazine (CYP2D6: Normal \n            Metabolizer",
      details:
        "For treating chorea associated with Huntington's disease: individualization of \n        dose with careful weekly titration is required. The first week's starting dose is 12.5 mg \n        daily; second week, 25 mg (12.5 mg twice daily); then slowly titrate at weekly intervals\n        by 12.5 mg to a tolerated dose. The maximum daily dose in CYP2D6 normal \n        rnetabolizers is 100 mg, with a maximum single dose of 37.5 mg. If serious \n        adverse events occur, titration should be stopped and the dose of tetrabenazine \n        should be reduced. If the adverse event(s) do not resolve, consider withdrawal of \n        tetrabenazine",
    },
    {
      medication: "Tizanidine",
      rxcui: "36566",
      recommendation: "INFORMATIVE",
      response:
        "Possible Non-Response to Tizanidine (CYP1A2: Normal \n            Metabolizer - Higher Inducibility",
      details:
        "There is little evidence regarding the impact of CYP1A2 genetic variants on tizanidine \n        response. Smokers may be at risk\n        for non-response and may require higher doses. There is an association between high\n        tizanidine plasma concentrations and the risk of hypotension and excessive sedation. \n        Therefore, careful monitoring is recommended during dosing adjustment. Smoking \n        cessation may increase plasma drug levels, leading to excessive hypotension and \n        sedation. Careful monitoring accompanied by dose reduction may be needed in \n        patients who have quit smoking",
    },
    {
      medication: "Warfarin",
      rxcui: "36566",
      recommendation: "ACTIONABLE",
      response:
        "Dosing Adjustments are Expected (CYP2C9 *1/*2; VKORC1 -\n        1639G>A G/A)",
      details:
        "When initiating warfarin treatment for indications with a target INR of 2-3, consider one\n        of the following methods to estimate dosing requirements:\n        FDA Label: CYP2C9 and VKORC1 genotype results indicate an expected therapeutic\n        dose of 3-4 mg/day. \n        Pharmacogenomics algorithms/calculator available at www.warfarindosing.org.\n        Caucasians and Asians: Use the patient's demographics and other clinical factors \n        along with CYP2C9 and VKORC1 genotypes to calculate the expected therapeutic \n        dose.\n        Africans and African Americans: Use the patient's demographics and other clinical \n        factors along with CYP2C9 and VKORC1 genotypes to calculate the expected \n        therapeutic dose.\n        The provided recommendations in Africans and African Americans apply only when all\n        the following CYP2C9 alleles are tested: *5, ’6, *8, *11.",
    },
  ];