import React, { useState, useContext, useEffect, useRef } from 'react';
import '../Tree.scss';

import {
  Form,
  TextInput,
  Button,
  MultiAdd,
  Select,
  Modal,
  theme,
} from 'Stories';

import { useMutation, useLazyQuery } from '@apollo/client';
import { PUT_SURVEY_NODE } from '../../GraphQL/mutations';
import { GET_SURVEY_NODE } from '../../GraphQL/queries';

// import { getSurveyNode } from 'graphql/queries';

export default function SurveyBuilder(props) {
  const [
    putSurveyNode,
    {
      data: putSurveyNodeData,
      loading: putSurveyNodeLoading,
      error: putSurveyNodeError,
    },
  ] = useMutation(PUT_SURVEY_NODE);

  const [
    getSurveyNode,
    {
      data: getSurveyNodeData,
      loading: getSurveyNodeLoading,
      error: getSurveyNodeError,
    },
  ] = useLazyQuery(GET_SURVEY_NODE);

  const onSubmit = async (e) => {
    console.log('SUB: ', e);

    let parent = props.modalInfo.id;
    let child = e.nodeId;

    if (parent !== child) {
      // let resp = await API.graphql(
      //   graphqlOperation(getSurveyNode, { id: child })
      // );

      let resp = await getSurveyNode({
        variables: {
          id: child,
        },
      });

      console.log('RESP: ', resp);

      let node = resp.data.getSurveyNode;

      if (node) {
        let arr = node.parentAlt ? node.parentAlt : [];

        let payload = {
          id: child,
          parentAlt: [...arr, parent],
        };

        putSurveyNode({
          variables: {
            info: payload,
          },
        });

        props.onSaveSplit();
      } else {
        console.log('Node not found');
      }
    } else {
      console.log('Node cannot be connected to self');
    }
  };

  return (
    <Form
      formStyle={{
        // position: 'absolute',
        top: '85px',
        bottom: 0,
        left: 0,
        right: 0,
      }}
      onSubmit={onSubmit}
    >
      <TextInput
        label={'Node ID'}
        id="nodeId"
        variant="outlined"
        // value={editInfo.identifier}
        inputProps={{ maxlength: 25 }}
        form
        required
      />
      <Button label="submit" id="submit" type="submit" />
    </Form>
  );
}
