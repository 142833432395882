import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal, theme } from 'Stories';
import tasks from 'Assets/Tasks.jpg';
import admin from 'Assets/Admin.jpg';

import { store } from 'Contexts/GlobalState';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import PageForm from './Form.js';

import './Main.css';

export default function Header(props) {
  let colors = theme.palette.general;

  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const location = useLocation();
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  let navigate = useNavigate();

  const onClickBack = (e) => {
    dispatch({
      type: 'setCurrentAppConfig',
      appConfig: null,
    });
    // navigate(props.prevPage);
    navigate(-1);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{props.title}</div>
        <div
          onClick={() => onClickBack()}
          style={{
            color: colors.blue,
            cursor: 'pointer',
          }}
        >
          <FontAwesomeIcon
            style={{ color: colors.blue }}
            icon={solid('arrow-left')}
          />
          {'  '}
          Back to {props.prevPageName}
        </div>
      </div>
    </div>
  );
}
