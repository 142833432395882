import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import logo from 'Assets/Inner Immersion Logo.png';
import {
  TEMP_CONFIG,
  TEMP_DASHBOARD,
  LARGE_WINDOW_SIZE,
  TITLE_DISPLAY,
} from 'Services/GlobalConstants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWindowDimensions } from 'Services/CustomHooks';

import { useCookies } from 'react-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BasicListItem } from 'Stories';
import User from './User';
import Routes from './Routes';

const drawerWidth = 240;

const menuOptions = [
  {
    name: 'Home',
    icon: <FontAwesomeIcon icon={solid('house')} />,
    path: '/',
    pageAccess: 'All',
  },
  {
    name: 'Users',
    icon: <FontAwesomeIcon icon={solid('user')} />,
    path: '/users',
    pageAccess: 'users',
  },
  // {
  //   name: 'Surveys',
  //   icon: <FontAwesomeIcon icon={solid('list')} />,
  //   path: '/view-config/surveys',
  //   pageAccess: 'surveyBuilder',
  //   extras: { state: { appConfig: TEMP_CONFIG } },
  // },
  // {
  //   name: 'Insights and Reporting',
  //   icon: <FontAwesomeIcon icon={solid('line-chart')} />,
  //   path: '/view-dashboard',
  //   pageAccess: 'analytics',
  //   extras: { state: { dashboard: TEMP_DASHBOARD } },
  // },
];

// grip-lines

export default function MiniDrawer(props) {
  let navigate = useNavigate();
  let location = useLocation();
  // const { user } = useAuthenticator((context) => [context.user]);
  const [cookies] = useCookies(['cookie-name']);

  const user = Auth.user;
  console.log('USER: ', user);

  const theme = useTheme();

  let colors = theme.palette.general;

  const [open, setOpen] = useState(true);
  const [pageName, setPageName] = useState('Home');
  const [userMenu, setUserMenu] = useState([]);
  const [windowWidth, windowHeight] = useWindowDimensions();
  const handleDrawerOpen = () => {
    // setOpen(true);
  };

  const handleDrawerClose = () => {
    // setOpen(false);
  };

  const hasPageAccess = (pageName) => {
    try {
      let pageAccess =
        typeof cookies.pageAccess === 'string'
          ? JSON.parse(cookies.pageAccess)
          : cookies.pageAccess;

      let accessGranted =
        pageAccess.indexOf('Admin') > -1 || pageAccess.indexOf(pageName) > -1;
      return accessGranted;
    } catch (e) {
      console.log('FAILED: ', e);
      return false;
    }
  };

  const isSurveyBuilder = () => {
    return (
      location &&
      location.pathname &&
      location.pathname.indexOf('survey-builder') > -1
    );
  };

  useEffect(() => {
    let tmp = [];
    for (var i = 0; i < menuOptions.length; ++i) {
      if (
        menuOptions[i].pageAccess === 'All' ||
        hasPageAccess(menuOptions[i].pageAccess)
      ) {
        console.log('PUSHING MENU');
        tmp.push(menuOptions[i]);
      }
      setUserMenu(tmp);
    }
  }, []);

  useEffect(() => {}, [userMenu]);

  useEffect(() => {
    setOpen(windowWidth > LARGE_WINDOW_SIZE);
  }, [windowWidth]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          backgroundColor: colors.ghostWhite,
          color: 'white',
          zIndex: 10,
          background:
            'linear-gradient(90deg, rgba(32,4,173,1) 0%, rgba(73,15,71,1) 100%, rgba(65,255,0,0.4738270308123249) 100%)',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <FontAwesomeIcon icon={solid('bars')} size="xs" />
          </IconButton>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ paddingLeft: '40px' }}
            >
              {/* Connect CMS */}
              {/* {`Welcome, ${user.attributes.given_name}!`} */}
              {TITLE_DISPLAY(location.pathname, cookies.given_name)}
            </Typography>

            <User open={true} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <div
          style={{
            backgroundColor: colors.ghostWhite,
            color: colors.blueGray,
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '20px',
          }}
        >
          <img style={{ width: '65%' }} src={logo} />
        </div>

        <List
          style={{
            height: '100%',
            backgroundColor: colors.ghostWhite,
            color: colors.blueGray,
            textAlign: 'center',
          }}
        >
          {userMenu.map((item) => {
            return (
              <BasicListItem
                key={item.name + '_DrawerListing'}
                selected={pageName === item.name}
                item={item}
                open={open}
                color={
                  //colors.blueGray
                  'black'
                }
                handleClick={() => {
                  setPageName(item.name);
                  navigate(item.path, item.extras);
                }}
              />
            );
          })}
        </List>
        <List
          style={{
            //height: '100%',
            backgroundColor: colors.ghostWhite,
            color: colors.blueGray,
            textAlign: 'center',
          }}
        >
          {/* <BasicListItem
            item={{
              name: 'Account Settings',
              icon: <FontAwesomeIcon icon={solid('cog')} />,
              path: '/company-info',
            }}
            selected={pageName === 'Account Settings'}
            open={open}
            color={
              //colors.blueGray
              'black'
            }
            handleClick={() => {
              setPageName('Account Settings');
              navigate('/company-info');
            }}
          /> */}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          backgroundColor: theme.palette.background.default,
          //minHeight: '100vh',
        }}
      >
        <DrawerHeader />
        <Paper
          square
          elevation={0}
          style={{
            boxShadow: colors.boxShadow,
            padding: isSurveyBuilder() ? '0px' : '25px',
            backgroundColor: isSurveyBuilder()
              ? '#F4F4F8'
              : theme.palette.background.default,
          }}
        >
          <Routes />
        </Paper>
      </Box>
    </Box>
  );
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 36px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 36px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
