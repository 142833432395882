import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { Button } from 'Stories/Button';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import ReactPlayer from 'react-player/lazy';
import { useMutation } from '@apollo/client';
import { EXECUTE_ADMIN_TASK } from 'Pages/FacilitateImmersion/GraphQL/mutations.js';

import Lottie from 'lottie-react';
import initalLoadingAnimation from 'Assets/Inner-Immersion-Splash-Screen.json';

export default function VideoPlayer({ link, title, open, setOpen, type }) {
  const [signedLink, setSignedLink] = useState(null);
  const [loading, setLoading] = useState(true);

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  const didClickLink = async () => {
    let x = link + '';

    if (x.indexOf('inner-immersion-facilitator-in-training') > -1) {
      let videoLink = '';

      videoLink = link.replace(
        'https://inner-immersion-facilitator-in-training.s3.us-west-2.amazonaws.com/',
        ''
      );
      videoLink = videoLink.replace(/\+/g, ' ');
      videoLink = decodeURIComponent(videoLink);

      let resp = await executeAdminTask({
        variables: {
          operation: 'getModuleResource',
          input: JSON.stringify({ key: videoLink }),
        },
      });

      x = JSON.parse(resp.data.executeAdminTask.body).signedUrl;
    }

    setSignedLink(x);
    setLoading(false);

    if (type !== 'video') {
      setOpen(false);
      window.open(x, '_blank', 'noreferrer');
    }
  };

  useEffect(() => {
    if (link) {
      setLoading(true);
      didClickLink();
    }
  }, [link]);

  useEffect(() => {
    if (!open) {
      setSignedLink(null);
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth={'lg'}>
      <DialogTitle>
        {title}
        <IconButton aria-label="Close" size="small" style={{ float: 'right' }}>
          <Close onClick={() => setOpen(false)} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <div
            style={{
              height: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Lottie animationData={initalLoadingAnimation} loop={true} />
          </div>
        ) : (
          <ReactPlayer
            url={signedLink}
            controls={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  onContextMenu: (e) => e.preventDefault(),
                },
              },
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
