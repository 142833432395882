import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import tasks from 'Assets/Tasks.jpg';
import admin from 'Assets/Admin.jpg';

import PageForm from './Form.js';

import './Main.css';

// import { API, graphqlOperation } from 'aws-amplify';
// import { searchProducts } from 'graphql/queries';
// import { deleteProduct } from 'graphql/mutations';

const labels = [
  {
    alignment: 'left',
    label: 'Product Name',
    key: 'name',
  },
  {
    alignment: 'left',
    label: 'Description',
    key: 'description',
  },
  {
    alignment: 'left',
    label: 'Added By',
    key: 'addedByDisplayName',
  },
  {
    alignment: 'right',
    label: 'Date Added',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: ' ',
    key: 'actions',
    size: 'small',
  },
];

export default function Products(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getItems = async () => {
    // const item = await API.graphql(graphqlOperation(searchProducts, {}));
    // let items = item.data.searchProducts.items;
    // for (var i = 0; i < items.length; ++i) {
    //   let name = items[i].addedBy;
    //   if (items[i].addedByUserInfo) {
    //     name =
    //       items[i].addedByUserInfo.given_name +
    //       ' ' +
    //       items[i].addedByUserInfo.family_name;
    //   }
    //   items[i] = {
    //     ...items[i],
    //     addedByDisplayName: name,
    //     actions: {
    //       edit: true,
    //       delete: true,
    //     },
    //   };
    // }
    // setData(items);
  };

  useEffect(() => {
    if (!modalOpen) {
      getItems();
    }
  }, [modalOpen]);

  const onClickDelete = async (e) => {
    // const item = await API.graphql(
    //   graphqlOperation(deleteProduct, { input: { id: e.id } })
    // );
    // setModalOpen(false);
    // setTimeout(() => {
    //   getItems();
    // }, 1000);
  };

  const onClickEdit = (e) => {
    setEditInfo(e);
    setModalOpen(true);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>Products</div>
        <Button label="Add new product" onClick={(e) => toggleModal(e)} />
      </div>
      <Table
        labels={labels}
        data={data}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />
      <Modal open={modalOpen} toggle={toggleModal} title={'Add Product'}>
        <PageForm
          editInfo={editInfo}
          setModalOpen={setModalOpen}
          setEditInfo={setEditInfo}
        />
      </Modal>
    </div>
  );
}
