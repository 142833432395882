import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Tooltip } from '@mui/material';
import { Button } from 'Stories';

const severityIcon = {
  major: solid('exclamation-circle'),
  moderate: solid('exclamation-triangle'),
  minor: solid('minus-circle'),
};

const color = {
  major: '#b9203b',
  moderate: 'rgb(218, 129, 13)',
  minor: '#green',
};
const tooltip = {
  style: {
    backgroundColor: '#fff5d4',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#140e29',
    color: 'black',
    padding: 2,
    fontSize: 16,
    borderStyle: 'solid',
  },
};

export const DrugInteractionsList = (props) => {
  const {
    setIsDrugInteractionsOpen,
    isDrugInteractionsOpen,
    drugInteractionsResult,
  } = props;
  return (
    <Drawer
      anchor={'left'}
      open={isDrugInteractionsOpen}
      onClose={() => setIsDrugInteractionsOpen(false)}
    >
      {/* Drug Interactions Drawer */}
      <div className={`pm-drug-interactions-list-container`}>
        {
          <h3 className="pm-drug-interactions-list-header">{`Drug interactions detected (${drugInteractionsResult.length})`}</h3>
        }
        {drugInteractionsResult.length > 0 &&
          drugInteractionsResult.map((interaction) => {
            const tooltipContent = (
              <div>
                <h3 className={`severity-text-${interaction.severity}`}>
                  {interaction.severity.toUpperCase()}
                </h3>
                <div>{interaction.extended_description}</div>
                {interaction.management && <h4>Management</h4>}
                <div>{interaction.management}</div>
              </div>
            );
            return (
              <Tooltip
                title={tooltipContent}
                arrow
                placement={'right'}
                componentsProps={
                  tooltip.style
                    ? {
                        tooltip: {
                          sx: {
                            ...tooltip.style,
                            borderColor: color[interaction.severity],
                          },
                        },
                      }
                    : null
                }
              >
                <div
                  className={`pm-drug-interaction-container severity-container-${interaction.severity}`}
                >
                  <div
                    className={`pm-drug-interaction-item-header severity-text-${interaction.severity}`}
                  >
                    <FontAwesomeIcon
                      icon={severityIcon[interaction.severity]}
                      style={{ marginRight: 8, fontSize: 15 }}
                    />
                    {`${interaction.affected_product_concept_name}, ${interaction.product_concept_name}`}
                  </div>
                  <div style={{ fontSize: 12 }}>{interaction.description}</div>
                </div>
              </Tooltip>
            );
          })}
      </div>
      <div className="pm-drug-interactions-actions-container">
        <Button
          label="Close"
          onClick={() => setIsDrugInteractionsOpen(false)}
        ></Button>
      </div>
    </Drawer>
  );
};
