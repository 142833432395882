import { useEffect, useState } from 'react';
import { Button } from 'Stories';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Tabs, Tab } from '@mui/material';

import {
  patientInfo,
  medicationsHeaders,
  dnaResponseHeaders,
  dosageGuidanceHeaders,
  dosageGuidance,
  dnaResponses,
  medications,
} from './Mocks/index.js';

// import { API, graphqlOperation } from 'aws-amplify';

import { EXECUTE_ADMIN_TASK } from '../GraphQL/mutations.js';
import { useLazyQuery, useMutation } from '@apollo/client';

import './PrecisionMedicineStyle.css';

import { TabPanel } from 'Stories';
import { PatientInformation } from './PatientInformation';
import { DrugInteractionsBar } from './DrugInteractionsBar';
import { DrugInteractionsList } from './DrugInteractionsList.js';
import { MedicationsSearch } from './MedicationsSearch.js';
import { MedicationsTable } from './MedicationsTable.js';
import { DNAResponseTable } from './DNAResponseTable.js';

const BLINK_DURATION = 150;

export default function PrecisionMedicine(props) {
  const [displayedList, setDisplayedList] = useState(0);
  const [isFilteredByActive, setIsFilteredByActive] = useState(false);
  const [filteredDNAResponses, setFilteredDNAResponses] =
    useState(dnaResponses);
  const [isSearching, setIsSearching] = useState(false);
  const [drugClassFilter, setDrugClassFilter] = useState('');
  const [drugNameFilter, setDrugNameFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [recommendationFilter, setRecommendationFilter] = useState('');
  const [isDrugInteractionsOpen, setIsDrugInteractionsOpen] = useState(false);
  const [isMedicationsSearchOpen, setIsMedicationsSearchOpen] = useState(false);
  const [drugBankPcidSearchString, setDrugBankPcidSearchString] = useState('');
  const [drugInteractionsResult, setDrugInteractionsResult] = useState([]);
  const [isDrugInteractionsDetected, setIsDrugInteractionsDetected] =
    useState(false);
  const [isDILoading, setIsDILoading] = useState(false);
  const [isDIError, setIsDIError] = useState(false);
  const [medSearch, setMedSearch] = useState('');
  const [medicationsSearchResults, setMedicationsSearchResults] = useState([]);
  const [addedMedications, setAddedMedications] = useState([]);
  const [blinkRcxui, setBlinkRcxui] = useState(null);

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  const addMedication = (drugbank_pcid) => {
    let newMedication = medicationsSearchResults.filter((searchResult) => {
      return searchResult.drugbank_pcid === drugbank_pcid;
    });
    let added = addedMedications;

    added =
      added.filter((item) => {
        if (item.drugbank_pcid === drugbank_pcid) {
          setBlinkRcxui(drugbank_pcid);
          setTimeout(() => {
            setBlinkRcxui(null);
          }, BLINK_DURATION);
        }
        return item.drugbank_pcid === drugbank_pcid;
      }).length > 0
        ? addedMedications
        : added.concat(newMedication); // Duplicate Check

    setAddedMedications(added);
  };

  const removeMedication = (drugbank_pcid) => {
    let added = addedMedications.filter((med) => {
      return med.drugbank_pcid !== drugbank_pcid;
    });
    setAddedMedications(added);
  };

  const customSortBySeverity = (array) => {
    const severityOrder = { major: 0, moderate: 1, minor: 2 };

    array.sort((a, b) => {
      const severityA = a.severity.toLowerCase();
      const severityB = b.severity.toLowerCase();
      const orderA = severityOrder[severityA];
      const orderB = severityOrder[severityB];

      if (orderA < orderB) {
        return -1;
      } else if (orderA > orderB) {
        return 1;
      } else {
        return 0;
      }
    });

    return array;
  };

  const fetchDrugInteractions = async () => {
    setIsDILoading(true);
    setIsDIError(false);

    try {
      let items = await executeAdminTask({
        variables: {
          operation: 'fetchDrugInteractions',
          input: JSON.stringify({ drugBankPcidSearchString }),
        },
      });

      console.log('fetchDrugInteractions RESP: ', items);
      let data = JSON.parse(items.data.executeAdminTask.body).data;
      const itemsx = data.interactions;
      setDrugInteractionsResult(customSortBySeverity(itemsx));
      setIsDILoading(false);
    } catch (e) {
      console.log('ERROR:ED', e);
      setIsDILoading(false);
      setIsDIError(true);
    }
  };

  const searchMedications = async () => {
    setIsSearching(true);

    try {
      let items = await executeAdminTask({
        variables: {
          operation: 'searchMedications',
          input: JSON.stringify({ medSearch }),
        },
      });

      console.log('RESP: ', JSON.parse(items.data.executeAdminTask.body));

      let data = JSON.parse(items.data.executeAdminTask.body).data;

      console.log('DATA: ', data);

      setIsSearching(false);
      setMedicationsSearchResults(data);
    } catch (e) {
      console.log('Error loading drug search results', e);
      setIsSearching(false);
    }
  };

  const filterLookup = {
    drugClass: {
      filterName: drugClassFilter,
      setter: setDrugClassFilter,
    },
    drungName: {
      filterName: drugNameFilter,
      setter: setDrugNameFilter,
    },
    category: {
      filterName: categoryFilter,
      setter: setCategoryFilter,
    },
    recommendation: {
      filterName: recommendationFilter,
      setter: setRecommendationFilter,
    },
    status: {
      filterName: isFilteredByActive,
      setter: setIsFilteredByActive,
    },
  };

  const handleChangeList = (e, value) => {
    setDisplayedList(value);
  };

  const handleFilterChange = (e, key) => {
    filterLookup[key].setter(e);
  };

  const handleMedicationSearchTermChange = (e) => {
    setMedSearch(e);
  };

  const updateDrugInteractionsSearchString = () => {
    let medicationsList = [];
    medicationsList = medicationsList.concat(medications);
    medicationsList = medicationsList.concat(addedMedications);
    setDrugBankPcidSearchString(
      medicationsList.map((medication) => medication.drugbank_pcid).join(',')
    );
  };

  useEffect(() => {
    if (medSearch.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        searchMedications();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setMedicationsSearchResults([]);
    }
  }, [medSearch]);

  useEffect(() => {
    setFilteredDNAResponses(() => {
      return dnaResponses.filter((response) => {
        return (
          response.drugClass
            .toLowerCase()
            .includes(drugClassFilter.toLowerCase()) &&
          response.drugName
            .toLowerCase()
            .includes(drugNameFilter.toLowerCase()) &&
          response.category
            .toLowerCase()
            .includes(categoryFilter.toLowerCase()) &&
          response.recommendation
            .toLowerCase()
            .includes(recommendationFilter.toLowerCase())
        );
      });
    });
  }, [drugClassFilter, drugNameFilter, categoryFilter, recommendationFilter]);

  useEffect(() => {
    console.log('Filtered by active set to ', isFilteredByActive);
    let responses = [];
    if (isFilteredByActive) {
      // NOTE: This if-else logic could be improved
      responses = dnaResponses.filter((response) => {
        return (
          response.drugClass
            .toLowerCase()
            .includes(drugClassFilter.toLowerCase()) &&
          response.drugName
            .toLowerCase()
            .includes(drugNameFilter.toLowerCase()) &&
          response.category
            .toLowerCase()
            .includes(categoryFilter.toLowerCase()) &&
          response.recommendation
            .toLowerCase()
            .includes(recommendationFilter.toLowerCase()) &&
          response.status === 'Active'
        );
      });
    } else {
      responses = dnaResponses.filter((response) => {
        return (
          response.drugClass
            .toLowerCase()
            .includes(drugClassFilter.toLowerCase()) &&
          response.drugName
            .toLowerCase()
            .includes(drugNameFilter.toLowerCase()) &&
          response.category
            .toLowerCase()
            .includes(categoryFilter.toLowerCase()) &&
          response.recommendation
            .toLowerCase()
            .includes(recommendationFilter.toLowerCase())
        );
      });
    }
    setFilteredDNAResponses(responses);
  }, [isFilteredByActive]);

  useEffect(() => {
    setIsDrugInteractionsDetected(drugInteractionsResult.length > 0);
  }, [drugInteractionsResult]);

  useEffect(() => {
    updateDrugInteractionsSearchString();
  }, [addedMedications]);

  useEffect(() => {
    if (drugBankPcidSearchString.length > 0) fetchDrugInteractions();
  }, [drugBankPcidSearchString]);

  useEffect(() => {
    updateDrugInteractionsSearchString();
  }, []);

  return (
    <div>
      <div className={'pm-top-section'}>
        {/* Patient Information */}
        <PatientInformation {...patientInfo} />
        {/* Drug Interactions */}
        <DrugInteractionsBar
          isDrugInteractionsDetected={isDrugInteractionsDetected}
          isDIError={isDIError}
          isDILoading={isDILoading}
          setIsDrugInteractionsOpen={setIsDrugInteractionsOpen}
        />
      </div>
      <div className={'pm-bottom-section'}>
        {/* Medications section */}
        <h3>Medications</h3>
        <Tabs value={displayedList} onChange={handleChangeList}>
          <Tab label="Active Medications" />
          <Tab label="DNA Responses" />
          <Tab label="Dosage Guidance" />
        </Tabs>
        <div style={{ marginTop: 10 }}>
          <TabPanel value={displayedList} index={1}>
            <DNAResponseTable
              dnaResponseHeaders={dnaResponseHeaders}
              filteredDNAResponses={filteredDNAResponses}
              setIsFilteredByActive={setIsFilteredByActive}
              handleFilterChange={handleFilterChange}
              isFilteredByActive={isFilteredByActive}
            />
          </TabPanel>
          <TabPanel value={displayedList} index={0}>
            <MedicationsTable
              medicationsHeaders={medicationsHeaders}
              medications={medications}
            />
            <div className="pm-check-medication-section">
              <Button
                label={
                  <div>
                    <FontAwesomeIcon icon={solid('plus')} />
                    {'Check a Medication'}
                  </div>
                }
                onClick={() => setIsMedicationsSearchOpen(true)}
              ></Button>
            </div>
          </TabPanel>
          <TabPanel value={displayedList} index={2}>
            <MedicationsTable
              medicationsHeaders={dosageGuidanceHeaders}
              medications={dosageGuidance}
            />
          </TabPanel>
        </div>
      </div>
      <DrugInteractionsList
        isDrugInteractionsOpen={isDrugInteractionsOpen}
        setIsDrugInteractionsOpen={setIsDrugInteractionsOpen}
        drugInteractionsResult={drugInteractionsResult}
      />
      <MedicationsSearch
        isMedicationsSearchOpen={isMedicationsSearchOpen}
        isSearching={isSearching}
        setIsMedicationsSearchOpen={setIsMedicationsSearchOpen}
        setMedicationsSearchResults={setMedicationsSearchResults}
        handleMedicationSearchTermChange={handleMedicationSearchTermChange}
        addedMedications={addedMedications}
        medicationsSearchResults={medicationsSearchResults}
        blinkRcxui={blinkRcxui}
        setBlinkRcxui={setBlinkRcxui}
        addMedication={addMedication}
        removeMedication={removeMedication}
      />
    </div>
  );
}
