export default function (props) {
  const { url, isLoading, height } = props;
  return isLoading ? (
    'Loading'
  ) : url ? (
    <iframe src={url} style={{ width: '100%' }} height={height} />
  ) : (
    'Error loading url'
  );
}
