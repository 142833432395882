import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';

export default function TextInput(props) {
  const [value, setValue] = useState(props.value ? props.value : '');

  const handleOnChange = (e) => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  useEffect(() => {
    setValue(props.value ? props.value : '');
  }, [props.value]);

  const registration =
    props.register && props.form
      ? props.register(props.name ? props.name : props.id)
      : null;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && props.onEnterListener) {
      e.preventDefault();
      props.onEnterListener();
    }
  };

  return (
    <div style={{ ...props.style }}>
      <TextField
        id={props.id}
        label={props.label}
        name={props.name ? props.name : props.id}
        type={props.type}
        multiline={props.multiline}
        disabled={props.disabled}
        rows={props.rows ? props.rows : 1}
        variant={props.variant}
        value={value}
        size={props.size ? props.size : null}
        required={props.required}
        style={{ width: '100%', backgroundColor: 'white' }}
        {...registration}
        onChange={handleOnChange}
        onKeyPress={handleKeyPress}
        InputProps={{ ...props.InputProps }}
        error={props.error}
        helperText={props.helperText}
      />
      {props.inputProps && props.inputProps.maxlength && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            fontSize: '12px',
          }}
        >
          {value.length}/{props.inputProps.maxlength}
        </div>
      )}
    </div>
  );
}

TextInput.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

TextInput.defaultProps = {
  id: 'NO-ID-PROVIDED',
  variant: 'filled',
  onClick: undefined,
  type: null,
};
