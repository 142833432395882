import React, { useState, useContext } from 'react';
import { TextInput, Form, Button, Table, Modal, Select } from 'Stories';

import { Auth } from 'aws-amplify';

import { EXECUTE_ADMIN_TASK } from './GraphQL/mutations.js';
import { useMutation } from '@apollo/client';

import { PAGE_LEVEL_ACCESS } from 'Services/GlobalConstants';

export default function PageForm(props) {
  console.log('PROPS: ', props);

  const { Username } = props.editInfo;
  const user = Auth.user;

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async (e) => {
    executeAdminTask({
      variables: {
        operation: props.editInfo.type === 'new' ? 'addGroup' : 'updateGroup',
        input: JSON.stringify({
          groupName: props.editInfo.type === 'new' ? e.groupName : Username,
          description: e.description,
          pageAccess:
            typeof e.pageAccess === 'string'
              ? e.pageAccess.split(',')
              : e.pageAccess,
        }),
      },
    });
    props.setEditInfo({});
    props.setModalOpen(false);
  };

  return (
    <Form style={{ display: 'block' }} onSubmit={onSubmit}>
      {props.editInfo.type === 'new' && (
        <TextInput label="Group Name" form id="groupName" required />
      )}
      <TextInput
        label="Description"
        value={props.editInfo.description}
        form
        id="description"
        required
      />
      <Select
        label="Page Access"
        id="pageAccess"
        form
        variant="outlined"
        isMulti
        value={
          props.editInfo && props.editInfo.pageAccess
            ? props.editInfo.pageAccess
            : null
        }
        options={PAGE_LEVEL_ACCESS}
        required
      />
      <Button label="submit" id="submit" type="submit" />
    </Form>
  );
}
