import { gql } from '@apollo/client';

export const PUT_APP_CONFIG = gql`
  mutation ($id: String, $info: AppConfigItem) {
    putAppConfig(id: $id, info: $info) {
      id
      name
      description
      connectCode
      addedBy
    }
  }
`;

export const DELETE_APP_CONFIG = gql`
  mutation ($id: String) {
    deleteAppConfig(id: $id) {
      id
      name
      description
      connectCode
      addedBy
    }
  }
`;
