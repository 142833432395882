import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

let surveyBuilderIcons = {
  instructions: solid('circle-info'),
  openEnded: solid('align-left'),
  multipleChoice: solid('circle-dot'),
  selectMany: solid('square-check'),
  linearScale: solid('timeline'),
  datetime: solid('calendar-days'),
};

const colors = {
  blue: '#4372c4', // SHA Blue
  cyan: '#3dc0eb', // SHA Cyan
  green: '#3a8a69', // SHA Green
  ghostWhite: '#f6f7ff',
  lightGray: '#f9f9f9',
  darkGray: '#e4e4e4',
  blueGray: '#706999',
  darkPurple: '#6E699D',
};

let theme = createTheme({
  fontSize: {
    paragraph: '12px',
    tiny: '10px',
    default: '14px',
    header: '20px',
  },
  padding: {
    standard: '20px',
  },
  node: {
    marginBottom: '40px',
    boxShadow: '0px 0px 10px #cdcdcd',
    color: '#6E699D',
    backgroundColor: 'white',
    fontSize: '20px',
    padding: '10px 30px',
    width: 'fit-content',
    borderRadius: '7px',
  },
  revertNodeColors: {
    boxShadow: '0px 0px 10px #a7a7a7',
    backgroundColor: '#6E699D',
    color: 'white',
  },
  icons: {
    surveyBuilderIcons: surveyBuilderIcons,
  },
  palette: {
    general: {
      boxShadow: '0px 0px 5px #d3d3d3',
      ...colors,
      background: {
        main: '#f4f4f4',
      },
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
