import { gql } from '@apollo/client';

export const PUT_SURVEY_NODE = gql`
  mutation ($info: SurveyNode) {
    putSurveyNode(info: $info) {
      id
      surveyId
      identifier
      question
      questionNumberValue
      questionType
      questionSubType
      parent
      isMatch
      isPath
      title
      answers {
        id
        value
        variable
        scoreValue
      }
      characterLimit
      upperLimit
      lowerLimit
      minDate
      maxDate
      addedBy
      condition
    }
  }
`;

export const DELETE_SURVEY_NODE = gql`
  mutation ($id: String) {
    putSurveyNode(id: $id) {
      id
      surveyId
      identifier
      question
      questionNumberValue
      questionType
      questionSubType
      parent
      isMatch
      isPath
      title
      answers {
        id
        value
        variable
        scoreValue
      }
      characterLimit
      upperLimit
      lowerLimit
      minDate
      maxDate
      addedBy
      condition
    }
  }
`;

export const PUT_SURVEY_VERSION = gql`
  mutation ($info: SurveyNode) {
    putSurveyNode(info: $info) {
      id
      surveyId
      identifier
      question
      questionNumberValue
      questionType
      questionSubType
      parent
      isMatch
      isPath
      title
      answers {
        id
        value
        variable
        scoreValue
      }
      characterLimit
      upperLimit
      lowerLimit
      minDate
      maxDate
      addedBy
      condition
    }
  }
`;
