import React, { useState, useEffect } from 'react';
import { theme } from 'Stories';
import '../../Tree.scss';
import './SplitNode.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import QuestionSelect from './QuestionSelect.js';

import { v4 as uuidv4 } from 'uuid';

export default function OptionGroup(props) {
  const { availableQuestions, keyedAnswers, id, parent, conditions } = props;

  const [selectorType, setSelectorType] = useState(
    conditions && conditions.selector ? conditions.selector : 'OR'
  );
  const [embedSelection, setEmbedSelection] = useState(false);
  const [questions, setQuestions] = useState(
    conditions && conditions.questions ? conditions.questions : []
  );

  const [childId, setChildId] = useState();

  console.log('CONDTIONS: ', conditions);

  const toggleType = () => {
    if (selectorType === 'OR') {
      setSelectorType('AND');
    } else {
      setSelectorType('OR');
    }
  };

  const addQuestion = (e) => {
    setQuestions([...questions, { question: null, answer: null }]);
  };

  const removeQuestion = (e) => {
    let dupe = [...questions];
    dupe.splice(e, 1);
    setQuestions(dupe);
  };

  const saveQuestion = (answer, question, ind) => {
    let dupe = [...questions];
    dupe[ind] = { question: question, answer: answer };
    setQuestions(dupe);
    props.acceptAnswers({
      selector: selectorType,
      questions: dupe,
      parent: parent,
      id: id,
    });
  };

  const removeEmbeddedGroup = () => {
    setEmbedSelection(false);
  };

  useEffect(() => {
    console.log('HItting use effect');
    addQuestion();
    setChildId(uuidv4());
  }, []);

  return (
    <div>
      <div className="selectorGroup">
        <div
          className="selectorName"
          style={{ color: theme.palette.general.darkPurple }}
          onClick={() => {
            toggleType();
          }}
        >
          {selectorType}
        </div>
        <div
          className="selectorAdd"
          style={{ backgroundColor: theme.palette.general.darkPurple }}
          onClick={() => {
            setEmbedSelection(true);
          }}
        >
          <FontAwesomeIcon
            className="alterChartBtn"
            style={{
              height: '13px',
              width: '13px',
            }}
            icon={solid('plus')}
          />
        </div>
        {props.isEmbedded && (
          <div
            className="selectorAdd"
            style={{ backgroundColor: theme.palette.general.darkPurple }}
            onClick={() => {
              props.removeEmbeddedGroup(false);
            }}
          >
            <FontAwesomeIcon
              className="alterChartBtn"
              style={{
                height: '13px',
                width: '13px',
              }}
              icon={solid('minus')}
            />
          </div>
        )}
      </div>

      <div
        className="questionHolder"
        style={{ borderColor: theme.palette.general.darkPurple }}
      >
        {questions.map((q, ind) => {
          return (
            <QuestionSelect
              availableQuestions={availableQuestions}
              keyedAnswers={keyedAnswers}
              addQuestion={addQuestion}
              questionData={q}
              removeQuestion={removeQuestion}
              saveQuestion={saveQuestion}
              ind={ind}
            />
          );
        })}

        {embedSelection && (
          <OptionGroup
            availableQuestions={availableQuestions}
            keyedAnswers={keyedAnswers}
            acceptAnswers={props.acceptAnswers}
            isEmbedded={true}
            id={childId}
            parent={id}
            removeEmbeddedGroup={removeEmbeddedGroup}
          />
        )}
      </div>
    </div>
  );
}
