function hasUpperCase(password) {
  return /.*[A-Z]/.test(password);
}

function hasLowerCase(password) {
  return /.*[a-z]/.test(password);
}

function hasNumeric(password) {
  return /.*[0-9]/.test(password);
}

function hasSpecialCharacter(password) {
  return /[^A-Za-z0-9]/.test(password);
}

export function checkPassword(a, b) {
  let errMsgs = [];

  if (a !== b) {
    errMsgs.push('Passwords do not match');
  } else if (!hasUpperCase(a)) {
    errMsgs.push('Password must contain at least one upper case letter');
  } else if (!hasLowerCase(a)) {
    errMsgs.push('Password must contain at least one lower case letter');
  } else if (!hasNumeric(a)) {
    errMsgs.push('Password must contain at least one number');
  } else if (!hasSpecialCharacter(a)) {
    errMsgs.push('Password must contain at least one special character');
  }

  return errMsgs;
}
