import React, { useState, useContext, useEffect } from 'react';
import {
  Form,
  TextInput,
  Button,
  MultiAdd,
  Select,
  Modal,
  theme,
} from 'Stories';
import { store } from 'Contexts/GlobalState';

import './Style.scss';

import { GlobalConstants } from 'Services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useMutation } from '@apollo/client';
import { PUT_SURVEY_NODE, DELETE_SURVEY_NODE } from '../GraphQL/mutations';

import { v4 as uuidv4 } from 'uuid';

import { Auth } from 'aws-amplify';

export default function NodeEdit(props) {
  const [editInfo, setEditInfo] = useState(null);
  const [show, setShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState({});

  const [lowerBound, setLowerBound] = useState(
    props.lowerLimit ? props.lowerLimit + 1 : 1
  );
  const [upperBound, setUpperBound] = useState(
    props.upperLimit ? props.upperLimit - 1 : 1
  );

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  const user = Auth.user;

  const [
    putSurveyNode,
    {
      data: putSurveyNodeData,
      loading: putSurveyNodeLoading,
      error: putSurveyNodeError,
    },
  ] = useMutation(PUT_SURVEY_NODE);

  // console.log(
  //   'HITTING: ',
  //   putSurveyNodeData,
  //   putSurveyNodeLoading,
  //   putSurveyNodeError
  // );

  const [
    deleteSurveyNode,
    {
      data: deleteSurveyNodeData,
      loading: deleteSurveyNodeLoading,
      error: deleteSurveyNodeError,
    },
  ] = useMutation(DELETE_SURVEY_NODE);

  useEffect(() => {
    if (state.editingNode) {
      setShow(true);
      let editInfoX = { ...state.editingNode };

      setEditInfo(editInfoX);
    } else {
      setShow(false);
      setTimeout(() => {
        setEditInfo(null);
      }, 500);
    }
  }, [state.editingNode]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getName = () => {
    return GlobalConstants.surveyBuilderOptionsMap[editInfo.questionType];
  };

  const onSubmit = async (d) => {
    let payload = {
      id: editInfo.id,
      identifier: d.identifier,
      surveyId: props?.surveyInfo?.id,
      question: d.question,
      questionSubType: d.questionSubType,
      questionType: editInfo.questionType,
      parent: editInfo.parent,
      characterLimit: d.characterLimit,
      upperLimit: d.upperLimit ? parseInt(d.upperLimit) : null,
      lowerLimit: d.lowerLimit ? parseInt(d.lowerLimit) : null,
      answers: d.answers ? JSON.parse(d.answers) : null,
    };

    let item = null;

    if (editInfo.isNew) {
      payload.id = uuidv4();
      payload.addedBy = user.attributes.sub;
    } else {
      payload.id = editInfo.id;
    }

    putSurveyNode({
      variables: {
        info: payload,
      },
    });

    props.updateNode(payload);
    closeEditPanel();
  };

  const closeEditPanel = () => {
    dispatch({
      type: 'setEditingNode',
      editingNode: null,
    });
  };

  const needsDefinedAnswers = () => {
    let types = ['multipleChoice', 'selectMany'];
    return types.indexOf(editInfo.questionType) > -1;
  };

  const clickedDeleteNode = () => {
    let msg = '';

    console.log('HIT: ', editInfo.children);

    let nonDefaultCount = 0;

    for (var i = 0; i < editInfo.children.length; ++i) {
      if (
        editInfo.children[i].type !== 'split' &&
        editInfo.children[i].type !== 'add'
      ) {
        ++nonDefaultCount;
      }
    }

    if (nonDefaultCount > 1) {
      msg = 'Functionality to delete a split node not ready';
    } else {
      msg =
        "Are you sure you want to delete '" +
        editInfo.question +
        "'? This action cannot be undone. All children nodes will shift up";
    }

    setConfirmationMessage({ msg: msg, nonDefaultCount: nonDefaultCount });
    toggleModal();
  };

  const confirmDelete = async () => {
    let grandparent = editInfo.parent;
    let children = editInfo.children;

    for (var i = 0; i < children.length; ++i) {
      if (children[i].type !== 'add' && children[i].type !== 'split') {
        // await API.graphql(
        //   graphqlOperation(updateSurveyNode, {
        //     input: { id: children[i].id, parent: grandparent },
        //   })
        // );
      }
    }

    deleteSurveyNode({
      variables: {
        id: editInfo.id,
      },
    });

    toggleModal();
    closeEditPanel();
    props.triggerReload();
  };

  const getOptions = () => {
    let options = null;
    if (editInfo.questionType === 'multipleChoice') {
      options = [
        {
          label: 'Radio Button',
          value: 'radioButton',
        },
        {
          label: 'Dropdown',
          value: 'dropdown',
        },
      ];
    } else if (editInfo.questionType === 'datetime') {
      options = [
        {
          label: 'Date',
          value: 'date',
        },
        {
          label: 'Time',
          value: 'time',
        },
        {
          label: 'Date & Time',
          value: 'datetime',
        },
      ];
    }
    return options;
  };

  return (
    <div className="nodeEditBox" style={{ right: show ? '0px' : null }}>
      {editInfo && (
        <div>
          <div
            className="nodeEditTitle"
            style={{
              backgroundColor: theme.palette.general.darkPurple,
              color: 'white',
              padding: '25px',
              display: 'flex',
            }}
          >
            <FontAwesomeIcon
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                height: '18px',
                cursor: 'pointer',
              }}
              icon={solid('xmark')}
              onClick={() => closeEditPanel()}
            />
            <FontAwesomeIcon
              style={{
                marginRight: '7px',
              }}
              icon={theme.icons.surveyBuilderIcons[editInfo.questionType]}
            />
            <div>{getName()}</div>
          </div>

          <div
            style={{
              fontSize: '15px',
              padding: '10px 20px',
              display: 'flex',
              justifyContent: 'space-between',
              background: '#d0c8de',
            }}
            id="CopyDiv"
          >
            <div id="RAW_NODE_ID">{editInfo.id}</div>
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                navigator.clipboard.writeText(editInfo.id);

                let x = document.getElementById('CopyDiv');
                x.classList.add('copyClicked');
                // console.log('X', x);

                setTimeout(() => {
                  x.classList.remove('copyClicked');
                }, 1000);
              }}
            >
              <FontAwesomeIcon
                style={{
                  marginRight: '7px',
                }}
                icon={solid('copy')}
              />
            </div>
          </div>

          <div>
            <Form
              formStyle={{
                position: 'absolute',
                top: '150px',
                bottom: 0,
                left: 0,
                right: 0,
              }}
              onSubmit={onSubmit}
            >
              {getOptions() && (
                <Select
                  label={'Type'}
                  value={editInfo.questionSubType}
                  id="questionSubType"
                  name="questionSubType"
                  options={getOptions()}
                  required
                />
              )}

              <TextInput
                label={'Identifier'}
                id="identifier"
                variant="outlined"
                value={editInfo.identifier}
                inputProps={{ maxlength: 25 }}
                form
                required
              />

              <TextInput
                label={
                  editInfo.questionType !== 'instructions'
                    ? 'Question'
                    : 'Instructions'
                }
                id="question"
                variant="outlined"
                value={editInfo.question}
                inputProps={{ maxlength: 1000 }}
                form
                required
                multiline
                rows={4}
              />

              {
                // <ImageUpload setImgUploadData={setImgUploadData} />
              }

              {needsDefinedAnswers() && (
                <MultiAdd
                  label="Answers"
                  id="answers"
                  name="answers"
                  stateValue={editInfo?.answers}
                />
              )}

              {editInfo.questionType === 'openEnded' && (
                <TextInput
                  label={'Character Limit'}
                  id="characterLimit"
                  variant="outlined"
                  value={editInfo.characterLimit}
                  type="number"
                  inputProps={{
                    max: 500,
                    min: 10,
                  }}
                  form
                  required
                />
              )}

              {editInfo.questionType === 'linearScale' && (
                <TextInput
                  label={'Lower Limit'}
                  id="lowerLimit"
                  name="lowerLimit"
                  variant="outlined"
                  value={editInfo.lowerLimit}
                  type="number"
                  onChange={(e) => setLowerBound(parseInt(e) + 1)}
                  inputProps={{
                    max: upperBound,
                    min: 0,
                  }}
                  form
                  required
                />
              )}

              {editInfo.questionType === 'linearScale' && (
                <TextInput
                  label={'Upper Limit'}
                  id="upperLimit"
                  name="upperLimit"
                  variant="outlined"
                  value={editInfo.upperLimit}
                  type="number"
                  onChange={(e) => setUpperBound(parseInt(e) - 1)}
                  inputProps={{
                    max: 100,
                    min: lowerBound,
                  }}
                  form
                  required
                />
              )}

              <div
                style={{
                  padding: '20px',
                  position: 'absolute',
                  bottom: 0,
                  background: 'white',
                  width: '100%',
                  left: 0,
                  display: 'flex',
                }}
              >
                <Button
                  label="delete"
                  id="delete"
                  type="delete"
                  ignoreDefault
                  onClick={clickedDeleteNode}
                />
                <Button
                  label="submit"
                  id="submit"
                  type="submit"
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </Form>
          </div>
        </div>
      )}
      <Modal open={modalOpen} toggle={toggleModal} title={'Confirm Delete'}>
        <div>{confirmationMessage.msg}</div>
        {confirmationMessage.nonDefaultCount <= 1 && (
          <div style={{ display: 'flex', marginTop: '20px' }}>
            <Button
              label="Cancel"
              id="confirm"
              type="confirm"
              onClick={() => toggleModal()}
            />
            <Button
              label="Confirm"
              id="confirm"
              type="confirm"
              style={{ marginLeft: '10px' }}
              onClick={() => confirmDelete()}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
