import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm } from 'react-hook-form';

export default function SwitchX(props) {
  const [checked, setChecked] = useState(props.value);

  const handleOnChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.checked);
    }
    // setValue(e.target.value);
  };

  return (
    <FormControlLabel
      control={
        <Switch defaultChecked={props.checked} onChange={handleOnChange} />
      }
      label={props.label}
    />
  );
}
