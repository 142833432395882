import React, { useState, useContext, useEffect, useMemo } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation } from 'react-router-dom';

import DashboardPagesPicker from './DashboardPagesPicker';
import './Style.css';
import { DASHBOARD_IDS_LIST } from 'Services/GlobalConstants';
import { Dayjs } from 'dayjs';
import IframeDisplay from './iframeDisplay';
import PrecisionMedicine from './PrecisionMedicine/Main.js';

import { useLazyQuery } from '@apollo/client';
import { GET_DASHBOARD_URL } from './GraphQL/queries';

export default function ViewDashboard(props) {
  const pathnameDashboardIdMap = useMemo(() => {
    let map = {};
    DASHBOARD_IDS_LIST.forEach((item) => {
      map[item.routeName] = item.id;
    });
    return map;
  });

  const location = useLocation();
  const navigate = useNavigate();
  const [pathname, setPathname] = useState(location.pathname.split('/')[2]);
  // Possible pathnames = overview, revenue-generation, operational-opportunites and precision-medicine
  const [dashboardURL, setDashboardURL] = useState('');
  const [dashboardId, setDashboardId] = useState(
    pathnameDashboardIdMap[pathname]
  );
  const currentDashboardProperties = useMemo(() => {
    return DASHBOARD_IDS_LIST.find((item) => item.id === dashboardId);
  });
  const [isLoading, setIsLoading] = useState(false);

  const [
    getDashboardUrl,
    {
      loading: getDashboardUrlLoading,
      data: getDashboardUrlData,
      error: getDashboardUrlError,
    },
  ] = useLazyQuery(GET_DASHBOARD_URL, {
    fetchPolicy: 'no-cache',
    variables: {
      operation: 'getDashboardUrl',
      input: { id: dashboardId },
    },
  });

  console.log(
    getDashboardUrlData,
    getDashboardUrlLoading,
    getDashboardUrlError
  );

  useEffect(() => {
    if (getDashboardUrlData && getDashboardUrlData.getDashboardUrl) {
      console.log('getDashboardUrl', getDashboardUrlData);
      setDashboardURL(getDashboardUrlData.getDashboardUrl.body);
    }
  }, [getDashboardUrlData, getDashboardUrlError]);

  useEffect(() => {
    if (currentDashboardProperties && currentDashboardProperties.isIframe) {
      console.log('GETING DAHS: ', dashboardId);
      getDashboardUrl();
    }
  }, [dashboardId]);

  useEffect(() => {
    navigate(`/view-dashboard/${pathname}`);
  }, [pathname]);

  useEffect(() => {
    if (location.pathname === '/view-dashboard') {
      navigate('/view-dashboard/overview');
      setDashboardId(pathnameDashboardIdMap['overview']);
    }
  }, []);

  console.log('Current dashboard id properties', currentDashboardProperties);

  return (
    <div>
      <DashboardPagesPicker
        dashboardId={dashboardId}
        setDashboardId={setDashboardId}
        setPathname={setPathname}
      />
      {currentDashboardProperties && currentDashboardProperties.isIframe ? (
        <IframeDisplay
          url={dashboardURL}
          isLoading={getDashboardUrlLoading}
          height={900}
        />
      ) : (
        <PrecisionMedicine id={dashboardId} />
      )}
    </div>
  );
}
