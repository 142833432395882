export const dnaResponses = [
    {
      category: "Anticancer Agents",
      drugClass: "Antifolates",
      drugName: "Methotrexate",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Azilsartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Candesartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Eprosartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Irbesartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Losartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Olmesartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Telmisartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Angiotensin II Receptor Antagonists",
      drugName: "Valsartan",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antianginal Agents",
      drugName: "Ranolazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Amiodarone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Disopyramide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Flecainide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Mexiletine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Propafenone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Quinidine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiarrhythmics",
      drugName: "Sotalol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Apixaban",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Betrixaban",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Dabigatran Etexilate",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Edoxaban",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Fondaparinux",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Rivaroxaban",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Anticoagulants",
      drugName: "Warfarin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiplatelets",
      drugName: "Clopidogrel",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiplatelets",
      drugName: "Prasugrel",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiplatelets",
      drugName: "Ticagrelor",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Antiplatelets",
      drugName: "Vorapaxar",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Atenolol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Bisoprolol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Carvedilol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Labetalol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Metoprolol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Nebivolol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Propanolol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Beta Blockers",
      drugName: "Timolol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Diuretics",
      drugName: "Torsemide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Atorvastatin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Fluvastatin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Lovastatin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Pitavastatin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Pravastatin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Rosuvastatin",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Cardiovascular",
      drugClass: "Statins",
      drugName: "Simvastatin",
      recommendation: "Consider Alternatives",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Meglitinides",
      drugName: "Nateglinide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Meglitinides",
      drugName: "Repaglinide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Sulfonylureas",
      drugName: "Chlorpropamide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Sulfonylureas",
      drugName: "Glimepiride",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Sulfonylureas",
      drugName: "Glipizide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Sulfonylureas",
      drugName: "Glyburide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Diabetes",
      drugClass: "Sulfonylureas",
      drugName: "Tolbutamide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Aprepitant",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Dolasetron",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Fosaprepitant",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Fosnetupitant",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Granisetron",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Metoclopramide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Netupitant / Palonosetron",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Ondansetron",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Palonosetron",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Rolapitant",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Antiemetics",
      drugName: "Dronabinol",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Proton Pump Inhibitors",
      drugName: "Esomeprazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Proton Pump Inhibitors",
      drugName: "Rabeprazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Proton Pump Inhibitors",
      drugName: "Dexlansorprazole",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Proton Pump Inhibitors",
      drugName: "Lansoprazole",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Proton Pump Inhibitors",
      drugName: "Omeprazole",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Gastrointestinal",
      drugClass: "Proton Pump Inhibitors",
      drugName: "Pantoprazole",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Gaucher Disease",
      drugClass: "Endocrine-Metabolic Agents",
      drugName: "Eliglustat",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gaucher Disease",
      drugClass: "Endocrine-Metabolic Agents",
      drugName: "Imiglucerase",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gaucher Disease",
      drugClass: "Endocrine-Metabolic Agents",
      drugName: "Miglustat",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gaucher Disease",
      drugClass: "Endocrine-Metabolic Agents",
      drugName: "Taliglucerase alfa",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Gaucher Disease",
      drugClass: "Endocrine-Metabolic Agents",
      drugName: "Velaglucerase alfa",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Hematology",
      drugClass: "Hemostatic Agents",
      drugName: "Avatrombopag",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Hematology",
      drugClass: "Hemostatic Agents",
      drugName: "Eltrombopag",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Hematology",
      drugClass: "Hemostatic Agents",
      drugName: "Lusutrombopag",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Amphotericin B",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Anidulafungin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Caspofungin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Fluconazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Isavuconazonium",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Itraconazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Micafungin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Posaconazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Antifungals",
      drugName: "Voriconazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Anti-HIV Agents",
      drugName: "Dolutegravir",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Anti-HIV Agents",
      drugName: "Doravirine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Anti-HIV Agents",
      drugName: "Efavirenz",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Anti-HIV Agents",
      drugName: "Etravirine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Anti-HIV Agents",
      drugName: "Raltegravir",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Anti-HIV Agents",
      drugName: "Rilpivirine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Infections",
      drugClass: "Animalarials",
      drugName: "Proguanil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Fibromyalgia Agents",
      drugName: "Milnacipran",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Muscle Relaxants",
      drugName: "Carisoprodol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Muscle Relaxants",
      drugName: "Cyclobenzaprine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Muscle Relaxants",
      drugName: "Metaxalone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Muscle Relaxants",
      drugName: "Methocarbamol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Muscle Relaxants",
      drugName: "Tizanidine",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Celecoxib",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Diclofenac",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Flurbiprofen",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Ibuprofen",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Indomethacin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Ketoprofen",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Ketorolac",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Meloxicam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Nabumetone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Naproxen",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Piroxicam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "NSAIDs",
      drugName: "Sulindac",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Alfentanil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Benzhydrocodone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Burpenorphine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Codeine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Dihydrocodeine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Fentanly",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Hydrocodone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Hydromorphone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Levorphanol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Meperidine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Methadone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Morphine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Oxycodone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Oxymorphone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Sufentanil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Tapentadol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Pain",
      drugClass: "Opioids",
      drugName: "Tramadol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antiaddictives",
      drugName: "Bupropion",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antiaddictives",
      drugName: "Aplenzin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antiaddictives",
      drugName: "Lofexidine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Amphetamine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Clonidine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Dextroamphetamine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Guanfacine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Lisdexamfetamine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Atomoxetine",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Dexmethylphenidate",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Methylphenidate",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Aptensio XR",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anti-ADHD Agents",
      drugName: "Metadate ER",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Brivaracetam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Cannabidiol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Carbamizepine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Carbatrol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Eslicarbazepine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Ethosuximide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Ezogabine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Felbamate",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Fosphenytoin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Gabapentin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Lacosamide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Lamotrigine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Levetiracetam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Oxcarbazepine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Perampanel",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Phenobarbital",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Phenytoin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Pregabalin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Primidone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Rufinamide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Tiagabine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Topiramate",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Valproic Acid",
      recommendation: "Standard Precautions",
      status: "Active",
      details:
        "Pharmacogenetic guidance: Genotype results obtained from the pharmacogenetic test performed in this patient cannot be used to identify patients carrying mutations in mitochondrial DNA polymerase y (POLG). Valproic acid is contraindicated in patient known to have mitochondrial disorders caused by mutations in mitochondrial ONA polymerase y (POLG; e.g., Alpers-Huttenlocher Syndrome) and children under two years of age who are suspected of having a POLG-related disorder.\n\nValproic acid is extensively metabolized in the liver, which occurs primarily by glucuronidation with probable contributions of UGTJA6, UGT1 A9, and UGT2B7. This drug is also metabolized by a minor CYP—dependent oxidation pathway, which includes multiple enzymes such as CYP2A6, CYP2C9, and CYP2C 19. There are insufficient studies documenting the impact of genetic polymorphisms of these metabolizing enzymes on valproic acid response, and no genetically guided drug selection or dosing recommendations are available. Polypharmacy guidance: enzyme-inducing drugs increase valproic acid clearance 2-fold, and higher doses of this drug are required to maintain therapeutic concentrations when added to a therapy regimen containing enzyme-inducing antiepileptic drugs.",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Vigabatrin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Anticonvulsants",
      drugName: "Zonisamide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidementia Agents",
      drugName: "Donepezil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidementia Agents",
      drugName: "Galantamine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidementia Agents",
      drugName: "Memantine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Amitriptyline",
      recommendation: "Standard Precautions",
      status: "Active",
      details:
        "The patient is predicted to be a normal CYP2D6 metabolizer which is likely to result in normal metabolism of amitriptyline to less active compounds.\n\nPsychiatric Conditions: Amitriptyline therapy can be prescribed according to standard recommended dosage and administration.\n\nNeuropathic Pain: Amitriptyline therapy can be prescribed according to standard recommended dosage and administration.",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Amoxapine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Citalopram",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Clomipramine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Desipramine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Desvenlafaxine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Doxepin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Duloxetine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Escitalopram",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Fluoxetine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Fluboxamine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Imipramine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Levomilnacipran",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Maprotiline",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Mirtazapine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Nefazodone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Nortriptyline",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Paroxetine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Protriptyline",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Sertraline",
      recommendation: "Standard Precautions",
      status: "Active",
      details:
        "Sertraline can be prescribed at standard label-recommended dosage and administration.",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Trazodone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Trimipramine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Venlafaxine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Vilazodone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antidepressants",
      drugName: "Vortioxetine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Aripiprazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Asenapine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Brexpiprazole",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Cariprazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Chlorpromazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "chlorpromazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Fluphenazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Haloperidol",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Iloperidone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Loxapine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Lurasidone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Paliperidone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Perphenazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Pimavanserin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Pimozide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Quetiapine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Risperidone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Thioridazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Thiothixene",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Trifluoperazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Ziprasidone",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Clozapine",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Antipsychotics",
      drugName: "Olanzapine",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Benzodiazepines",
      drugName: "Alprazolam",
      recommendation: "Standard Precautions",
      status: "Active",
      details:
        "Pharmacogenetic guidance: Alprazolam is primarily eliminated by metabolism via CYP3A4 and CYP3A5. Genetic polymorphisms of these genes are not expected to affect the efficacy or safety profiles of this drug. Polypharmacy guidance: The concomitant use of alprazolam with CYP3A4 inhibitors may result in increased alprazolam levels and prolonged sedation. Impairment of motor skills are also observed with some combinations. Monitor patients for exaggerated sedative effects. If possible, alprazolam should be avoided in patients receiving strong inhibitors of CYP3A4 such as ketoconazole, itraconazole and ritonavir. Drugs that induce CYP3A enzymes may decrease a1prazolam levels. which results in a loss of efficacy.",
    },
    {
      category: "Psychotropic",
      drugClass: "Benzodiazepines",
      drugName: "Clobazam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Benzodiazepines",
      drugName: "Clonazepam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Benzodiazepines",
      drugName: "Diazepam",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Other Neurological Agents",
      drugName: "Deutetrabenazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Other Neurological Agents",
      drugName: "Dextromethorphan / Quinidine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Other Neurological Agents",
      drugName: "Flibanserin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Other Neurological Agents",
      drugName: "Valbenazine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Psychotropic",
      drugClass: "Other Neurological Agents",
      drugName: "Tetrabenazine",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Rheumatology",
      drugClass: "Anti-Hyperuricemics and Anti-Gout Agents",
      drugName: "Colchicine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Rheumatology",
      drugClass: "Anti-Hyperuricemics and Anti-Gout Agents",
      drugName: "Febuxostat",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Rheumatology",
      drugClass: "Immunomodulators",
      drugName: "Apremilast",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Rheumatology",
      drugClass: "Immunomodulators",
      drugName: "Leflunomide",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Rheumatology",
      drugClass: "Immunomodulators",
      drugName: "Tofacitinib",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Sjogren's Syndrome",
      drugClass: "Cholinergic Agonists",
      drugName: "Cevimeline",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Transplantation",
      drugClass: "Immunosuppressants",
      drugName: "Tacrolimus",
      recommendation: "Use with Caution",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "5-Alpha Reductase Inhibitors for Benign Prostatic Hyperplasia",
      drugName: "Dutasteride",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "5-Alpha Reductase Inhibitors for Benign Prostatic Hyperplasia",
      drugName: "Finasteride",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Alpha-Blockers for Benign Prostatic Hyperplasia",
      drugName: "Alfuzodin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Alpha-Blockers for Benign Prostatic Hyperplasia",
      drugName: "Doxazosin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Alpha-Blockers for Benign Prostatic Hyperplasia",
      drugName: "Silodosin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Alpha-Blockers for Benign Prostatic Hyperplasia",
      drugName: "Tamsulosin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Alpha-Blockers for Benign Prostatic Hyperplasia",
      drugName: "Terazosin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Darifenacin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Fesoterodine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Mirabegron",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Oxybutynin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Solifenacin",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Tolerodine",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Antispasmodics for Overactive Bladder",
      drugName: "Trospium",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Phosphodiesterase Inhibitors for Erectile Dysfunction",
      drugName: "Avanafil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Phosphodiesterase Inhibitors for Erectile Dysfunction",
      drugName: "Slidenafil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Phosphodiesterase Inhibitors for Erectile Dysfunction",
      drugName: "Tadalafil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
    {
      category: "Urologicals",
      drugClass: "Phosphodiesterase Inhibitors for Erectile Dysfunction",
      drugName: "Vardenafil",
      recommendation: "Standard Precautions",
      status: "Inactive",
    },
  ];