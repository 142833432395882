import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Modal } from 'Stories';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import { VideoPlayer } from 'Stories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Lottie from 'lottie-react';
import initalLoadingAnimation from 'Assets/Inner-Immersion-Splash-Screen.json';

import { useMutation } from '@apollo/client';
import { EXECUTE_ADMIN_TASK } from 'Pages/FacilitateImmersion/GraphQL/mutations.js';

export default function BasicTable(props) {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [videoLink, setVideoLink] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [linkType, setLinkType] = useState('');
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const toggleModal = () => {
    if (confirmDeleteModal) setModalInfo(null);
    setConfirmDeleteModal(!confirmDeleteModal);
  };
  console.log('Data', props.data);

  function LinkCell({ link, title }) {
    const isVideo = link.endsWith('mp4');

    return isVideo ? (
      <Link
        to=""
        onClick={(e) => {
          e.preventDefault();
          setLinkType('video');
          setVideoTitle(title);
          setVideoLink(link);
          setIsVideoOpen(true);
        }}
      >
        {title}
      </Link>
    ) : (
      <Link
        to=""
        onClick={(e) => {
          e.preventDefault();
          setLinkType('download');
          setVideoTitle(title);
          setVideoLink(link);
          setIsVideoOpen(true);
        }}
      >
        {title}
      </Link>
    );
  }

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        style={{ tableLayout: 'fixed' }}
      >
        {props.labels && props.labels.length > 0 && !props.customHeader ? (
          <TableHead>
            <TableRow>
              {props.labels.map((item, index) => {
                return (
                  <TableCell
                    align={item.alignment ? item.alignment : 'left'}
                    size={item.size ? item.size : 'medium'}
                    key={'TableLabel_' + index}
                    variant={'head'}
                    sx={item.sx}
                  >
                    {item.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        {props.customHeader}
        {!props.loading && (
          <TableBody>
            {props.data &&
              props.data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {props.labels.map((label) => {
                    let rowInfo = null;

                    if (label.key === 'actions') {
                      rowInfo = (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {row.actions.edit && (
                            <div
                              style={{ paddingLeft: '10px', cursor: 'pointer' }}
                              onClick={() => {
                                if (props.onClickEdit) props.onClickEdit(row);
                              }}
                            >
                              <FontAwesomeIcon
                                size="lg"
                                icon={solid('pen-to-square')}
                              />
                            </div>
                          )}

                          {row.actions.diagram && (
                            <div
                              style={{ paddingLeft: '10px', cursor: 'pointer' }}
                              onClick={() => {
                                if (props.onClickDiagram)
                                  props.onClickDiagram(row);
                              }}
                            >
                              <FontAwesomeIcon
                                size="lg"
                                icon={solid('diagram-project')}
                              />
                            </div>
                          )}

                          {row.actions.delete && (
                            <div
                              style={{ paddingLeft: '10px', cursor: 'pointer' }}
                              onClick={() => {
                                if (props.confirmDelete) {
                                  setModalInfo(row);
                                  setConfirmDeleteModal(true);
                                } else if (props.onClickDelete) {
                                  props.onClickDelete(row);
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                size="lg"
                                icon={solid('trash')}
                              />
                            </div>
                          )}

                          {row.actions.manage && (
                            <div
                              style={{ paddingLeft: '10px', cursor: 'pointer' }}
                              onClick={() => {
                                if (props.onClickManage)
                                  props.onClickManage(row);
                              }}
                            >
                              <FontAwesomeIcon
                                size="lg"
                                icon={solid('wrench')}
                              />
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      rowInfo = row[label.key];
                    }

                    return (
                      <TableCell
                        key={uuidv4()}
                        align={label.alignment}
                        size={label.size ? label.size : 'medium'}
                        style={{
                          ...props.tableCellGeneralStyles,
                          ...(props.tableCellColumnStyles &&
                            props.tableCellColumnStyles[label.key]),
                          whiteSpace: 'normal',
                          textOverflow: 'ellipsis',
                          width: 'auto',
                          overflow: 'hidden',
                        }}
                      >
                        {rowInfo ? ( // Check if rowInfo is null
                          Array.isArray(rowInfo) ? ( // Check if array
                            rowInfo.map((row, index) => {
                              return (
                                <div key={index}>
                                  <LinkCell
                                    link={row.link}
                                    title={row.title}
                                    setOpen={setIsVideoOpen}
                                  />
                                </div>
                              );
                            })
                          ) : rowInfo.title ? ( // Check if object
                            <LinkCell
                              link={rowInfo.link}
                              title={rowInfo.title}
                            />
                          ) : (
                            rowInfo
                          ) // Check if string
                        ) : (
                          ''
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
      {props.loading && (
        <div
          style={{ height: '90px', display: 'flex', justifyContent: 'center' }}
        >
          <Lottie animationData={initalLoadingAnimation} loop={true} />
        </div>
      )}
      <Modal
        open={confirmDeleteModal}
        toggle={toggleModal}
        title={'Confirm Delete'}
        confirmation={true}
        toggleModal={toggleModal}
        modalInfo={modalInfo}
        onClickDelete={() => {
          props.onClickDelete(modalInfo);
          toggleModal();
        }}
      >
        <div>Are you sure you want to delete?</div>
      </Modal>
      <VideoPlayer
        link={videoLink}
        title={videoTitle}
        open={isVideoOpen}
        setOpen={setIsVideoOpen}
        type={linkType}
      />
    </TableContainer>
  );
}
