import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { modules } from 'Pages/TrainingModules/Constants';
import Module from 'Pages/TrainingModules/Module';
import {
  Conditions,
  Symptoms,
  Home,
  Products,
  Survey,
  SurveyBuilder,
  AddSurvey,
  AppConfigs,
  ViewConfig,
  ManageConfig,
  ManageUsers,
  BioTracker,
  PhysicianConnect,
  RxTracker,
  UserFields,
  Variables,
  Dashboards,
  ViewDashboard,
  Account,
  ChangePassword,
  UsersAndGroups,
  GroupManagement,
  CompanyInfo,
  FacilitateImmersion,
  ViewImmersionVideo,
  TrainingModules,
} from 'Pages';

import {
  BrowserRouter as Router,
  Routes as DOMRoutes,
  Route,
  redirect,
  Navigate,
} from 'react-router-dom';
import { View } from '@aws-amplify/ui-react';

export default function Routes() {
  const user = Auth.user;

  function isAdmin() {
    try {
      return (
        user.signInUserSession.idToken.payload['cognito:groups'].indexOf(
          'Admin'
        ) > -1
      );
    } catch (e) {
      return false;
    }
  }

  const isTrainee = () => {
    try {
      return (
        user.signInUserSession.idToken.payload['cognito:groups'][0] ===
        'Facilitator_in_Training'
      );
    } catch (e) {
      return true;
    }
  };

  const hasPageAccess = (pageName) => {
    try {
      let pageAccess = JSON.parse(
        user.signInUserSession.idToken.payload.pageAccess
      );
      return (
        pageAccess.indexOf('Admin') > -1 || pageAccess.indexOf(pageName) > -1
      );
    } catch (e) {
      return false;
    }
  };

  const RedirectToTraining = () => {
    return redirect('/training-modules');
  };

  if (isTrainee()) {
    return (
      <DOMRoutes>
        <Route path="/" element={<Home />} />
        {
          <Route path="/training-modules">
            <Route index element={<TrainingModules />} />
            {modules.map((module, index) => {
              const isDisabled =
                !isAdmin() &&
                new Date(module.enableDate).getTime() > new Date().getTime();
              if (!isDisabled) {
                return (
                  <Route
                    key={index}
                    path={module.path}
                    element={
                      <Module title={module.title} content={module.content} />
                    }
                  />
                );
              }
            })}
          </Route>
        }

        <Route path="/facilitate-immersion" element={<FacilitateImmersion />} />
        <Route path="/view-immersion-video" element={<ViewImmersionVideo />} />
        <Route path="*" element={<Navigate to="/training-modules" replace />} />
      </DOMRoutes>
    );
  } else {
    return (
      <DOMRoutes>
        <Route path="/app-configs" element={<AppConfigs />} />
        <Route path="/dashboards" element={<Dashboards />} />
        {hasPageAccess('analytics') && (
          <Route path="/users" element={<UsersAndGroups />} />
        )}
        {hasPageAccess('analytics') && (
          <Route path="/groups" element={<GroupManagement />} />
        )}
        <Route path="/company-info" element={<CompanyInfo />} />
        {hasPageAccess('analytics') && (
          <Route path="/view-dashboard" element={<ViewDashboard />}>
            <Route index element={<ViewDashboard />} />
            <Route path="overview" element={<ViewDashboard />} />
            <Route path="revenue-generation" element={<ViewDashboard />} />
            <Route
              path="operational-opportunities"
              element={<ViewDashboard />}
            />
            <Route path="precision-medicine" element={<ViewDashboard />} />
          </Route>
        )}

        <Route path="/account" element={<Account />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/facilitate-immersion" element={<FacilitateImmersion />} />
        {hasPageAccess('training-modules') && (
          <Route path="/training-modules">
            <Route index element={<TrainingModules />} />
            {modules.map((module, index) => {
              return (
                <Route
                  key={index}
                  path={module.path}
                  element={
                    <Module title={module.title} content={module.content} />
                  }
                />
              );
            })}
          </Route>
        )}
        <Route path="/view-immersion-video" element={<ViewImmersionVideo />} />
        <Route path="/view-config">
          <Route index element={<ViewConfig />} />
          <Route path="configure" element={<ManageConfig />} />
          <Route path="conditions" element={<Conditions />} />
          <Route path="variables" element={<Variables />} />
          <Route path="symptoms" element={<Symptoms />} />
          <Route path="products" element={<Products />} />
          <Route path="bio-tracker" element={<BioTracker />} />
          <Route path="physician-connect" element={<PhysicianConnect />} />
          <Route path="rx-tracker" element={<RxTracker />} />
          <Route path="user-fields" element={<UserFields />} />

          {hasPageAccess('surveyBuilder') && (
            <Route path="surveys" element={<Survey />} />
          )}
          {hasPageAccess('surveyBuilder') && (
            <Route path="survey-builder" element={<SurveyBuilder />} />
          )}
          {hasPageAccess('surveyBuilder') && (
            <Route path="add-survey" element={<AddSurvey />} />
          )}

          <Route path="manage-users" element={<ManageUsers />} />
        </Route>
        <Route path="/" element={<Home />} />
      </DOMRoutes>
    );
  }
}
