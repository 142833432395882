import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function BasicListItem(props) {
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const { name, icon } = props.item;

  return (
    <ListItem
      key={name}
      sx={{ display: 'block' }}
      onClick={(e) => props.handleClick(props.item)}
    >
      <ListItemButton
        selected={props.selected}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          display: 'block',
          textAlign: 'center'
        }}
      >
        <ListItemIcon
          sx={{
            fontSize: 28,
            width: "100%",
            display: 'block',
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
            color: props.color ? props.color : 'white',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={props.item.name}
          sx={{ opacity: open ? 1 : 0, display: 'block', fontSize: "12px" }}
          style={{ color: props.color ? props.color : 'white' }}
        />
      </ListItemButton>
    </ListItem>
  );
}

BasicListItem.defaultProps = {
  handleClick: () => {},
};
