// Component : TrainingModules
// Path: src\Pages\TrainingModules\Main.js
// Compare this snippet from src\Pages\FacilitateImmersion\Main.js:
import React, { useState, useEffect } from 'react';
import { modules } from './Constants';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './styles.css';

export default function TrainingModules(props) {
  const user = Auth.user;
  const [isAdmin, setIsAdmin] = useState(false);

  let navigate = useNavigate();

  const setUserLevel = () => {
    let x =
      user.signInUserSession.idToken.payload['cognito:groups'].indexOf(
        'Admin'
      ) > -1;

    setIsAdmin(x);
  };

  useEffect(() => {
    setUserLevel();
  }, []);

  return (
    <div>
      <h2>Immersion Training Programs</h2>
      <div style={{ width: 1450 }}>
        {modules.map((module, index) => {
          const isDisabled =
            !isAdmin &&
            new Date(module.enableDate).getTime() > new Date().getTime();
          return (
            <img
              src={module.image}
              width={400}
              className={`module-image ${isDisabled ? 'disabled' : ''}`}
              onClick={() => {
                if (!isDisabled) navigate(module.path);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
