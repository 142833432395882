import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import tasks from 'Assets/Tasks.jpg';
import admin from 'Assets/Admin.jpg';

import PageForm from './Form.js';

import './Main.scss';

import { useLazyQuery, useMutation } from '@apollo/client';
import { DELETE_SURVEY } from './GraphQL/mutations';
import { LIST_SURVEYS } from './GraphQL/queries';

const labels = [
  {
    alignment: 'left',
    label: 'Survey Name',
    key: 'title',
  },
  {
    alignment: 'left',
    label: 'Description',
    key: 'description',
  },
  {
    alignment: 'right',
    label: 'Date Added',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: ' ',
    key: 'actions',
    size: 'small',
  },
];

export default function Surveys(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  let navigate = useNavigate();

  const toggleModal = (e) => {
    setModalOpen(!modalOpen);
  };

  const location = useLocation();
  const { appConfig } = location.state;

  const [
    listSurveys,
    { loading: listLoading, data: listData, error: listError },
  ] = useLazyQuery(LIST_SURVEYS, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination: {
        nextToken: null,
        limit: pageLimit,
      },
    },
  });

  const [
    deleteItem,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_SURVEY);

  useEffect(() => {
    console.log('DATA: ', listData);
    if (listData && listData.listSurveys) {
      let items = listData.listSurveys.data;

      for (var i = 0; i < items.length; ++i) {
        let name = items[i].addedBy;

        if (items[i].addedByUserInfo) {
          name =
            items[i].addedByUserInfo.given_name +
            ' ' +
            items[i].addedByUserInfo.family_name;
        }

        items[i] = {
          ...items[i],
          addedByDisplayName: name,
          actions: {
            edit: true,
            delete: true,
            diagram: true,
          },
        };
      }
      setData(items);
    } else if (listError) {
      console.log('Failed getting: ', listError);
    }
  }, [listData, listError]);

  const getItems = async () => {
    listSurveys({
      variables: {
        appConfigId: appConfig.id,
      },
    });
  };

  useEffect(() => {
    if (!modalOpen) {
      getItems();
    }
  }, [modalOpen]);

  const onClickDelete = async (e) => {
    deleteItem({
      variables: {
        id: e.id,
      },
    });

    setModalOpen(false);
    setTimeout(() => {
      getItems();
    }, 1000);
  };

  const onClickEdit = (e) => {
    toggleModal(e);
  };

  const onClickDiagram = (e) => {
    navigate('../survey-builder', {
      state: { editInfo: e, appConfig: appConfig },
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>Surveys</div>
        <Button label="Add new survey" onClick={(e) => toggleModal({})} />
      </div>
      <Table
        labels={labels}
        data={data}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickDiagram={onClickDiagram}
      />
      <Modal open={modalOpen} toggle={toggleModal} title={'Add Survey'}>
        <PageForm
          editInfo={editInfo}
          setModalOpen={setModalOpen}
          setEditInfo={setEditInfo}
          appConfigId={appConfig.id}
        />
      </Modal>
    </div>
  );
}
