import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal, Card, Select } from 'Stories';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import './Account.css';
export default function Account(props) {
  const navigate = useNavigate();

  const user = Auth.user;

  const [username, setUsername] = useState(user.username);
  const [givenName, setGivenName] = useState(user.attributes.given_name);
  const [familyName, setFamilyName] = useState(user.attributes.family_name);
  const [email, setEmail] = useState(user.attributes.email);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const languageOptions = [
    {
      label: 'English',
      value: 0,
    },
    {
      label: 'Dutch',
      value: 1,
    },
  ]; // Placeholder only
  const handleChange = (e, setValue) => {
    console.log('Setting ', e);
    setValue(e);
    const delayDebounceFn = setTimeout(() => {
      saveChanges();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  };

  const saveChanges = () => {
    // Fake saving
    console.log('Saving', {
      username,
      givenName,
      familyName,
      email,
      phoneNumber,
    });
    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
      setIsSaved(true);
    }, 400);
    // Placeholder method for saving changes through API
  };
  console.log('User details', user);
  return (
    <div>
      <div>
        <h3>User account</h3>
        <div className="content">
          {/* Placeholder profile image */}
          <img
            src="https://t3.ftcdn.net/jpg/01/18/01/98/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg"
            className="profile-picture"
          ></img>
          <div className="fields">
            <Paper variant={'outlined'} className={'field-category'}>
              <div className="text-input">
                <TextInput
                  label="Display Name"
                  value={username}
                  onChange={(e) => handleChange(e, setUsername)}
                  fullWidth
                />
              </div>
              <div className="text-input">
                <TextInput
                  label="First Name"
                  value={givenName}
                  onChange={(e) => handleChange(e, setGivenName)}
                  fullWidth
                />
              </div>
              <div className="text-input">
                <TextInput
                  label="Last Name"
                  value={familyName}
                  onChange={(e) => handleChange(e, setFamilyName)}
                  fullWidth
                />
              </div>
              <div className="text-input">
                <TextInput
                  label="E-mail"
                  type={'email'}
                  value={email}
                  onChange={(e) => handleChange(e, setEmail)}
                  fullWidth
                />
              </div>
              <div className="text-input">
                <TextInput
                  label="Phone Number"
                  type={'tel'}
                  value={phoneNumber}
                  onChange={(e) => handleChange(e, setPhoneNumber)}
                  fullWidth
                />
              </div>
              <div className="text-input message">
                {isSaving ? (
                  <p>
                    {'Saving Changes'}
                    <CircularProgress size={12} className={'progress'} />
                  </p>
                ) : (
                  ''
                )}
                {!isSaving && isSaved ? (
                  <p>{'Settings have been saved (placeholder message)'}</p>
                ) : (
                  ''
                )}
              </div>
            </Paper>
            <Paper className="field-category" variant={'outlined'}>
              <div className="text-input">
                <Select
                  label="Primary Language"
                  options={languageOptions}
                  value={0}
                  fullWidth
                  onChange={saveChanges}
                />
              </div>
              <div className="text-input">
                <TextInput
                  label="City"
                  value={phoneNumber}
                  onChange={(e) => handleChange(e, setCity)}
                  fullWidth
                />
              </div>
              <div className="text-input">
                <TextInput
                  label="State/Province"
                  value={phoneNumber}
                  onChange={(e) => handleChange(e, setStateOrProvince)}
                  fullWidth
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
      <div>
        <h3>Security</h3>
        <div className="content">
          <Button
            label="Change Password"
            onClick={() => navigate('/change-password')}
          />
        </div>
      </div>
    </div>
  );
}
