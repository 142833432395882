import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';

export default function SelectX(props) {
  const [value, setValue] = useState(
    props.value ? props.value : props.isMulti ? [] : null
  );

  const handleOnChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  let registration = null;

  if (props.register) {
    registration = props.register(props.name ? props.name : props.id);
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={props.id}
          name={props.name}
          defaultValue={value ?? ''}
          label={props.label}
          onChange={handleOnChange}
          required={props.required}
          style={{ backgroundColor: 'white', ...props.style }}
          multiple={props.isMulti}
          {...registration}
        >
          {props.options.map((option) => {
            if (option.isTitle) {
              if (!option.value) {
                return <hr style={{ borderTop: '1px solid #ebebeb' }} />;
              } else {
                return <ListSubheader>{option.value}</ListSubheader>;
              }
            } else {
              return <MenuItem value={option.value}>{option.label}</MenuItem>;
            }
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
