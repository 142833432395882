import React from 'react';
import Menu from './Menu/Menu';
import Box from '@mui/material/Box';

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundColor: '#f1f1f1',
        overflowX: 'scroll',
        height: '100vh',
      }}
    >
      <Box>
        <Menu />
      </Box>
    </div>
  );
}

export default App;
