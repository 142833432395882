import React, { useState, useEffect, useRef, useContext } from 'react';
import { CanvasControl, theme } from 'Stories';
import { useLocation } from 'react-router-dom';
import { store } from 'Contexts/GlobalState';
import { Space } from 'react-zoomable-ui';
import { GlobalConstants } from 'Services';

import NodeEdit from './NodeEdit/NodeEdit';

import Xarrow, { Xwrapper } from 'react-xarrows';
import './Main.scss';
import { embedTree } from './Tree/utility.js';

import { v4 as uuidv4 } from 'uuid';

import Tree from './Tree/Tree';

import { useLazyQuery, useMutation } from '@apollo/client';
import { LIST_SURVEY_NODES } from './GraphQL/queries';
import { PUT_SURVEY_VERSION } from './GraphQL/mutations';

const ZOOM_MIN = 0.25;
const ZOOM_MAX = 1.75;

export default function SurveyBuilder(props) {
  const [currentZoom, setCurrentZoom] = useState(1);
  const [arrows, setArrows] = useState([]);
  const [embeddedTree, setEmbeddedTree] = useState({});

  const [divContainer, setDivContainer] = useState(null);
  const [currentPosition, setCurrentPostion] = useState({ x: 0, y: 0 });

  const [rawTree, setRawTree] = useState([]);
  const [treeElem, setTreeElem] = useState(null);

  const scrollRef = useRef(null);
  const r = useRef(null);

  const location = useLocation();
  const { appConfig, editInfo } = location.state;

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [
    getSurveyNodes,
    { loading: listLoading, data: listData, error: listError },
  ] = useLazyQuery(LIST_SURVEY_NODES, {
    fetchPolicy: 'no-cache',
    variables: {
      surveyId: editInfo.id,
    },
  });

  const [
    putSurveyVersion,
    {
      data: putSurveyVersionData,
      loading: putSurveyVersionLoading,
      error: putSurveyVersionError,
    },
  ] = useMutation(PUT_SURVEY_VERSION);

  useEffect(() => {
    getSurveyNodes();
  }, []);

  useEffect(() => {
    if (listData && listData.listSurveyNodes) {
      let tmp = listData.listSurveyNodes.data;

      console.log('TMP: ', tmp);

      for (var i = 0; i < tmp.length; ++i) {
        tmp[i].parentAlt = tmp[i].parentAlt ? tmp[i].parentAlt : [];

        tmp[i].parentCombined = tmp[i].parent
          ? [tmp[i].parent].concat(tmp[i].parentAlt)
          : [];
      }

      setRawTree(tmp);
    } else if (listError) {
      console.log('ERROR GETTING: ', listError);
    }
  }, [listData, listError]);

  const centerChart = (givenVp) => {
    let vp = givenVp ? givenVp : r?.current?.viewPort;
    if (vp) {
      let item = divContainer.getBoundingClientRect();
      vp.camera.centerFitAreaIntoView(
        {
          left: item.left,
          top: item.top - 100,
          width: item.width,
          height: item.height,
        },
        undefined,
        {
          durationMilliseconds: 500,
        }
      );
    }
  };

  const updateNode = (e) => {
    getSurveyNodes();
  };

  const updateNodes = (allUpdates) => {
    getSurveyNodes();
  };

  const zoomChart = (num) => {
    r?.current?.viewPort?.camera.moveBy(0, 0, num, undefined, undefined, {
      durationMilliseconds: 500,
    });
  };

  const zoomBtns = () => {
    return (
      <div
        className="Buttons"
        style={{ right: props.showProperties ? '400px' : '0px' }}
      >
        <div className="zoomButtons">
          <CanvasControl
            type="zoomIn"
            style={{
              backgroundColor:
                r?.current?.viewPort?.zoomFactor >= ZOOM_MAX
                  ? '#cecdd5'
                  : theme.palette.general.darkPurple,
            }}
            onClick={() => zoomChart(0.1)}
          />

          <CanvasControl
            type="zoomOut"
            style={{
              backgroundColor:
                r?.current?.viewPort?.zoomFactor <= ZOOM_MIN
                  ? '#cecdd5'
                  : theme.palette.general.darkPurple,
            }}
            onClick={() => zoomChart(-0.1)}
          />
        </div>

        <CanvasControl
          type="center"
          style={{
            backgroundColor: theme.palette.general.darkPurple,
          }}
          onClick={() => centerChart()}
        />
      </div>
    );
  };

  useEffect(() => {
    if (putSurveyVersionData && putSurveyVersionData.putSurveyVersion) {
      let og = window.location.origin;
      let url = null;

      if (og.indexOf('localhost') > -1) {
        url = 'http://localhost:4200';
      } else if (og.indexOf('inner') > -1) {
        url = 'https://innerimmersion.d3fwibokd3sz2e.amplifyapp.com';
      } else {
        url = 'https://master.d3fwibokd3sz2e.amplifyapp.com';
      }

      window.open(url + '/' + editInfo.id + '?r=' + editInfo.referenceId);
    } else if (putSurveyVersionError) {
      console.log('Failed to put version: ', putSurveyVersionError);
    }
  }, [putSurveyVersionData, putSurveyVersionError]);

  const versionSurvey = async () => {
    let refId = uuidv4();

    let versionTree = embedTree(
      JSON.parse(JSON.stringify(rawTree)),
      GlobalConstants.surveyBuilderOptionsCanSplit,
      'version'
    );

    let payload = {
      id: editInfo.id,
      versionId: 1,
      referenceId: refId,
      nodes: JSON.stringify(versionTree.tree),
    };

    putSurveyVersion({
      variables: {
        info: payload,
      },
    });
  };

  return (
    <div>
      <div className="scrollHolder" ref={(e) => setDivContainer(e)}>
        {zoomBtns()}

        <Xwrapper>
          {divContainer && (
            <Space
              ref={r}
              pollForElementResizing={true}
              onCreate={(vp) => {
                let item = divContainer.getBoundingClientRect();
                vp.setBounds({ zoom: [ZOOM_MIN, ZOOM_MAX] });
                centerChart(vp);
              }}
              onUpdated={(e) => {
                setCurrentZoom(e.zoomFactor);
                setCurrentPostion({ left: e.left, top: e.top });
              }}
            >
              <Tree
                currentZoom={currentZoom}
                setArrows={setArrows}
                nodes={rawTree}
                updateNodes={updateNodes}
                surveyInfo={editInfo}
              />
            </Space>
          )}
          {arrows.map((a) => {
            // console.log('ARROW: ', a);
            return (
              <Xarrow
                color={theme.palette.general.darkPurple}
                strokeWidth={3 * currentZoom}
                startAnchor={'bottom'}
                endAnchor={'top'}
                showHead={false}
                showTail={false}
                key={uuidv4()}
                start={a.start}
                end={a.end}
              />
            );
          })}
        </Xwrapper>
        <NodeEdit
          surveyInfo={editInfo}
          triggerReload={getSurveyNodes}
          updateNode={updateNode}
        />
        <div
          className="testBtn"
          onClick={(e) => {
            versionSurvey();
          }}
        >
          Test Survey
        </div>
      </div>
    </div>
  );
}
