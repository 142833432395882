import React from 'react';
import { theme } from 'Stories';
import '../Tree.scss';

export default function Title(props) {
  return (
    <div
      style={{
        ...theme.node,
        ...theme.revertNodeColors,
        fontWeight: 'bold',
        marginBottom: '0px',
      }}
    >
      {props.title}
    </div>
  );
}
