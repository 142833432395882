import React, { useContext } from 'react';
import { theme } from 'Stories';
import '../Tree.scss';

import { store } from 'Contexts/GlobalState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Node(props) {
  const { item } = props;
  const icons = theme.icons.surveyBuilderIcons;

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  const setEditingNode = () => {
    let currentNodeId = state?.editingNode?.id;

    dispatch({
      type: 'setEditingNode',
      editingNode: currentNodeId === item.id ? null : item,
    });
  };

  if (item.hidden) {
    // console.log('NODE: ', item);
    return (
      <span
        className="tf-nc item mainNode"
        key={'matchInfoSpan_' + item.id}
        id={item.id}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          width: '10px',
          height: '0px',
          border: 'none',
          boxShadow: 'none',
          backgroundColor: 'unset',
          padding: '0px',
        }}
      ></span>
    );
  } else {
    return (
      <span
        className="tf-nc item mainNode"
        key={'matchInfoSpan_' + item.id}
        id={item.id}
        style={{
          border: '2px solid white',
          color: theme.palette.general.darkPurple,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          maxWidth: '350px',
          borderColor:
            state.editingNode && state.editingNode.id === item.id
              ? theme.palette.general.darkPurple
              : 'white',
        }}
        onClick={(e) => setEditingNode()}
      >
        <FontAwesomeIcon
          className="alterChartBtn"
          style={{ height: '13px', width: '13px', marginRight: '5px' }}
          icon={icons[item.questionType]}
        />
        <div>{item.identifier ? item.identifier : 'HI'}</div>
      </span>
    );
  }
}
