import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { checkPassword } from './utility.js';
import './Style.css';
import { spacing } from '@mui/system';

export default function PasswordHelper(props) {
  const [hasUpper, setHasUpper] = useState(false);
  const [hasLower, setHasLower] = useState(false);
  const [hasNumeric, setHasNumeric] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);

  function checkForUpper(password) {
    return password && /.*[A-Z]/.test(password);
  }

  function checkForLower(password) {
    return password && /.*[a-z]/.test(password);
  }

  function checkForNumeric(password) {
    return password && /.*[0-9]/.test(password);
  }

  function checkForSpecial(password) {
    return password && /[^A-Za-z0-9]/.test(password);
  }

  const validatePassword = (p) => {
    let up = checkForUpper(p);
    let lw = checkForLower(p);
    let nu = checkForNumeric(p);
    let sp = checkForSpecial(p);

    setHasUpper(up);
    setHasLower(lw);
    setHasNumeric(nu);
    setHasSpecial(sp);

    props.setIsPasswordValid(up && lw && nu && sp);
  };

  useEffect(() => {
    validatePassword(props.password);
  }, [props.password]);

  const CHECKS = [
    {
      fn: hasUpper,
      message: 'One upper case letter',
    },
    {
      fn: hasLower,
      message: 'One lower case letter',
    },
    {
      fn: hasNumeric,
      message: 'One number',
    },
    {
      fn: hasSpecial,
      message: 'One special character',
    },
  ];

  return (
    <div style={{ flex: 1, marginLeft: 15 }}>
      {CHECKS.map((item, index) => {
        return (
          <div
            key={'item_' + index}
            className="passwordChecklistItem"
            style={{
              color: item.fn ? '#37a721' : '#13135d',
            }}
          >
            <div>
              <FontAwesomeIcon
                icon={item.fn ? solid('check') : regular('square')}
              />
            </div>
            <div style={{ marginLeft: 5 }}>{item.message}</div>
          </div>
        );
      })}
    </div>
  );
}
