import React, { useContext } from 'react';
import { NodeButton, theme } from 'Stories';
import '../Tree.scss';
import Node from './Node';
import { v4 as uuidv4 } from 'uuid';
import { store } from 'Contexts/GlobalState';

export default function Branch(props) {
  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  let { item } = props;

  // item = { ...item };

  // if (item.parentAlt) {
  //   for (var i = 0; i < item.parentAlt.length; ++i) {
  //     item.children.push({
  //       hidden: true,
  //     });
  //   }
  // }

  const generatePathLabel = (item, ind) => {
    return 'PATH';
  };

  const setEditingNode = (item) => {
    dispatch({
      type: 'setEditingNode',
      editingNode: item,
    });
  };

  if (item) {
    return (
      <li key={'matchInfo_' + item.id}>
        {item.type === 'add' && (
          <NodeButton
            key={uuidv4()}
            id={item.id}
            type="add"
            onClick={(e) => {
              console.log('ADDING NODExxxxxx:', item);
              props.addNode(item.parentId, e);
            }}
          />
        )}

        {item.type === 'split' && (
          <NodeButton
            key={uuidv4()}
            id={item.id}
            type="split"
            onClick={(e) => props.splitNode(item, true)}
          />
        )}

        {item.isPath && (
          <div
            id={item.id}
            key={uuidv4()}
            onClick={(e) => props.splitNode(item, false)}
          >
            <div
              className="Tree_MatchItem"
              style={{
                backgroundColor: theme.palette.general.darkPurple,
                marginBottom: '0px',
              }}
              key={uuidv4()}
            >
              {item.title}
            </div>
          </div>
        )}

        {!item.type && !item.isPath && <Node item={item} />}

        <ul key={uuidv4()}>
          <li key={uuidv4()}>
            <ul key={'parent_' + item.id}>
              {item.children && item.children.length > 0
                ? item.children.map((c, ind) => {
                    return (
                      <Branch
                        key={uuidv4()}
                        item={c}
                        addNode={props.addNode}
                        splitNode={props.splitNode}
                      />
                    );
                  })
                : null}
              {item.childNeedsOffset &&
                item.childNeedsOffset.map((x) => {
                  return <Branch key={uuidv4()} item={x} />;
                })}
            </ul>
          </li>
        </ul>
      </li>
    );
  } else {
    return null;
  }
}
