import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import { StateProvider as GlobalStateProvider } from 'Contexts/GlobalState';

import './index.css';
import App from 'Main/App';
import reportWebVitals from './reportWebVitals';
import Authentication from 'Main/AuthNew/Authentication.js';
import { Authenticator } from '@aws-amplify/ui-react';
import awsmobile from 'aws-exports';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'Stories';

import { Auth } from '@aws-amplify/auth';

Amplify.configure({
  Auth: {
    ...awsmobile,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator.Provider>
    <ThemeProvider theme={theme}>
      <Authentication username={Auth.user?.username}>
        <BrowserRouter>
          <GlobalStateProvider>
            <App />
          </GlobalStateProvider>
        </BrowserRouter>
      </Authentication>
    </ThemeProvider>
  </Authenticator.Provider>
);

reportWebVitals();
