import { gql } from '@apollo/client';

export const PUT_SURVEY = gql`
  mutation ($info: Survey) {
    putSurvey(info: $info) {
      id
      appConfigId
      title
      description
      addedBy
    }
  }
`;

export const DELETE_SURVEY = gql`
  mutation ($id: String) {
    deleteSurvey(id: $id) {
      id
      appConfigId
      title
      description
      addedBy
    }
  }
`;
