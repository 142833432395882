import { gql } from '@apollo/client';

export const LIST_SURVEYS = gql`
  query ($pagination: DynamoPaginationInput) {
    listSurveys(pagination: $pagination) {
      data {
        id
        appConfigId
        title
        description
        addedBy
      }
      item {
        id
        appConfigId
        title
        description
        addedBy
      }
      nextToken
    }
  }
`;
