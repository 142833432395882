import React, { useState, useEffect } from 'react';
import { theme, Button, Form, TextInput } from 'Stories';

import '../../Tree.scss';
import * as utility from '../../utility.js';

import { Auth } from 'aws-amplify';
import OptionGroup from './OptionGroup.js';

import { v4 as uuidv4 } from 'uuid';

import { useMutation } from '@apollo/client';
import { PUT_SURVEY_NODE } from '../../../GraphQL/mutations';

export default function Node(props) {
  const { rawNodes, embeddedNodes, nodeSplit } = props;
  const user = Auth.user;

  console.log('NODE SPLIT: ', nodeSplit);

  const conditions =
    nodeSplit && nodeSplit.condition ? JSON.parse(nodeSplit.condition) : {};

  console.log('PROPS: ', props);

  const getDefaultValue = (key) => {
    return nodeSplit && nodeSplit[key] ? nodeSplit[key] : '';
  };

  const [
    putSurveyNode,
    {
      data: putSurveyNodeData,
      loading: putSurveyNodeLoading,
      error: putSurveyNodeError,
    },
  ] = useMutation(PUT_SURVEY_NODE);

  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [keyedAnswers, setKeyedAnswers] = useState({});
  const [pathName, setPathName] = useState(getDefaultValue('title'));

  const [selectorType, setSelectorType] = useState('OR');
  const [path, setPath] = useState([]);
  const [id, setId] = useState(getDefaultValue('id'));

  useEffect(() => {
    let x = [];
    let unavailableNodes = {};
    let keyedAnswersx = {};

    for (var i = 0; i < nodeSplit.children.length; ++i) {
      unavailableNodes[nodeSplit.children[i].id] = true;
    }

    for (var i = 0; i < rawNodes.length; ++i) {
      if (!unavailableNodes[rawNodes[i].id] && rawNodes[i].answers) {
        let answers = [];

        for (var j = 0; j < rawNodes[i].answers.length; ++j) {
          answers.push({
            value: rawNodes[i].answers[j].id,
            label: rawNodes[i].answers[j].value,
          });
        }

        keyedAnswersx[rawNodes[i].id] = answers;

        x.push({
          label: rawNodes[i].identifier,
          value: rawNodes[i].id,
        });
      }
    }

    setAvailableQuestions(x);
    setKeyedAnswers(keyedAnswersx);
  }, []);

  const toggleType = () => {
    if (selectorType === 'OR') {
      setSelectorType('AND');
    } else {
      setSelectorType('OR');
    }
  };

  const acceptAnswers = (a) => {
    let dupe = [...path];
    let found = false;

    for (var i = 0; i < dupe.length; ++i) {
      if (dupe[i].id === a.id) {
        dupe[i] = a;
        found = true;
      }
    }

    if (!found) {
      dupe.push(a);
    }

    setPath(dupe);
  };

  const saveAsNode = async (info, label) => {
    return new Promise(async function (resolve, reject) {
      let payload = {
        id: nodeSplit.isPath ? info.id : uuidv4(),
        surveyId: props?.surveyInfo?.id,
        parent: nodeSplit.id,
        isPath: true,
        title: label,
        condition: JSON.stringify(info),
      };

      let item = null;

      console.log('PAYLOD: ', payload);

      if (nodeSplit.isNew) {
        payload.addedBy = user.attributes.sub;
      } else {
        delete payload.parent;
        delete payload.surveyId;
        delete payload.isPath;
      }

      putSurveyNode({
        variables: {
          info: payload,
        },
      });

      resolve(payload);
    });
  };

  const onSubmit = async (w) => {
    console.log('PATH: ', path);
    let embedded = utility.embedTree([...path]).tree[0];

    console.log('-->', embedded);
    delete embedded.children;

    let nodeInfo = await saveAsNode(embedded, pathName);
    props.saveSplit(embedded);
  };

  useEffect(() => {
    if (!id) {
      setId(uuidv4());
    }
  }, []);

  return (
    <div>
      <TextInput
        value={pathName}
        variant="standard"
        size="small"
        label="Path Label"
        onChange={(e) => setPathName(e)}
        style={{
          backgroundColor: 'unset',
          width: '100%',
          paddingBottom: '20px',
        }}
      />

      <OptionGroup
        availableQuestions={availableQuestions}
        keyedAnswers={keyedAnswers}
        acceptAnswers={acceptAnswers}
        conditions={conditions}
        id={id}
      />

      <Button
        label="submit"
        id="submit"
        onClick={() => {
          onSubmit();
        }}
      />
    </div>
  );
}

// [
//   {
//     selector: 'and',
//     questions: [],
//     chilren: [],
//   },
//   {
//     selector: 'and',
//     questions: [],
//     chilren: [],
//   },
// ];
