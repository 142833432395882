const isInnerImmersion = true;
const isProd = window.location.host.indexOf('dev.magnolia-point.com') === -1;

const CONFIG = {
  dev: {
    userPoolId: 'us-west-2_hZFphgt9l',
    userPoolWebClientId: '7614d5vqts9dj3t3clej34a3k',
    graphqlApiId: '2hlzwci56baqrdytg7pmxefwfu',
  },
  prod: {
    userPoolId: 'us-west-2_hZFphgt9l',
    userPoolWebClientId: '7614d5vqts9dj3t3clej34a3k',
    graphqlApiId: '2hlzwci56baqrdytg7pmxefwfu',
  },
};

const CONFIG_ENV = isProd ? 'prod' : 'dev';
const CURRENT_CONFIG = CONFIG[CONFIG_ENV];

export default {
  region: 'us-west-2',
  userPoolId: CURRENT_CONFIG.userPoolId,
  userPoolWebClientId: CURRENT_CONFIG.userPoolWebClientId,
  graphqlApiEndpoint: `https://${CURRENT_CONFIG.graphqlApiId}.appsync-api.us-west-2.amazonaws.com/graphql`,
  authType: 'AMAZON_COGNITO_USER_POOLS',
  isInnerImmersion: isInnerImmersion,
};
