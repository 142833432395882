import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';
import { useNavigate, useLocation } from 'react-router-dom';

import { store } from 'Contexts/GlobalState';
import { alertClasses } from '@mui/material';

import PageForm from './Form.js';
import { EXECUTE_ADMIN_TASK } from './GraphQL/mutations.js';

import { useLazyQuery, useMutation } from '@apollo/client';

const labels = [
  {
    alignment: 'left',
    label: 'Name',
    key: 'label',
  },
  {
    alignment: 'left',
    label: 'Description',
    key: 'description',
  },
  {
    alignment: 'left',
    label: 'Access',
    key: 'access',
  },
  {
    alignment: 'right',
    label: 'Number of Members',
    key: 'numberOfMembers',
  },
  {
    alignment: 'right',
    label: 'Actions',
    key: 'actions',
    size: 'small',
  },
];

export default function GroupManagement(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [, setModalInfo] = useState(null);
  const [editInfo, setEditInfo] = useState({});
  const [myUserGroups, setMyUserGroups] = useState([]);

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const toggleModal = (e) => {
    // navigate('/add-survey', { state: { editInfo: e } });
    setModalInfo(e);
    setModalOpen(!modalOpen);
  };

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;
  let navigate = useNavigate();

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (taskData && taskData.executeAdminTask) {
      let groups = JSON.parse(taskData.executeAdminTask.body);

      groups.forEach((item) => {
        if (item.label === 'Admin') {
          item.access = 'All';
          item.actions = {
            edit: false,
            delete: false,
          };
        } else {
          item.access =
            item.details && item.details.pageAccess
              ? item.details.pageAccess.toString()
              : '';

          item.actions = {
            edit: true,
            delete: true,
          };
        }
      });

      console.log('GROUPS: ', groups);
      setGroups(groups);
    } else if (taskError) {
      console.log('Failed', taskError);
    }
  }, [taskData, taskError]);

  const getUsers = async () => {
    executeAdminTask({
      variables: {
        operation: 'pullGroupsForDashboard',
      },
    });
  };

  const onClickEdit = (e) => {
    setEditInfo({ ...e, type: 'edit' });
    setMyUserGroups(e.userGroups);
    toggleModal();
    console.log('Dashboard ', JSON.stringify(e));
  };

  const onClickDelete = async (e) => {
    // console.log('onclick delete', e);
    // const items = await API.graphql(
    //   graphqlOperation(executeAdminTask, {
    //     operation: 'deleteUser',
    //     info: JSON.stringify({ username: e.Username }),
    //   })
    // );
    // let t = JSON.parse(items.data.executeAdminTask);
    // console.log('Delete Resp', t);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>Groups</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >
          {
            <Button
              label="Add new group"
              onClick={(e) => {
                setEditInfo({ type: 'new' });
                toggleModal({});
              }}
            />
          }

          {
            // <Button label="Add new group" onClick={(e) => toggleModal({})} />
          }
        </div>
      </div>
      <Table
        labels={labels}
        data={groups}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        confirmDelete={true}
      />
      <Modal
        open={modalOpen}
        toggle={toggleModal}
        title={`${editInfo.type === 'edit' ? 'Manage' : 'Add'} User`}
      >
        <PageForm
          editInfo={editInfo}
          setModalOpen={setModalOpen}
          setEditInfo={setEditInfo}
          myUserGroups={myUserGroups}
          groups={groups}
        />
      </Modal>
    </div>
  );
}
