import React, { useState, useEffect } from 'react';
import { theme } from 'Stories';
import '../../Tree.scss';
import './SplitNode.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Select } from 'Stories';

export default function QuestionSelect(props) {
  const { availableQuestions, keyedAnswers, ind, questionData } = props;

  const [question, setQuestion] = useState(null);
  const [defaultQuestion, setDefaultQuestion] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [defaultAnswers, setDefaultAnswers] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const getCurrQuestion = () => {
    let q = null;
    for (var i = 0; i < availableQuestions.length; ++i) {
      if (availableQuestions[i].value === questionData.question) {
        q = availableQuestions[i];
      }
    }

    if (q) {
      let kA = keyedAnswers[q.value];
      let dA = null;

      for (var i = 0; i < kA.length; ++i) {
        if (kA[i].value === questionData.answer) {
          dA = kA[i];
        }
      }

      setQuestion(q.value);
      setDefaultQuestion(q.value);
      setDefaultAnswers(dA.value);
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (availableQuestions && availableQuestions.length > 0 && keyedAnswers) {
      if (questionData && questionData.question) {
        getCurrQuestion();
      } else {
        setLoaded(true);
      }
    }
  }, [keyedAnswers, availableQuestions]);

  const onChangeQuestion = (e) => {
    setQuestion(e);
  };

  return (
    <div className="comparisonGroup">
      {loaded ? (
        <div style={{ width: '40%' }}>
          <Select
            label={'Question'}
            onChange={(e) => {
              onChangeQuestion(e);
            }}
            id="question"
            name="question"
            value={defaultQuestion}
            options={availableQuestions}
          />
        </div>
      ) : null}

      {question && (
        <div style={{ width: '40%', marginLeft: '20px' }}>
          <Select
            label={'Answer'}
            onChange={(e) => {
              props.saveQuestion(e, question, ind);
            }}
            id="answer"
            name="answer"
            options={keyedAnswers[question]}
            value={defaultAnswers}
            required
          />
        </div>
      )}
      <div
        className="selectorAdd"
        style={{ backgroundColor: theme.palette.general.darkPurple }}
        onClick={() => {
          props.addQuestion();
        }}
      >
        <FontAwesomeIcon
          className="alterChartBtn"
          style={{
            height: '13px',
            width: '13px',
          }}
          icon={solid('plus')}
        />
      </div>
      {props.ind !== 0 && (
        <div
          className="selectorAdd"
          style={{ backgroundColor: theme.palette.general.darkPurple }}
          onClick={() => {
            props.removeQuestion(props.ind);
          }}
        >
          <FontAwesomeIcon
            className="alterChartBtn"
            style={{
              height: '13px',
              width: '13px',
            }}
            icon={solid('minus')}
          />
        </div>
      )}
    </div>
  );
}
