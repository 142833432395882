import React, { useState } from 'react';
import { theme } from 'Stories';
import './Style.scss';

import { GlobalConstants } from 'Services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { v4 as uuidv4 } from 'uuid';

export default function NodeButton(props) {
  const availTypes = GlobalConstants.surveyBuilderOptions;
  const [hovering, setHovering] = useState(false);

  const buttonMatch = {
    split: solid('arrows-split-up-and-left'),
    add: solid('plus'),
  };

  return (
    <div
      style={{
        height: 'fit-content',
        width: 'fit-content',
      }}
      // onMouseLeave={(e) => setHovering(false)}
    >
      <div
        className="basicButton"
        style={{
          backgroundColor: theme.palette.general.darkPurple,
          width: 'fit-content',
        }}
        onClick={() => {
          if (props.type !== 'add') props.onClick();
          else setHovering(!hovering);
        }}
        id={props.id}
        // onMouseEnter={(e) => setHovering(true)}
      >
        <div className="iconHolder" style={{ padding: '3px' }}>
          <FontAwesomeIcon
            className="alterChartBtn"
            style={{
              height: '13px',
              width: '13px',
              rotate: props.type === 'split' ? '180deg' : '0deg',
            }}
            icon={buttonMatch[props.type]}
          />
        </div>
      </div>
      {props.type === 'add' && (
        <div className="addButtonOptions">
          {hovering &&
            availTypes.map((btn) => {
              return (
                <div
                  className="addButtonOption"
                  style={{
                    backgroundColor: theme.palette.general.darkPurple,
                  }}
                  onClick={() => {
                    setHovering(false);
                    props.onClick(btn.type);
                  }}
                  key={uuidv4()}
                >
                  <FontAwesomeIcon
                    style={{
                      height: '13px',
                      width: '13px',
                      marginRight: '7px',
                    }}
                    icon={theme.icons.surveyBuilderIcons[btn.type]}
                  />
                  {btn.name}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
