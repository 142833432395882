export const medications = [
    {
      medication: "Alprazolam (Xanax®)",
      response: "Normal Response to Alprazolam",
      recommendation: "INFORMATIVE",
      rxcui: "202363",
      drugbank_pcid:"DBPC0248883",
      details:
        "Pharmacogenetic guidance: \n        Alprazolam is primarily eliminated by metabolism via \n        CYP3A4 and CYP3A5. Genetic polymorphisms of these genes are not expected to affect \n        the efficacy or safety profiles of this drug. \n        \n        Polypharmacy guidance: \n        The concomitant use \n        of alprazolam with CYP3A4 inhibitors may result in increased alprazolam levels and \n        prolonged sedation. Impairment of motor skills are also observed with some combinations. \n        Monitor patients for exaggerated sedative effects. If possible, alprazolam should be avoided\n        in patients receiving strong inhibitors of CYP3A4 such as ketoconazole, itraconazole and \n        ritonavir. Drugs that induce CYP3A enzymes may decrease a1prazolam levels. which \n        results in a loss of efficacy.",
    },
    {
      medication: "Amitriptyline (Elavil®)",
      response: "Normal Amitriptyline Exposure (CYP2D6: Normal Metabolizer)",
      recommendation: "ACTIONABLE",
      rxcui: "42940",
      drugbank_pcid: "DBPC0121211",
      details:
        "The patient is predicted to be a normal CYP2D6 metabolizer which is likely to result in \n        normal metabolism of amitriptyline to less active compounds.\n\n        Psychiatric Conditions: Amitriptyline therapy can be prescribed according to standard \n        recommended dosage and administration.\n\n        Neuropathic Pain: Amitriptyline therapy can be prescribed according to standard \n        recommended dosage and administration.",
    },
    {
      medication: "Depakene Valproic Acid",
      response: "Normal Response to Valproic Acid",
      recommendation: "INFORMATIVE",
      rxcui: "1099681",
      drugbank_pcid:"DBPC0007817",
      details:
        "Pharmacogenetic guidance: \n        Genotype results obtained from the pharmacogenetic test \n        performed in this patient cannot be used to identify patients carrying mutations in \n        mitochondrial DNA polymerase y (POLG). Valproic acid is contraindicated in patient known \n        to have mitochondrial disorders caused by mutations in mitochondrial ONA polymerase y \n        (POLG; e.g., Alpers-Huttenlocher Syndrome) and children under two years of age who are \n        suspected of having a POLG-related disorder.\n\n        Valproic acid is extensively metabolized in the liver, which occurs primarily by \n        glucuronidation with probable contributions of UGTJA6, UGT1 A9, and UGT2B7. This drug \n        is also metabolized by a minor CYP—dependent oxidation pathway, which includes multiple\n        enzymes such as CYP2A6, CYP2C9, and CYP2C 19. There are insufficient studies \n        documenting the impact of genetic polymorphisms of these metabolizing enzymes on \n        valproic acid response, and no genetically guided drug selection or dosing \n        recommendations are available. \n        \n        Polypharmacy guidance: enzyme-inducing drugs \n        increase valproic acid clearance 2-fold, and higher doses of this drug are required to \n        maintain therapeutic concentrations when added to a therapy regimen containing enzyme-\n        inducing antiepileptic drugs.",
    },
    {
      medication: "Sertraline (Zoloft®)",
      response: "Normal Sensitivity to Sertraline (CYP2C19: Normal Metabolizer)",
      recommendation: "ACTIONABLE",
      rxcui: "82728",
      drugbank_pcid:"DBPC0046978",
      details:
        "Sertraline can be prescribed at standard label-recommended dosage and administration.",
    },
  ];