export const PatientInformation = props => {
    const { lastName, firstName, gender, dateOfBirth } = props;
    
    
    return <div className={"pm-patient-info"}>
    <h3>Patient Information</h3>
    <div className={"pm-patient-info-labels"}>
        <div>
            {"Last Name"}
        </div>
        <div>
            {"First Name"}
        </div>
        <div>
            {"Gender"}
        </div>
        <div>
            {"Date of Birth"}
        </div>
    </div>
    <div className={"pm-patient-info-content"}>
        <div>
            {lastName}
        </div>
        <div>
            {firstName}
        </div>
        <div>
            {gender}
        </div>
        <div>
            {dateOfBirth}
        </div>
    </div>
</div>
}