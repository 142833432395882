import React, { useState, useContext, useEffect } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';

import tasks from 'Assets/Tasks.jpg';
import admin from 'Assets/Admin.jpg';

import PageForm from './Form.js';
import { store } from 'Contexts/GlobalState';
import { useNavigate } from 'react-router-dom';

import './Main.css';

import { useLazyQuery, useMutation } from '@apollo/client';
import { LIST_APP_CONFIGS } from './GraphQL/queries';
import { DELETE_APP_CONFIG } from './GraphQL/mutations';

const labels = [
  {
    alignment: 'left',
    label: 'Configuration Name',
    key: 'name',
  },
  {
    alignment: 'left',
    label: 'Description',
    key: 'description',
  },
  {
    alignment: 'right',
    label: 'Date Added',
    key: 'createdAt',
  },
  {
    alignment: 'right',
    label: ' ',
    key: 'actions',
    size: 'small',
  },
];

export default function AppConfigs(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [data, setData] = useState([]);

  const GlobalState = useContext(store);
  const { dispatch, state } = GlobalState;

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const [
    listAppConfigs,
    { loading: listLoading, data: listData, error: listError },
  ] = useLazyQuery(LIST_APP_CONFIGS, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination: {
        page: page - 1,
        limit: pageLimit,
      },
    },
  });

  const [
    deleteItem,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_APP_CONFIG);

  let navigate = useNavigate();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    if (listData && listData.listPushes) {
      let items = listData.listAppConfigs.items;

      for (var i = 0; i < items.length; ++i) {
        let name = items[i].addedBy;

        if (items[i].addedByUserInfo) {
          name =
            items[i].addedByUserInfo.given_name +
            ' ' +
            items[i].addedByUserInfo.family_name;
        }

        items[i] = {
          ...items[i],
          addedByDisplayName: name,
          actions: {
            edit: true,
            delete: true,
            manage: true,
          },
        };

        console.log(items[i]);
      }
      setData(items);
    } else if (listError) {
      console.log('LIST ERROR FOR APP COFNIGS: ', listError);
    }
  }, [listData, listError]);

  useEffect(() => {
    if (!modalOpen) {
      listAppConfigs();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (deleteData && deleteData.deleteAppConfig) {
      listAppConfigs();
    }
  }, [deleteData]);

  const onClickDelete = async (e) => {
    deleteItem({
      variables: {
        id: e.id,
      },
    });

    setModalOpen(false);
  };

  const onClickEdit = (e) => {
    setEditInfo(e);
    setModalOpen(true);
  };

  const onClickManage = (e) => {
    console.log('Setting appconfig to ', JSON.stringify(e));
    dispatch({
      type: 'setCurrentAppConfig',
      appConfig: e,
    });
    navigate('/view-config', { state: { appConfig: e } });
  };
  console.log('App configs data', data);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div>App Configurations ({data.length}/10)</div>
        <Button label="Add new configuration" onClick={(e) => toggleModal(e)} />
      </div>
      <Table
        labels={labels}
        data={data}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickManage={onClickManage}
      />
      <Modal open={modalOpen} toggle={toggleModal} title={'Add AppConfig'}>
        <PageForm
          editInfo={editInfo}
          setModalOpen={setModalOpen}
          setEditInfo={setEditInfo}
        />
      </Modal>
    </div>
  );
}
