import { useState, useEffect } from 'react';
import { sections } from './constants';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './styles.css';
export default function FacilitateImmersion(props) {
  let navigate = useNavigate();

  const openVideo = (video) => {
    navigate('/view-immersion-video', { state: { video: video } });
  };

  return (
    <div className="fi-container">
      <h2>Facilitate an Immersion</h2>
      {sections.map((section, index) => {
        return (
          <div key={index}>
            <h3>{section.title}</h3>
            {section.content.map((item, index) => {
              return (
                <Paper
                  key={index}
                  variant={'elevation'}
                  elevation={6}
                  className={'fi-content-container'}
                  onClick={() => openVideo(item.video)}
                >
                  <div className="fi-content-image-container">
                    <img src={item.imgUrl} className={'fi-content-image'} />
                    <div className={'fi-play'}>
                      <FontAwesomeIcon
                        icon={solid('play-circle')}
                        className={'fi-play-icon'}
                      />
                      <div>Watch Video</div>
                    </div>
                  </div>

                  <div className={'fi-content'}>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </Paper>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
