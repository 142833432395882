import {TableHead, TableRow, TableCell, Pagination, Stack } from '@mui/material';
import { Table } from 'Stories';
import { useState, useEffect } from "react"
const MEDICATIONS_PER_PAGE = 5;
export const MedicationsTable = props => {
    const {
        medicationsHeaders,
        medications
    } = props;

    const [ paginatedData, setPaginatedData ] = useState(medications.slice(0, MEDICATIONS_PER_PAGE));
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ totalPages, setTotalPages ] = useState(1);

    useEffect(() => {
        let totalPages = Math.ceil(medications.length / MEDICATIONS_PER_PAGE)
        
        setCurrentPage(0);
        setTotalPages(totalPages);
        let data = medications.slice(currentPage * MEDICATIONS_PER_PAGE, currentPage * MEDICATIONS_PER_PAGE + MEDICATIONS_PER_PAGE);
        setPaginatedData(data);
    }, [medications])

    useEffect(() => {
        let data = medications.slice(currentPage * MEDICATIONS_PER_PAGE, currentPage * MEDICATIONS_PER_PAGE + MEDICATIONS_PER_PAGE);
        setPaginatedData(data);
    }, [currentPage])

    return (<div>
                        
            <Table
                customHeader={
                <TableHead className={"pm-table-header"}>
                    <TableRow>
                        {medicationsHeaders.map((header, index) => {
                            return <TableCell
                                align={header.alignment ? header.alignment : 'left'}
                                size={header.size ? header.size : 'medium'}
                                key={'TableLabel_' + index}
                                variant={"head"}
                                sx={{ fontWeight: "bold"}}
                            >
                            {header.label}
                        </TableCell>
                        })}
                    </TableRow>
                </TableHead>
                }
                
                isHeadersHidden={true}
                labels={medicationsHeaders}
                data={paginatedData}
            />
            {totalPages > 1 && <Stack spacing={2}>
                <Pagination count={totalPages} shape="rounded" onChange={(e,v) => setCurrentPage(v - 1)}/>
            </Stack>}
        </div>
    )
}