import React, { useState, useContext } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';

import { v4 as uuidv4 } from 'uuid';

import { useMutation } from '@apollo/client';
import { PUT_SURVEY } from './GraphQL/mutations';

import { Auth } from 'aws-amplify';

export default function PageForm(props) {
  const { id, name, description } = props.editInfo;

  const user = Auth.user;

  const [
    putSurvey,
    { data: putSurveyData, loading: putSurveyLoading, error: putSurveyError },
  ] = useMutation(PUT_SURVEY);

  const onSubmit = async (e) => {
    let payload = {
      title: e.title,
      description: e.description,
    };

    if (!id) {
      payload.id = uuidv4();
      payload.appConfigId = props.appConfigId;

      console.log('QS NEW', payload);
    } else {
      payload.id = id;
    }

    putSurvey({
      variables: { info: payload },
    });
    props.setEditInfo({});
    props.setModalOpen(false);
  };

  return (
    <Form style={{ display: 'block' }} onSubmit={onSubmit}>
      <TextInput
        label="Title"
        id="title"
        form
        variant="outlined"
        value={name}
      />
      <TextInput
        label="Description"
        id="description"
        variant="outlined"
        form
        rows={4}
        multiline
        value={description}
      />
      <Button label="submit" id="submit" type="submit" />
    </Form>
  );
}
