import React, { useState, useContext } from 'react';
import { TextInput, Form, Button, Table, Modal } from 'Stories';

import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';

import { useLazyQuery, useMutation } from '@apollo/client';
import { PUT_APP_CONFIG } from './GraphQL/mutations';

export default function PageForm(props) {
  const { id, name, description } = props.editInfo;
  const user = Auth.user;

  const [
    putAppConfig,
    {
      data: putAppConfigData,
      loading: putAppConfigLoading,
      error: putAppConfigError,
    },
  ] = useMutation(PUT_APP_CONFIG);

  const onSubmit = async (e) => {
    let payload = {
      name: e.name,
      description: e.description,
    };

    if (!id) {
      payload.id = uuidv4();
      payload.addedBy = user.attributes.sub;
    } else {
      payload.id = id;
    }

    putAppConfig({
      variables: {
        id: payload.id,
        info: payload,
      },
    });

    props.setEditInfo({});
    props.setModalOpen(false);
  };

  return (
    <Form style={{ display: 'block' }} onSubmit={onSubmit}>
      <TextInput label="Name" id="name" variant="outlined" value={name} />
      <TextInput
        label="Description"
        id="description"
        variant="outlined"
        rows={4}
        multiline
        value={description}
      />
      <Button label="submit" id="submit" type="submit" />
    </Form>
  );
}
