import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'Stories';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Paper from '@mui/material/Paper';
import './Home.css';
import News from '../../Assets/Connect App Illustration.svg';
import { LARGE_WINDOW_SIZE } from 'Services/GlobalConstants';
import { useWindowDimensions } from 'Services/CustomHooks';
import {
  quickLinksList,
  innerImmersionLinksList,
  innerImmersionLinksListFacilitator,
} from './Constants';

import { Auth } from 'aws-amplify';
import awsmobile from 'aws-exports';

function QuickLink(props) {
  let navigate = useNavigate();
  return (
    <div
      className={'home-quicklink'}
      onClick={() => navigate(props.link.path, props.link.extras)}
    >
      <div className={'home-quicklink-title'}>
        <h3>
          {props.link.title}
          <FontAwesomeIcon
            icon={solid('angle-right')}
            className={'home-quicklink-icon'}
          />
        </h3>
      </div>
      <div className={'home-quicklink-image-container'}>
        <img
          src={props.link.image}
          width={225}
          className={'home-quicklink-image'}
        />
      </div>
    </div>
  );
}

export default function Home(props) {
  const isInnerImmersion = awsmobile.isInnerImmersion; // TEMPORARY: This boolean is temporarily hardcoded until we figure out difference between inner immersion and regular users

  const user = Auth.user;

  const linksList =
    user.signInUserSession.idToken.payload['cognito:groups'][0] === 'Admin'
      ? innerImmersionLinksList
      : innerImmersionLinksListFacilitator;

  const [windowWidth, windowHeight] = useWindowDimensions();
  return (
    <div className={'home-content'}>
      <h3>Latest News</h3>
      <Paper variant="elevation" elevation={2} className={'home-news-card'}>
        <div className={'home-news'}>
          <div className={'home-news-image-container'} style={{}}>
            <img src={News}></img>
          </div>
          <div className={'home-news-content'}>
            <div className={'home-tag'}>Coming Soon</div>
            <h2>Connect Mobile App</h2>
            <p>
              Daily health checks, personalized care and access to interactive
              programs that support your physical and mental health goals.
            </p>
          </div>
          <div className={'home-news-actions'}>
            {/* <Button
              label="Download"
              icon={<FontAwesomeIcon icon={solid('download')} />}
              style={{ marginRight: 40 }}
            ></Button> */}
          </div>
        </div>
      </Paper>
      <h3>Quick Links</h3>
      <div
        className={'home-quicklink-list'}
        style={{ width: windowWidth < LARGE_WINDOW_SIZE ? '80%' : null }}
      >
        {linksList.map((quickLink) => {
          return <QuickLink link={quickLink} />;
        })}
      </div>
    </div>
  );
}
