import React, { useState, useContext } from 'react';
import { TextInput, Form, Button, Table, Modal, Select } from 'Stories';

import { EXECUTE_ADMIN_TASK } from './GraphQL/mutations.js';
import { useMutation } from '@apollo/client';

export default function PageForm(props) {
  console.log('PROPS: ', props);

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Username, email } = props.editInfo;

  const [
    executeAdminTask,
    { loading: taskLoading, data: taskData, error: taskError },
  ] = useMutation(EXECUTE_ADMIN_TASK, {
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async (e) => {
    setLoading(true);
    setMessage(null);

    try {
      let resp = await executeAdminTask({
        variables: {
          operation: props.editInfo.type === 'new' ? 'addUser' : 'updateUser',
          input: JSON.stringify({
            username: props.editInfo.type === 'new' ? e.username : email,
            email: props.editInfo.type === 'new' ? e.email : email,
            groups:
              typeof e.groups === 'string' ? e.groups.split(',') : e.groups,
            given_name: e.given_name,
            family_name: e.family_name,
          }),
        },
      });

      let body = JSON.parse(resp.data.executeAdminTask.body);

      console.log('RESP: ', body);

      if (!body.error) {
        setLoading(false);
        props.forceReload();
        props.setEditInfo({});
        props.setModalOpen(false);
      } else {
        setLoading(false);
        setMessage(body.message);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setMessage('There was an error adding user');
    }
  };

  return (
    <Form style={{ display: 'block' }} onSubmit={onSubmit}>
      {props.editInfo.type === 'new' && (
        <TextInput label="Email" form id="email" required />
      )}
      <TextInput
        label="First Name"
        value={props.editInfo.given_name}
        form
        id="given_name"
        required
      />
      <TextInput
        label="Last Name"
        value={props.editInfo.family_name}
        form
        id="family_name"
        required
      />
      <Select
        label="Groups"
        id="groups"
        form
        variant="outlined"
        isMulti
        value={props.myUserGroups}
        // value={name}
        options={props.groups}
        required
      />
      <div style={{ color: 'red' }}>{message}</div>
      <Button label="submit" id="submit" type="submit" />
    </Form>
  );
}
