import { moduleHeaders } from './Constants';
import {
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Stack,
} from '@mui/material';
import { Table } from 'Stories';
export default function Module({ title, content }) {
  return (
    <div>
      <h2>{title}</h2>
      <div>
        <Table
          customHeader={
            <TableHead className={'pm-table-header'}>
              <TableRow>
                {moduleHeaders.map((header, index) => {
                  return (
                    <TableCell
                      align={header.alignment ? header.alignment : 'left'}
                      size={header.size ? header.size : 'medium'}
                      key={'TableLabel_' + index}
                      variant={'head'}
                      sx={{ ...header.sx, fontWeight: 'bold' }}
                    >
                      {header.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          }
          labels={moduleHeaders}
          data={content}
        />
      </div>
    </div>
  );
}
